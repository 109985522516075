<feature-toggle-provider [features]="featureFlags">
  <nav class="navbar navbar-expand-lg navbar-light  quicknav" *featureToggle="'quickNavScreenEnabled'">
    <ul class="navbar-nav me-auto">
      <li class="nav-item dropdown danger">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{buildInfo}}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="https://bx.dev.ielts.com/home">DEV</a>
          <a class="dropdown-item" href="https://bx.tst.ielts.com/home">TST</a>
          <a class="dropdown-item" href="https://bx.sit.ielts.com/home">SIT</a>
          <a class="dropdown-item" href="https://bx.uat.ielts.com/home">UAT</a>
          <a class="dropdown-item" href="https://bx.perf.ielts.com/home">PERF</a>
        </div>
      </li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/account']">My account</a></li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/account/signup']">Sign up</a></li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/account/signin']">Sign in</a></li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/onboarding']">Onboarding</a></li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/booking']">Session search</a></li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/booking']">Payment</a></li>
    </ul>
  </nav>
  <div class="d-flex wrapper flex-wrap">
    <div class="uploadList border rounded" *featureToggle="'quickNavScreenEnabled'">
      <div class="inputBox d-flex justify-content-around align-items-center w-75">
        <input type="file" name="" id="" accept=".json" (change)="file = $event.target.files[0]"
          [disabled]="percentage > 0">
        <button class="btn btn-success" [disabled]="!file || percentage > 0" (click)="uploadFile(file)">
          <span *ngIf="percentage === 0">Add</span>
          <span *ngIf="percentage > 0">{{percentage}} %</span>
        </button>
      </div>
      <table style="width: 100%;" class="m-2">
        <caption class="sr-only">Personal Details</caption>
        <thead>
          <tr>
            <th>File Name</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of files">
            <td>{{file.fileName}}</td>
            <td><button class="btn btn-link" (click)="copyLink(file.address)">Copy link</button></td>
            <td>
              <div class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Actions
                </button>
                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                  <a class="dropdown-item" href="javascript:void()" [routerLink]="'/my-tests'"
                    [queryParams]="{ mockKey: file.address}">My Test</a>
                  <a class="dropdown-item" href="javascript:void()" [routerLink]="'/my-account'"
                    [queryParams]="{ mockKey: file.address}">My Account</a>
                  <a class="dropdown-item" href="javascript:void()" (click)="deleteFile(file)">Delete</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="fomr-group toggle-button" *featureToggle="'quickNavScreenEnabled'">
      <div>
        <span>Request Mode :</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setRequestM('Mock')" (keyup)="setRequestM('Mock')"
            [class.active]="requestMode === 'Mock'" btnCheckbox tabindex="0" role="button">Hardcoded Fee</label>
          <label class="btn btn-mode" (click)="setRequestM('Api')" (keyup)="setRequestM('Api')"
            [class.active]="requestMode === 'Api'" btnCheckbox tabindex="0" role="button">API</label>
        </div>
      </div>
      <div>
        <span>3DS Payment :</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="set3dsPaymentState(true)" (keyup)="set3dsPaymentState(true)"
            [class.active]="enable3DSPayment" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="set3dsPaymentState(false)" (keyup)="set3dsPaymentState(false)"
            [class.active]="!enable3DSPayment" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
      <div>
        <span>Single Skill Resit:</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setSSR(true)" (keyup)="setSSR(true)"
            [class.active]="enableSSR" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="setSSR(false)" (keyup)="setSSR(false)"
            [class.active]="!enableSSR" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
      <div>
        <span>Location Mode :</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setLocationM('Mock')" (keyup)="setLocationM('Mock')"
            [class.active]="locationMode === 'Mock'" btnCheckbox tabindex="0" role="button">Mock</label>
          <label class="btn btn-mode" (click)="setLocationM('Api')" (keyup)="setLocationM('Api')"
            [class.active]="locationMode === 'Api'" btnCheckbox tabindex="0" role="button">Map Box</label>
        </div>
      </div>
      <div>
        <span>TT Application updates:</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setUpdateApplicationDetails(true)" (keyup)="setUpdateApplicationDetails(true)"
            [class.active]="enableUpdateApplicationDetails" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="setUpdateApplicationDetails(false)" (keyup)="setUpdateApplicationDetails(false)"
            [class.active]="!enableUpdateApplicationDetails" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
      <div>
        <span>Offline EOR:</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setEnableOfflineEOR(true)" (keyup)="setEnableOfflineEOR(true)"
            [class.active]="enableOfflineEOR" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="setEnableOfflineEOR(false)" (keyup)="setEnableOfflineEOR(false)"
            [class.active]="!enableOfflineEOR" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
      <div>
        <span>Download eTRF:</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setDownloadEtrf(true)" (keyup)="setDownloadEtrf(true)"
            [class.active]="enableDownloadEtrf" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="setDownloadEtrf(false)" (keyup)="setDownloadEtrf(false)"
            [class.active]="!enableDownloadEtrf" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
      <div>
        <span>New Account UI:</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setEnableNewAccountUI(true)" (keyup)="setEnableNewAccountUI(true)"
            [class.active]="enableNewAccountUI" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="setEnableNewAccountUI(false)" (keyup)="setEnableNewAccountUI(false)"
            [class.active]="!enableNewAccountUI" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
      <div>
        <span>Dashboard V2:</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setEnableDashboardV2(true)" (keyup)="setEnableDashboardV2(true)"
          [class.active]="enableDashboardV2" btnCheckbox tabindex="0"
            role="button">Enable</label>
          <label class="btn btn-mode" (click)="setEnableDashboardV2(false)" (keyup)="setEnableDashboardV2(false)"
          [class.active]="!enableDashboardV2" btnCheckbox tabindex="0"
            role="button">Disable</label>
        </div>
      </div>
      <div>
        <span>New Offline Payment Journey:</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setEnableNewOfflinePayment(true)" (keyup)="setEnableNewOfflinePayment(true)"
            [class.active]="enableNewOfflinePayment" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="setEnableNewOfflinePayment(false)" (keyup)="setEnableNewOfflinePayment(false)"
            [class.active]="!enableNewOfflinePayment" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
      <div>
        <span>Enable Captcha:</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setEnableCaptchaCheck(true)" (keyup)="setEnableCaptchaCheck(true)"
            [class.active]="enableCaptchaCheck" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="setEnableCaptchaCheck(false)" (keyup)="setEnableCaptchaCheck(false)"
            [class.active]="!enableCaptchaCheck" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
      <div>
        <span>Enable OneTrust Cookie Policy?</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setEnableOnetrustPolicy(true)" (keyup)="setEnableOnetrustPolicy(true)"
            [class.active]="enableOnetrustPolicy" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="setEnableOnetrustPolicy(false)" (keyup)="setEnableOnetrustPolicy(false)"
            [class.active]="!enableOnetrustPolicy" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
        <div>
        <span>Change Payment Offline to Online:</span>
        <div class="btn-group" appApplyTabIndexes>
          <label class="btn btn-mode" (click)="setChangeOfflineToOnline(true)" (keyup)="setChangeOfflineToOnline(true)"
            [class.active]="changePaymentToOnlineEnabled" btnCheckbox tabindex="0" role="button">Enable</label>
          <label class="btn btn-mode" (click)="setChangeOfflineToOnline(false)" (keyup)="setChangeOfflineToOnline(false)"
            [class.active]="!changePaymentToOnlineEnabled" btnCheckbox tabindex="0" role="button">Disable</label>
        </div>
      </div>
    </div>

    <div class="flexbox justify-contents-center textcenter" *featureToggle="'!quickNavScreenEnabled'">
      <div class="error-message">
        <h1>This feature is disabled</h1>
        <h2>Please contact your system administrator</h2>
        <button class="btn btn-block btn-filled" (click)="goToHome()">Go to Dashboard</button>
        <button class="btn btn-block btn-filled" (click)="reload()">Refresh the Page</button>
      </div>
    </div>
  </div>

</feature-toggle-provider>
