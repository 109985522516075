import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type IState = {
  toggleVersion?: string,
  enablePrePaymentAddFields?: boolean
  enablePaymentConfirmation?: boolean,
  enablePromotionalBanner?: boolean,
  enableDashboard?: boolean,
  enableCaptcha?: boolean
};

@Injectable({
  providedIn: 'root'
})

export class SharedStateService {

  private readonly initialState: IState = {
    toggleVersion: '0.02', // Must change the version while adding new toggle key
    enablePrePaymentAddFields: false,
    enablePaymentConfirmation: false,
    enablePromotionalBanner: true,
    enableDashboard: false,
    enableCaptcha: false
  };

  state$ = new BehaviorSubject<IState>(this.initialState);
  private mockDetails = 'mockDetails';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    const local = this.getFromLocalStorage();
    if (local && (Object.keys(local)?.length) && this.initialState?.toggleVersion === local?.toggleVersion) {
      this.state$.next({
        ...local
      } as IState);
    } else {
      this.state$.next(this.initialState);
      localStorage.setItem(this.mockDetails, JSON.stringify(this.initialState));
    }
  }

  getFromLocalStorage() {
    const mockObj = localStorage.getItem(this.mockDetails);
    return mockObj ? JSON.parse(mockObj) : '';  
  }

  changeState(myChange: IState) {
    this.state$.next(myChange);
    const temp = JSON.stringify({
      ...myChange,
    });
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.mockDetails, temp);
    }
  }

  updateState(myChange: IState): void {
    const newState = {
      ...this.state$.getValue(),
      ...myChange
    };
    this.state$.next(newState);
    const temp = JSON.stringify(newState);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.mockDetails, temp);
    }
  }

  getState() {
    return this.state$.asObservable();
  }

  clearLocalStorage() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(this.mockDetails);
    }
    this.state$.next(this.initialState);
    localStorage.setItem(this.mockDetails, JSON.stringify(this.initialState));
  }
  
  clearState() {
    this.state$.next(this.initialState);
  }

}
