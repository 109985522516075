import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { Injectable, OnDestroy } from '@angular/core';
import {
  Application, BraintreePayment, CreatePaymentRequest, CreatePaymentRequestGatewayPayment,
  CreateTokenRequest, CreateTokenResponse, PaymentGateway, PaymentService, PaymentSourceId
} from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';
import { Subscription, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { UserState } from 'src/app/store/user/user.reducer';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { CommonService } from './common.service';

const ProductId = ['47e6d554-5776-4bd1-8a02-ca7f36cbb64b'];
const LocationID = ['12995a88-88a3-474e-846e-db3a3dfd2511'];
export interface IExpiredError {
  isReceiptExpiredError: boolean,
  receiptExpiredTitle: string,
  receiptExpiredDescription: string
}

@Injectable({
  providedIn: 'root'
})
export class PaymentsService implements OnDestroy {
  sub: Subscription;
  state: UserState;
  enable3DSPayment: boolean;

  private expiredErrorData = {
    isReceiptExpiredError: true,
    receiptExpiredTitle: "Application expired",
    receiptExpiredDescription: "Your application has expired due to inactivity, begin from where you left."
  };

  constructor(
    private store: Store<{ globalStore, userStore }>,
    private applicationService: ApplicationsService,
    private paymentService: PaymentService,
    private commonService: CommonService) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.state = x;
    });
    this.sub.add(this.store.select(appState => appState.globalStore.enable3DSPayment).subscribe(x => {
      this.enable3DSPayment = x;
    }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  createToken(applicationId: string, applicationPaymentId: string): Observable<CreateTokenResponse> {
    if (!applicationId) {
      return throwError(new Error('Application is Empty'));
    }
    if (!applicationPaymentId) {
      return throwError(new Error('payment Id is Empty'));
    }
    const tokenRequest = {
      paymentGateway: 'BRAINTREE' as PaymentGateway,
      paymentSourceId: {
        applicationId,
        applicationPaymentId,
        userProfileId: this.state.userDetails.userProfileId
      } as PaymentSourceId
    } as CreateTokenRequest;
    return this.paymentService.createToken(tokenRequest);
  }
  DoPayment(simulateMode: string, nonce: string, app: Application, deviceData, token: string): Observable<any> {
    this.paymentService.configuration.accessToken = this.state.userToken;
    return new Observable((observer) => {
      const request = {
        gatewayPayment: {
          braintreePayment: {
            token,
            nonce,
            deviceData,
            storeInVaultOnSuccess: true
          } as BraintreePayment
        } as CreatePaymentRequestGatewayPayment,
        paymentMethod: 'CREDIT_CARD' as IPaymentMethods,
        paymentSourceId: {
          applicationId: app.id,
          applicationPaymentId: this.applicationService.getLatestApplicationPayment(app?.applicationPayments)?.id,
          userProfileId: this.state.userDetails.userProfileId
        } as PaymentSourceId
      } as CreatePaymentRequest;

      this.paymentService.createPayment(request, this.commonService?.appCorrelationId()).pipe(first()).subscribe(z => {
        observer.next(z);
      }, e => {
        observer.error(e);
      },
        () => {
          observer.complete();
        });
    });
  }

  check3DSstatus(): boolean {
    return this.enable3DSPayment;
  }

  getErrorState() {
    return this.expiredErrorData;
  }

}
