import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { ICon } from '../payment-container/payment-container.component';
import {
  Application,
  CreatePaymentOrderRequest,
  CreatePaymentOrderResponse,
  PaymentService,
  StripeForwardingCaptureRequest,
  StripeForwardingCaptureResponse,
  StripeForwardingCaptureStatus,
  StripeForwardingRequest,
  StripeForwardingResponse,
  StripePaymentSource,
  StripeService
} from '@idp-education/ors-test-taker-bff-client-v1';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { first } from 'rxjs/operators';
import { Stripe } from '@fireflysemantics/angular-stripe-service/lib/types';
import { PaymentUtilService } from 'src/app/pages/payment/payment-util.service';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { PaymentGateways, PaymentStates, PaymentTypes } from 'src/app/pages/payment/payment.enum';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { NavigationService } from 'shared/services/navigation.service';
const appSettings = require('src/assets/appSettings.json');
import { Subscription } from 'rxjs';
import { GtmTrackingService } from 'shared/services/gtm-tracking.service';
import { gtmConst } from 'shared/utils/trackingConstants';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @Output() onReturn: EventEmitter<any> = new EventEmitter();
  @ViewChild('confirmationModal') confirmationModal: ConfirmModalComponent;
  createPaymentOrderResponse: CreatePaymentOrderResponse;
  stripeForwardingResponse: StripeForwardingResponse;
  stripeSubscription: Subscription;
  stripeCaptureSubscription: Subscription;
  stripeForwardingResponseStatus: string;
  isStipeSupported = true;
  stripe: any;
  stripeElements: any;
  card: any;
  payment: any;
  showStripeForm = false;
  showPayNowBtn = false;
  disablePayNowButton = false;
  stripeErrMsg: string;
  title: string;
  acceptText: string;
  @Input() isFromNewPaymentPage: boolean;
  isNewPaymentJourney: string;
  static get tab(): ICon {
    return {
      alt: 'stripe',
      key: 'STRIPE',
      url: '',
      class: 'fa fa-credit-card-alt',
      title: 'Pay online with Credit Card & Wallets',
      description: 'Secure payments powered by Stripe',
    };
  }

  constructor(
    private loadingService: LoadingService,
    private paymentService: PaymentService,
    private paymentUtilService: PaymentUtilService,
    private stripePaymentService: StripeService,
    private navigationService: NavigationService,
    private router: Router,
    private commonService: CommonService,
    private store: Store<{
      myTestsStore;
      paymentStore;
      bookingStore;
      applicationsStore;
    }>,
    private toastr: ToastrService,
    private stripeService: AngularStripeService,
    private gtmTrackingService: GtmTrackingService
  ) { 
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
  }

  ngOnInit(): void {
    this.createOrder();
  }

  createOrder() {
    this.loadingService.increaseLoadingCounter();
    const createPaymentOrderRequestPayload: CreatePaymentOrderRequest = {
      paymentMethod: 'STRIPE',
      paymentSourceId: {
        applicationId: this.applicationId,
        applicationPaymentId: this.applicationPaymentId,
        userProfileId: this.application?.userProfile?.id,
      },
    };
    this.paymentService
      .createOrder(createPaymentOrderRequestPayload, this.commonService?.appCorrelationId())
      .pipe(first())
      .subscribe(
        (res: CreatePaymentOrderResponse) => {
          this.createPaymentOrderResponse = res;
          if (this.createPaymentOrderResponse?.gatewayResponse?.stripeResponse?.paymentSource === StripePaymentSource.STRIPEPAYMENTSOS) {
            this.isStipeSupported = false;
          }
          this.loadingService.decreaseLoadingCounter();
          this.showCardDetailsForm();
        },
        () => {
          this.loadingService.decreaseLoadingCounter();
        }
      );
  }

  showCardDetailsForm() {
    const keyOptions = {
      stripeAccount:
        this.createPaymentOrderResponse?.gatewayResponse?.stripeResponse?.merchantAccountId,
    };
    this.stripeService
      .setPublishableKey(this.createPaymentOrderResponse?.gatewayResponse?.stripeResponse?.publishableKey, keyOptions)
      .then((stripe: Stripe) => {
        this.showStripeForm = true;
        this.stripe = stripe;
        const elementOptions = {
          clientSecret:
            this.createPaymentOrderResponse?.gatewayResponse?.stripeResponse?.clientSecret,
          appearance: {
            theme: 'stripe',
          },
        };
        this.stripeElements = stripe.elements(elementOptions);
        this.card = this.stripeElements.create('linkAuthentication');
        this.card.mount('#link-authentication-element');
        this.payment = this.stripeElements.create('payment', { layout: 'tabs', });
        this.payment.mount('#payment-element');
        this.payment.on('ready', () => {
          this.showPayNowBtn = true;
        });
        return stripe;
      },
        (err => {
          this.handleStripeError(err);
          this.loadingService.decreaseLoadingCounter();
        }));
  }

  confirmPayment() {
    this.stripeErrMsg = '';
    this.loadingService.increaseLoadingCounter();
    this.gtmTrackingService.trackGa4(gtmConst.eventName.eventsGa4, gtmConst.pageName.onlinePaymentMethods, gtmConst.category.stripePayment);
    const payload = {
      elements: this.stripeElements,
      confirmParams: {
        return_url: this.getReturnUrl(),
      },
    };
    this.storeBookingInfo();
    if (this.isStipeSupported) {
      try {
        this.stripe.confirmPayment(payload).then((res: { error: any }) => {
          this.handleStripeError(res);
          this.loadingService.decreaseLoadingCounter();
        });
      } catch (e) {
        this.handleStripeError(e);
        this.loadingService.decreaseLoadingCounter();
      }
    } else {
      this.loadingService.increaseLoadingCounter();
      const stripeForwardingRequest: StripeForwardingRequest = {
        applicationId: this.applicationId,
        paymentTransactionId: this.createPaymentOrderResponse?.gatewayResponse?.stripeResponse?.paymentTransactionId
      };
      try {
        const setupPayload = {
          elements: this.stripeElements,
          confirmParams: {
            return_url: this.getReturnUrl(),
          },
          redirect: 'if_required' // To restrict auto navigation to payment confirmation page until forwarding response is received.
        };
        return new Promise<void>((resolve, reject) => {
          this.loadingService.increaseLoadingCounter();
          this.stripe.confirmSetup(setupPayload).then(() => {
            setTimeout(() => {
              const xCorrelationID = {
                id: this.commonService.appCorrelationId()
              }
              this.stripeSubscription = this.stripePaymentService.stripeForwarding(stripeForwardingRequest, xCorrelationID?.id)
                .subscribe((stripeForwardingResponse: StripeForwardingResponse) => {
                  const stripeForwardingCaptureRequest: StripeForwardingCaptureRequest = {
                    status: stripeForwardingResponse?.status as StripeForwardingCaptureStatus,
                    authorizeActionId: xCorrelationID?.id,
                    paymentId: stripeForwardingResponse?.paymentsOsPaymentId,
                    paymentTransactionId: this.createPaymentOrderResponse?.gatewayResponse?.stripeResponse?.paymentTransactionId
                  };
                  if (appSettings.site.environmentName === 'PROD' && stripeForwardingResponse?.redirection?.url) {
                    this.navigationService.storePaymentGateway(PaymentGateways.PAYMENTOS);
                    window.open(stripeForwardingResponse?.redirection?.url, '_self');
                  } else {
                    if (stripeForwardingCaptureRequest.status?.toLowerCase() === 'succeed' || stripeForwardingCaptureRequest.status?.toLowerCase() === 'success') {
                      this.stripeCaptureSubscription = this.stripePaymentService.stripeForwardingCapture(stripeForwardingCaptureRequest, xCorrelationID?.id).subscribe((stripeForwardingCaptureResponse: StripeForwardingCaptureResponse) => {
                        this.stripeForwardingResponseStatus = stripeForwardingCaptureResponse?.status;
                        if (this.stripeForwardingResponseStatus === StripeForwardingCaptureStatus.SUCCESS) {
                          this.navigationService.storePaymentState(PaymentStates.COMPLETED);
                          this.navigateOnPaymentType();
                        } else {
                          if (this.paymentType !== PaymentTypes.EOR) {
                            this.title = this.stripeForwardingResponseStatus === StripeForwardingCaptureStatus.FAILED ?
                              `Your current payment status is ${this.stripeForwardingResponseStatus},
                       navigate to dashboard to retrigger the payment.`
                              : `You current payment is in progress please check your IELTS profile for the latest status or
                         contact your test centre`;
                            this.acceptText = 'Return to My Account';
                            this.confirmationModal.open();
                            this.loadingService.decreaseLoadingCounter();
                          } else {
                            if (this.stripeForwardingResponseStatus === StripeForwardingCaptureStatus.FAILED) {
                              this.title = `Your current payment status is ${this.stripeForwardingResponseStatus},
                          please retry the payment.`;
                              this.acceptText = 'Retry Payment';
                              this.confirmationModal.open();
                              this.loadingService.decreaseLoadingCounter();
                            } else if (this.stripeForwardingResponseStatus === StripeForwardingCaptureStatus.PENDING) {
                              this.title = `You current payment is in progress please check your IELTS profile for the latest status or
                                   contact your test centre`;
                              this.acceptText = 'Return to My Account';
                              this.confirmationModal.open();
                              this.loadingService.decreaseLoadingCounter();
                            }
                          }
                        }
                      })
                    } else {
                      this.title = `Your current payment status is Failed or Pending. Please retry after sometime.`;
                      this.acceptText = 'Retry Payment';
                      this.confirmationModal.open();
                      this.loadingService.decreaseLoadingCounter();
                    }
                  }
                });
            }, 3000);
            resolve();
            this.loadingService.decreaseLoadingCounter();
          }, (err => {
            this.handleStripeError(err);
            this.loadingService.decreaseLoadingCounter();
          }));
          this.loadingService.decreaseLoadingCounter();
        });
      }
      catch (e) {
        this.handleStripeError(e);
        this.loadingService.decreaseLoadingCounter();
        this.disablePayNowButton = true;
      }
    }
    this.loadingService.resetLoadingCounter();
  }
  handleStripeError(err) {
    const defaultErrMsg = 'Service unavailable, please try after some time.';
    if (err?.error?.type !== 'validation_error') {
      this.stripeErrMsg = err?.error?.message || defaultErrMsg;
      this.toastr.error(this.stripeErrMsg);
    }
  }

  // after successful stripe payment, the user will be redirected to confirmation page
  // the page will be loaded fresh and all @ngrx/store data will be lost
  // storing the info necessary to show the confirmation page in local storage
  // local storage will be cleared after retrieving the info in confirmation.component.ts, my-tests.component.ts
  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
  }
  getRoute() {
    return this.isNewPaymentJourney === 'enabled'
      ?  `${location.origin}/account/booking-confirmation`
      :  `${location.origin}/payment/confirmation`;
  }
  getReturnUrl() {
    switch (this.paymentType) {
      case 'IOC':
        return this.getRoute();
      case 'EOR':
      case 'OSR':
        return `${location.origin}/my-tests`;
      default:
        return `${location.origin}/my-tests`;
    }
  }
  navigateOnPaymentType() {
    this.navigationService.navigateOnPaymentType(this.paymentType);
  }
  returnMyAccount() {
    this.loadingService.increaseLoadingCounter();
    if (this.paymentType === PaymentTypes.EOR) {
      setTimeout(() => {
        this.onReturn.emit(this.stripeForwardingResponseStatus);
      }, 5000);
    } else {
      setTimeout(() => {
        this.router.navigate(['/my-account']);
      }, 3000);
    }
  }

  ngOnDestroy(): void {
    this.stripeSubscription?.unsubscribe();
    this.stripeCaptureSubscription?.unsubscribe();
  }
}
