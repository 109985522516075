import { Component, NgZone, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { UserProfileValidateRequest, UserProfileValidateResponse } from '@idp-education/ors-test-taker-bff-client-v1';
import { UserProfileService } from 'shared/services/user-profile.service';
import { Auth } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { EMAIL_REGEXP } from 'src/app/shared/sharedRegex';
import { setBackAction } from 'src/app/store/global.actions';
import { Observable } from 'rxjs';
const { featureFlags } = require('src/assets/appSettings.json');
const { site } = require('src/assets/appSettings.json');

@Component({
  selector: 'app-entry-point',
  templateUrl: './entry-point.component.html',
  styleUrls: ['./entry-point.component.scss'],
})
export class EntryPointComponent implements OnDestroy {

  signupMail: UntypedFormControl;
  button: 'facebook' | 'google' | 'apple' | '' = '';
  public featureFlags: any = featureFlags;
  public site: any = site;
  constructor(private router: Router, private store: Store<{ globalStore }>, private zone: NgZone, private userProfileService: UserProfileService, private toastr: ToastrService) {
    this.signupMail = new UntypedFormControl('',
      Validators.pattern(EMAIL_REGEXP)
    );
    this.store.dispatch(setBackAction({ BackButtonEnable: false, BackButtonRoute: null }));

    // Logging the version to console, for debugging the Cloud Front caching
    // tslint:disable-next-line: no-console
    console.info('Build: ' + site?.buildInfo );

  }
  ngOnDestroy(): void {
    this.store.dispatch(setBackAction({ BackButtonEnable: true, BackButtonRoute: '/account' }));
  }
  onEnterEmail(event) {
    if (event.key === 'Enter' && this.signupMail.valid) {
      this.redirectToSignUp();
    }
  }
  redirectToSignUp() {
    this.validateAccountExist(this.signupMail.value?.toLowerCase()).subscribe({
      next: (emailValidate) => {
        if (emailValidate?.recaptchaSuccessFlag) {
          sessionStorage.setItem('emailValidate', JSON.stringify(emailValidate));
          const url = (emailValidate?.emailExistsFlag || emailValidate?.userProfileSub) ? '/account-v1/signin-v1' : '/account-v1/signup-v1';
          if (url === '/account-v1/signin-v1') {
            this.toastr.info('Account already exists. Please sign in to continue');
          }
          this.zone.run(() => this.router.navigate([url]));
          localStorage.setItem('emailID', this.signupMail.value);
        }
        else {
          this.toastr.error('Error occurred. Please try again later');
        }
      },
      error: (error) => {
        this.toastr.error(error?.message || 'Error occurred. Please try again later');
      }
    })
  }
  public validateAccountExist(emailAddress: string): Observable<UserProfileValidateResponse> {
    const validateEmailDetails: UserProfileValidateRequest = { emailAddress, token: this.randomToken, enable_captcha_validation: false };
    return this.userProfileService.validateUserProfile(validateEmailDetails);
  }

  get randomToken() {
    const generateBuffer = new Uint16Array(1);
    crypto.getRandomValues(generateBuffer)
    return generateBuffer[0].toString();
  }

  facebookLogin() {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook }).then((credentials) => {
      console.log(credentials);
    }).catch((error) => {
      console.error('error signing in: ', error);
    });

  }
  AppleLogin() {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple }).then((credentials) => {
      console.log(credentials);
    }).catch((error) => {
      console.error('error signing in: ', error);
    });
  }
  googleLogin() {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    }).then((credentials) => {
      console.log(credentials);
    })
      .catch((error) => {
        console.error('error signing in: ', error);
      });

  }

  onHoverAction(button: 'facebook' | 'google' | 'apple') {
    this.button = button;
  }

  onBlur() {
    this.button = '';
  }

}
