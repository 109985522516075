import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationService } from '@idp-education/ors-test-taker-bff-client-v1';
import { DashboardResponse, ApplicationService as ApplicationServiceV2, DashboardItem } from '@idp-education/ors-test-taker-bff-client-v2';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { setDashboard } from './../../store/dashboard/dashboard.action';
import { pluck, map, defaultIfEmpty, share, tap, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CommonService } from './common.service';
const { requestExpireTime } = require('src/assets/appSettings.json');
import * as uuid from 'uuid';
@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private appRequest$: BehaviorSubject<DashboardResponse> = new BehaviorSubject(null);
    public applicationRequest: Observable<DashboardResponse> = this.appRequest$.asObservable();
    private fetchApplication$: Observable<DashboardResponse>;
    private forceFetch = false;

    private _lastFetchTime: number;
    // less then 1 sec
    private readonly expireTime = requestExpireTime.dashboard;

    public get lastFetchTime(): number {
        return this._lastFetchTime;
    }
    private sub = new Subscription();
    enableDashboardV2: boolean;

    constructor(private applicationService: ApplicationService,
                private store: Store<{ dashboardStore, globalStore, userStore }>,
                private authenticationService: AuthService,
                private applicationServiceV2: ApplicationServiceV2,
                private commonService: CommonService) {

        this.sub.add(this.store?.select(st => st.userStore).subscribe(data => {
            if (data?.isLogOut) {
                this.clear();
            }
        }));
        this.sub.add(this.store?.select(appState => appState?.globalStore?.enableDashboardV2)
        .subscribe(enableDashboardV2 => {
            if (enableDashboardV2) {
                this.enableDashboardV2 = enableDashboardV2;
                localStorage.setItem('enableDashboardV2', JSON.stringify(enableDashboardV2));
            }
        }));
    }
    correlationId() {
        return location.href.endsWith('my-account') || location.href.endsWith('my-tests') ? uuid.v4() : this.commonService?.getUserUuid();
    }
    private fetchApplications(): Observable<DashboardResponse> {
        return this.authenticationService.checkToken().pipe(
            switchMap( () => {
                const getDashBoardItem = localStorage.getItem('enableDashboardV2');
                this.enableDashboardV2 = (getDashBoardItem === 'true');
                return this.enableDashboardV2 ?
                    this.applicationServiceV2.getApplicationsDashboardV2(this.correlationId()) :
                    this.applicationService.getApplicationsDashboard(this.correlationId());
            }), tap(data => {
                this.appRequest$?.next(data);
                this._lastFetchTime = new Date().getTime();
            }), share()
        );
    }

    isThereUpcomingTests(): Observable<boolean> {
        return this.getAllDashboardApplications().pipe(map<DashboardResponse, boolean>(app => {
            if (Array.isArray(app?.upcomingTests) && app?.upcomingTests.length) {
                return !!app.upcomingTests.length;
            } else {
                return false;
            }
        }));
    }
    isThereResultAvailable(): Observable<boolean> {
        return this.getAllDashboardApplications().pipe(map<DashboardResponse, boolean>(app => {
            if (Array.isArray(app?.pastTests)) {
                return !!app.pastTests.filter(i => i.status === 'RESULTS_AVAILABLE' || i.status === 'EOR_IN_PROGRESS'
                    || i.status === 'EOR_COMPLETED').length;
            } else {
                return false;
            }
        }));

    }
    isTherePastTests(): Observable<boolean> {
        return this.getAllDashboardApplications().pipe(map<DashboardResponse, boolean>(app => {
            if (Array.isArray(app?.pastTests)) {
                return !!app.pastTests.length;
            } else {
                return false;
            }
        }));
    }
    getAllDashboardApplications(force = false): Observable<DashboardResponse> {
        this.forceFetch = force;
        const isTheLastReqExpired = () => {
            return new Date().getTime() - this.lastFetchTime > this.expireTime;
        };
        if (force || (!force && (!this.appRequest$?.value || isTheLastReqExpired()))) {
            if (!this.fetchApplication$) {
                this.fetchApplication$ = this.fetchApplications();
            }
            return this.fetchApplication$;
        } else if (this.appRequest$?.value && !this.applicationRequest) {
            this.applicationRequest = this.appRequest$?.asObservable();
        }
        this.fetchApplication$ = null;
        return this.applicationRequest;


    }
    getUpcomingTests(): Observable<DashboardItem[]> {
        return this.getAllDashboardApplications().pipe(pluck('upcomingTests'));
    }
    getPastTests(): Observable<DashboardItem[]> {
        return this.getAllDashboardApplications().pipe(map<DashboardResponse, DashboardItem[]>(app => app?.pastTests));
    }
    getCancelledTests(): Observable<DashboardItem[]> {
        return this.getAllDashboardApplications().pipe(map<DashboardResponse, DashboardItem[]>(app =>
            app?.pastTests?.filter(i => i.status === 'CANCELLED')
        ), defaultIfEmpty([]));
    }
    clear() {
        this.sub.unsubscribe();
        this.appRequest$?.next(null);
        this.appRequest$ = null;
        this.applicationRequest = null;
        this.store.dispatch(setDashboard(null));
        localStorage.removeItem('enableDashboardV2');
    }
    getLastResultAvailable(): Observable<DashboardItem> {
        return this.getAllResultAvailable().pipe(
            map(items => {
                // Since the dashboard returns past test with results sorted from backend as part of 23396
                // No need for sorting in the UI.
                return items[0];
            })
        );
    }
    getAllResultAvailable(): Observable<DashboardItem[]> {
        return this.getPastTests().pipe(
            map((items) => items.filter(i => i.status === 'RESULTS_AVAILABLE' || i.status === 'EOR_IN_PROGRESS' || i.status === 'EOR_COMPLETED')));
    }
    hasAnyUnpaid(): Observable<boolean> {
        return this.getUpcomingTests().pipe(
            map(items => items.filter(i => i.status === 'UNPAID')),
            map(data => !!data.length));
    }
    getFirstUnpaidApplicationId(): Observable<DashboardItem> {
        return this.getAllDashboardApplications().pipe(
            pluck('upcomingTests'),
            map(items => items.filter(i => i.status === 'UNPAID')),
            map(data => data[0]));
    }
}
