import { Component, NgZone, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Auth } from '@aws-amplify/auth';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Router } from '@angular/router';
import { SharedStateService } from '../shared-state.service';
import { Title, Meta } from '@angular/platform-browser';
import { CognitoErrorService } from 'src/app/shared/services/cognito-error.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  $form: UntypedFormGroup;
  stateData = {};
  hasError = false;
  error = '';
  customMessages = {
    email: {
      pattern: () => $localize`A valid email address is required`
    },
  };
  constructor(
    private fb: UntypedFormBuilder,
    public loadingService: LoadingService,
    private state: SharedStateService,
    private cognitoError: CognitoErrorService,
    private zone: NgZone,
    private router: Router, private titleService: Title, private metaTagService: Meta) {
    this.titleService.setTitle('Reset your password | IDP IELTS');
    this.state.getState().subscribe(data => {
      this.stateData = data;
      this.$form = this.fb.group({
        email: [data.email || '', [
          Validators.required,
          Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ]]
      });
    });
  }
  onSubmit() {
    if (this.$form.invalid) {
      return false;
    }
    this.hasError = false;
    this.loadingService.isLoading.next(true);
    Auth.forgotPassword(this.$form.controls.email.value).then((data) => {
      this.state.changeState({
        email: this.$form.controls.email.value
      });
      this.loadingService.isLoading.next(false);
      this.zone.run(() => {
        this.router.navigate(['account-v1/reset-password/step1']);
      })
    }).catch((error) => {
      this.loadingService.isLoading.next(false);
      this.hasError = true;
      this.error = this.cognitoError.getResetPasswordErrorMsg(error);
    });
  }
  inputChanged() {
    if (this.hasError) {
      this.hasError = false;
      this.error = '';
    }
  }
  ngOnInit(): void {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Unable to access your IDP account? Reset your password in simple steps.' },
    );

  }

}
