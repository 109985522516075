import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { LandingPageService } from '@idp-education/ors-test-taker-bff-client-v1';
import { CookieService } from 'ngx-cookie';
import { IOCLocalStorageItemKeys, IOCSessionStorageItemKeys } from 'pages/payment/payment.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { gtmConst } from 'shared/utils/trackingConstants';
import * as uuid from 'uuid';
const appSettings = require('src/assets/appSettings.json');
@Injectable({
  providedIn: 'root'
})

export class CommonService {
  private isOtherLanguageSelectedSubject = new BehaviorSubject<boolean>(false);
  isOtherLanguageSelected$ = this.isOtherLanguageSelectedSubject.asObservable();
  private isOtherNationalitySelectedSubject = new BehaviorSubject<boolean>(false);
  isOtherNationalitySelected$ = this.isOtherNationalitySelectedSubject.asObservable();
  constructor(private readonly landingService: LandingPageService,
    private cookieService: CookieService
  ) { }
  getUserUuid() {
    return this.cookieService?.get('user_uuid') || uuid.v4();
  }
  getContentfulDetailsApi(url: string): Observable<any> {
    const countryName = this.getSessionStorageValue(IOCSessionStorageItemKeys.testLocalTimeZoneVal);
    return this.landingService.getContentfulData('en-GB', url, this.getUserUuid(), countryName?.split('/')[0]?.toLowerCase());
  }
  getLocalStorageValue(key: any) {
    const details = localStorage.getItem(key);
    return details ? JSON.parse(details) : ''
  }
  getSessionStorageValue(key:any){
    const sessionDetails = sessionStorage.getItem(key);
    return sessionDetails ? JSON.parse(sessionDetails) : ''
  }
  appCorrelationId() {
    return localStorage?.getItem('appCorrelationId') || this.getUserUuid();
  }
  removeCorrelationIdFromCookie(){
    this.cookieService?.remove(gtmConst?.common?.userUuid, { path: '/', domain: appSettings?.site?.envSubDomain, secure: true, sameSite: 'none' });
  }
  setSessionStorageValue(key, value, stringifyData?) {
    if (stringifyData) {
        const stringifiedValue = JSON.stringify(value);
        sessionStorage.setItem(key, stringifiedValue);
      
    } else {
      sessionStorage.setItem(key, value);
    }
  }
  
  updateLanguageOtherValidators(languageOtherControl?: AbstractControl, isOtherLanguageSelected?: boolean): void {
    if (isOtherLanguageSelected) {
      languageOtherControl?.setValidators([Validators.required, Validators.maxLength(50)]);
      languageOtherControl?.updateValueAndValidity();
    } else {
      languageOtherControl?.setValue(undefined);
      languageOtherControl?.setValidators([]);
      languageOtherControl?.updateValueAndValidity();
    }
  }

  updateNationalityOtherValidators(nationalityOtherControl?: AbstractControl, isOtherNationalitySelected?: boolean): void {
    if (isOtherNationalitySelected) {
      nationalityOtherControl?.setValidators([Validators.required, Validators.maxLength(50)]);
      nationalityOtherControl?.updateValueAndValidity();
    } else {
      nationalityOtherControl?.setValue(undefined);
      nationalityOtherControl?.setValidators([]);
      nationalityOtherControl?.updateValueAndValidity();
    }
  }

  onFieldChange(event: any, getProfileControl: (name: string) => AbstractControl, getIdControl: (name: string) => AbstractControl): void {
    const { selected, field } = event;

    if (field === 'language') {
      const isOtherLanguageSelected = selected === 'Other';
      this.setOtherLanguageSelected(isOtherLanguageSelected);
      this.updateLanguageOtherValidators(getProfileControl('languageOther'), isOtherLanguageSelected);
    } else if (field === 'nationality') {
      const isOtherNationalitySelected = selected === 'Other';
      this.setOtherNationalitySelected(isOtherNationalitySelected);
      this.updateNationalityOtherValidators(getIdControl('nationalityOther'), isOtherNationalitySelected);
    }
    else {
      if (field === 'language') {
        this.setOtherLanguageSelected(false);
      } else if (field === 'nationality') {
        this.setOtherNationalitySelected(false);
      }
  }
  }

  setOtherLanguageSelected(selected: boolean): void {
    this.isOtherLanguageSelectedSubject.next(selected);
  }
  setOtherNationalitySelected(selected: boolean): void {
    this.isOtherNationalitySelectedSubject.next(selected);
  }
}
