import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICon } from '../payment-container/payment-container.component';
import { Application, CreateReceiptRequest, CreateReceiptResponse, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { Subscription } from 'rxjs';
import { UserState } from 'store/user/user.reducer';
import { Store } from '@ngrx/store';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { NavigationService } from 'shared/services/navigation.service';
import { LoadingService } from 'shared/services/loading-service.service';
import { HsbcomniPaymentService, PaymentMethod } from '@idp-education/ors-hsbcomni-payment-bff-client-v1';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { SharedStateService } from 'pages/account/shared-state.service';
import { PaymentsService } from 'shared/services/payment.services';
import { CommonService } from 'shared/services/common.service';
const appSettings = require('src/assets/appSettings.json');
@Component({
  selector: 'app-hsbc-omni',
  templateUrl: './hsbc-omni.component.html',
  styleUrls: ['./hsbc-omni.component.scss']
})
export class HsbcOmniComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() tcPaymentMethod = [];
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @Input() isNewJourney = false;
  receiptExpiredValue;
  static get tab(): ICon {
    return {
      alt: 'hsbcomni',
      key: 'HSBCOMNI',
      url: '/assets/images/payment/hsbcomni.svg',
      class: '',
      title: 'Pay online with Credit Card, Alipay and WeChat Pay',
      description: 'Secure payments powered by HSBC Omni',
    };
  }

  sub: Subscription;
  userState: UserState;
  receiptResponse: CreateReceiptResponse;

  tcPaymentMethodId: string;
  paymentSelected: any;
  paymentMethods = [];
  isPaymentMethod = false;
  isLoading = false;

  cardTitle: string;
  alipayTitle: string;
  wechatpayTitle: string;
  redirectLinkContent: any;

  constructor(
    private receiptService: ReceiptService,
    private navigationService: NavigationService,
    private loadingService: LoadingService,
    private hsbcomniPaymentService: HsbcomniPaymentService,
    private sharedState: SharedStateService,
    private paymentService: PaymentsService,
    private commomService: CommonService,
    private store: Store<{ userStore }>
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.userState = x;
    });
  }

  ngOnInit(): void {
    this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.HSBCOMNI) {
        this.onCardClick(paymentDetails.typeVal);
      }
    }));
    this.isLoading = false;
    const tcPaymentMethod = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.HSBCOMNI);
    this.tcPaymentMethodId = tcPaymentMethod?.id;
    this.paymentMethods = tcPaymentMethod?.paymentMethodConfig?.document?.paymentMethodConfiguration?.paymentMethods;
    this.cardTitle = this.paymentMethods?.includes('CREDIT_CARD') ? 'Card Payment' : '';
    this.alipayTitle = this.paymentMethods?.includes('ALIPAY') ? 'Alipay' : '';
    this.wechatpayTitle = this.paymentMethods?.includes('WECHATPAY') ? 'WeChat Pay' : '';
    this.isPaymentMethod = this.paymentMethods?.length ? true : false;
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onCardClick(paymentType) {
    if (paymentType === 'CREDIT_CARD') {
      this.paymentSelected = PaymentMethod.CREDITCARD;
    } else if (paymentType === 'ALIPAY') {
      this.paymentSelected = PaymentMethod.ALIPAY;
    } else if (paymentType === 'WECHATPAY') {
      this.paymentSelected = PaymentMethod.WECHATPAY;
    }
    this.isLoading = true;
    this.createReceipt();
  }

  createReceipt() {
    const createReceiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: this.tcPaymentMethodId,
      paymentMethod: this.paymentSelected
    };

    const postReceipt = this.receiptService.createReceipt(this.commomService.appCorrelationId(),createReceiptRequest)?.subscribe(result=> {
      this.receiptResponse = result;
      this.storeBookingInfo();
    },(error) => {
      if(error?.includes('APPLICATION_EXPIRED')){
        this.receiptExpiredValue = this.paymentService.getErrorState();
      }
    });
    this.sub.add(postReceipt);
  }

  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.HSBCOMNI);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
    this.fetchFormData();
  }

  fetchFormData() {
    this.hsbcomniPaymentService.configuration.accessToken = this.userState?.userToken;
    const appId = this.applicationId;
    const receiptId = this.receiptResponse.id;
    const postSub = this.hsbcomniPaymentService.redirectform(appId, receiptId, this.paymentSelected, this.commomService.appCorrelationId()).subscribe(result => {
      this.loadingService.increaseLoadingCounter();
      this.redirectLinkContent = result?.htmltFormStr;
      document.getElementById('form-container').innerHTML = this.redirectLinkContent;
      const formElement = document.getElementById('pay_form') as HTMLFormElement;
      if (formElement) {
        formElement.submit();
      }
      this.loadingService.decreaseLoadingCounter();
    }, error => {
      this.loadingService.decreaseLoadingCounter();
    });
    this.sub.add(postSub);
  }

  backToListingPage(): void {
    window.open(`${appSettings.site.bxsearchURL}/book-ielts`, '_self');
  }
}
