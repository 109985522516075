import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import {
  UserProfileAddressDetails,
  Gender,
  Nationality,
  ApplicationService,
  IdentityStatus,
  UserProfile,
  GetTestLocationIdentificationTypesItem,
  IdentificationType,
  ApplicationUserProfilePatchRequest,
  ApplicationUserProfileUpdateRequest,
  ProductService,
  ApplicationResponse,
  FeatureFlagsService,
  RetrieveFeatureFlagRequest
} from '@idp-education/ors-test-taker-bff-client-v1';
import { catchError, concatMap, first, map, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Storage } from 'aws-amplify';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { ISections } from '../../my-profile/models';
import { Title, Meta } from '@angular/platform-browser';
import { PrepareSurveyFormService, moveOtherToLast } from '../../my-profile/prepare-survey-form.service';
import { SetLatestPageUrl } from '../../booking/store/booking.actions';
import { User } from '../../account/signup/userProfile.model';
import { MapperService } from '../../my-profile/mapper.service';
import { selectOriginalApplication } from 'store/my-tests/my-tests.reducer';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { postValidator } from 'src/app/shared/validators/post-validator';
import {
  addressDefaultValueValidator,
  countryDefaultValueValidator,
  expiryDateValidator,
  nameValidator,
  nationalityDefaultValueValidator,
  noSpecialCharactersValidator,
  statusValidator
} from 'src/app/shared/validators/custom-validators';
import { USER_SIGNUP_CONSTANTS_V2 } from 'src/app/pages/account/signup/signup.constants';
import { ToastrService } from 'ngx-toastr';
import { Options } from '../../onboarding/profile/options';
import { ADDRESS_REGEXP } from 'src/app/shared/sharedRegex';
import { titleGenderValidator } from 'src/app/shared/validators/gender-title.validator';
import { emptyWhiteSpaceValidator } from 'src/app/shared/validators/empty-whitespace-validator';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { ApplicationDocumentUploadFormComponent, DocumentPhotoUpdateEvent } from 'shared/components/forms/application-document-upload-form/application-document-upload-form.component';
import { getCountryCode } from 'shared/utils/phone-number-helper';
import { DatePipe } from '@angular/common';
import { CommonService } from 'shared/services/common.service';
const appSettings = require('src/assets/appSettings.json');

const { signup } = require('src/assets/appSettings.json');
export enum Sections {
  ukviInfo = 'ukvi-info',
  ieltsInfo = 'ielts-info',
  address = 'address',
  profileInfo = 'profile-info'
}
export enum ImageIds {
  ID1 = 'id1',
  ID2 = 'id2'
}
export type Is3IdImage = { s3Url: string, amzVersion?: string };
export type Is3Image = { primaryId: Is3IdImage, secondaryId?: Is3IdImage };
export type IidImageSave = { imageNo: string, updatedFile?: { file: File, url: string } };
@Component({
  selector: 'app-update-application-details',
  templateUrl: './update-application-details.component.html',
  styleUrls: ['./update-application-details.component.scss'],
  providers: [PrepareSurveyFormService, DatePipe]
})
export class UpdateApplicationDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('cancelChangesConfirmation') cancelChangesConfirmation: ConfirmModalComponent;
  sections = Sections;
  callToActions = [];
  addressDetailsForm: UntypedFormGroup;
  personalNIdInformationForm: UntypedFormGroup;
  // To be used when users try to update the document photo
  cachedIdInformation?: any;
  ieltsInfoForm: UntypedFormGroup;
  applicationId: string;
  personalUserTitle: { name: string, value: string }[] = [
    { name: 'Mr', value: 'MR' },
    { name: 'Mrs', value: 'MRS' },
    { name: 'Miss', value: 'MISS' },
    { name: 'Dr', value: 'DR' },
  ];
  activeIds = [this.sections.profileInfo];
  documentImageUpdated = false;
  documentImageUpdatedLocally = { id1: false, id2: false };
  documentImageUploaded = false;
  personalGenderList: Gender[] = [];
  personalFieldsName: string[];
  subscription = new Subscription();
  userProfile: UserProfile;
  primaryIdImage: Is3IdImage | null = null;
  secondaryIdImage: Is3IdImage | null = null;
  // Application have no image and user have the default image from the sign up process
  idImageWasDefault = false;
  idImageObservable: Subject<Is3Image> = new Subject();

  selectedIDType: IdentificationType; // To store selected ID type data
  IdentificationTypes: GetTestLocationIdentificationTypesItem[] = [];
  isVerified: boolean;
  ischeckVerified: boolean;
  userProfileVersion: string;
  options = Options;
  expiryDateCheck = false;
  id1Updated = false;
  isImgAvailable: string;
  moveOtherToLast = moveOtherToLast;
  transformedTestDate: any;
  verifyMsg: string = $localize`You will not be able to update certain fields, please contact the test centre directly`;
  profileAlertMsg: string = $localize`Any changes to the application details will not reflect in your profile`;
  myTestProfileAlertMsg: string = $localize`Prior to your IELTS test, you must <span class='text-danger'>ensure all your application details are updated and correct. </span>Please <span class='text-danger'>review your profile information, address, ID and IELTS information </span>below.`;
  uploadTitle: string = $localize`Upload your ID document`;
  uploadSubTitle: string = $localize`To complete your test booking, you must upload your ID document and bring the same document to your test day.`;
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  languages: any[];
  educationLevelList: any[];
  occupationLevelList: any[];
  countryList: any[];
  testReasonList: any[];
  occupationSectorList: any[];
  studyEnglishYearsList: Array<{
    id: number,
    name: string
  }> = [];
  nationalities: Array<Nationality> = [];
  mapperService: MapperService;
  version: string;
  dataReady = false;
  isUKVIApplication = false;
  // TODO: Init the form after application detail fetched from API call
  UKVIInfoForm?: UntypedFormGroup;
  getProductId: string;
  // To let children or parent knows user want to cancel the changes
  cancelChangesid1$ = new Subject<void>();
  cancelChangesid2$ = new Subject<void>();
  saveTheChangesid1$ = new Subject<IidImageSave>();
  saveTheChangesid2$ = new Subject<IidImageSave>();
  resetCacheUpload$ = new Subject<void>();
  isOSRApplication = false;
  resetTransformId1$ = new Subject<string>();
  resetTransformId2$ = new Subject<string>();
  imageTransformedId1 = false;
  imageTransformedId2 = false;
  selectedCountryISO = '';
  isSecondaryImage: boolean;
  featureFlagEnable = false;
  imageNo: string;
  updatedFile: Array<any> = [];
  isCefrDataAvailable: boolean;
  appCorrelationId = '';
  constructor(
    private store: Store<{ bookingStore, onboardingStore, myTestsStore, userStore }>,
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private apiService: ApiService,
    private titleService: Title,
    private metaTagService: Meta,
    private applicationService: ApplicationService,
    private toast: ToastrService,
    private prepareSurveyFormService: PrepareSurveyFormService,
    private productService: ProductService,
    private cdr: ChangeDetectorRef,
    private accConfig: NgbAccordionConfig,
    private featureFlagService: FeatureFlagsService,
    private datePipe: DatePipe,
    private zone: NgZone,
    private commonService: CommonService
  ) {
    this.accConfig.closeOthers = true;
    this.titleService.setTitle('My Profile | IDP IELTS');
    this.store.dispatch(SetLatestPageUrl({ latestPage: null }));
  }

  getFieldOptions() {
    this.getNationalities();
    this.getGender();
    this.getYearsStudyingEnglishList();
    forkJoin([
      this.prepareSurveyFormService.getLanguagesList(),
      this.prepareSurveyFormService.getReasonTestList(),
      this.prepareSurveyFormService.getOccupationLevel(),
      this.prepareSurveyFormService.getOccupationSector(),
      this.prepareSurveyFormService.getEducationLevel(),
      this.prepareSurveyFormService.getCountries()
    ])
      .subscribe((results) => {
        if (results) {
          this.languages = results[0];
          this.testReasonList = results[1];
          this.occupationLevelList = results[2];
          this.occupationSectorList = results[3];
          this.educationLevelList = results[4];
          this.countryList = results[5];
        }
      }, (err) => { /* empty */ });
  }
  loadData() {
    let testLocId: string;
    this.subscription.add(this.store.select(selectOriginalApplication).pipe(
      first(),
      concatMap(dashboardItem => {
        // TODO: If the application was UKVI then init the UKVIInfoForm
        this.applicationId = dashboardItem.applicationId;
        this.isUKVIApplication = this.isThisApplicationSelt(dashboardItem);
        this.isOSRApplication = this.isThisApplicationOSR(dashboardItem);
        return this.applicationService.getApplication(dashboardItem.applicationId, this.commonService?.getUserUuid()).pipe(first());
      }),
      tap(data => {
        if (this.isUKVIApplication) {
          this.UKVIInfoForm = this.defineForm(data);
        }
      }),
      map((application) => {
        this.appCorrelationId = application?.['applicationCorrelationId'];
        this.featureFlag();
        this.isVerified = application.identityStatus === IdentityStatus.VERIFIED;
        this.ischeckVerified = true;
        this.version = application.version;
        testLocId = application.bookings[0]?.testLocationId;
        this.getProductId = application.bookings[0]?.bookableProductId;
        const testDate = application.bookings?.[0]?.bookingLines?.[0]?.startDateTimeLocal ?? null;
        if (testDate) {
          this.transformedTestDate = this.datePipe.transform(testDate, 'dd/MM/yyyy');
        }
        return application.userProfile;
      })
    )?.subscribe((uProfile: UserProfile) => {
      if (uProfile) {
        this.userProfile = uProfile;
        this.isThisProductIdUKVI(this.getProductId).subscribe(isUKVI => {
          const getIdService = isUKVI ? this.getProductIdTypes(this.getProductId) : this.getTestLocIdTypes(testLocId);
          getIdService.then(() => {
            this.prepareForms();
            this.setS3ImageUrl();
            this.setIssuingAuthorityValidator(isUKVI, this.personalNIdInformationForm);
            this.initialProfileInfoUpdate();
            this.mapperService.versionId = this.version;
            this.mapperService.userProfileId = this.userProfile?.id;
            this.mapperService.surveyForm = this.ieltsInfoForm;
            this.isSecondaryImage = this.userProfile?.identityDetails?.numberOfIdImages > 1;
            this.dataReady = true;
            this.loadingService.decreaseLoadingCounter();
          });
        });
      }
    }));
  }
  isThisApplicationOSR(dashboardItem: any): any {
    return (dashboardItem.productName as string).toLowerCase().includes('osr');
  }

  defineForm(data: ApplicationResponse): UntypedFormGroup {
    const seltDetail = data?.seltDetails;
    if (seltDetail?.targetCefrLevel !== undefined && seltDetail?.targetCefrLevel) {
      this.isCefrDataAvailable = true
    } else {
      this.isCefrDataAvailable = false
    }
    let targetCefrLevel
    if (!seltDetail?.targetCefrLevel?.includes("Id")) {
      targetCefrLevel = seltDetail?.targetCefrLevel
    } else {
      targetCefrLevel = seltDetail?.targetCefrLevel?.replace(/'/g, '"').replace(/False/g, 'false');
      targetCefrLevel = targetCefrLevel ? JSON.parse(targetCefrLevel) : undefined;
    }
    return new UntypedFormGroup({
      isTestForUKVI: new UntypedFormControl(seltDetail?.isForUkvi, Validators.required),
      targetCefrLevel: new UntypedFormControl(targetCefrLevel, Validators.required),
      isUKVISponsorStatus: new UntypedFormControl(seltDetail?.isForStudySponsoredStatus),
      isUKVIReferral: new UntypedFormControl(seltDetail?.isReferredByPartner),
      referralName: new UntypedFormControl(seltDetail?.referralPartnerName || ''),
      termAndCondition: new UntypedFormControl(''),
      issuingAuthority: new UntypedFormControl(data?.userProfile?.identityDetails?.issuingAuthority || '')
    });
  }

  private isThisApplicationSelt(dashboardItem: any): boolean {
    return (dashboardItem.productName as string).includes('SELT') ||
      (dashboardItem.productName as string).includes('UKVI');
  }

  setS3ImageUrl() {
    const identityDetails = this.userProfile?.identityDetails;
    const additionalImages = identityDetails?.additionalImages?.[0];
    const primaryS3Url = identityDetails?.s3Url;
    const primaryAmzVersion = identityDetails?.version;
    const secondaryS3Url = additionalImages?.s3Url;
    const secondaryAmzVersion = additionalImages?.version;
    this.idImageWasDefault = (this.userProfile?.identityDetails?.s3Url === signup.s3IdImageUrl || this.userProfile?.identityDetails?.s3Url === signup.oldS3IdImageUrl);
    this.primaryIdImage = (primaryS3Url && primaryS3Url !== signup.s3IdImageUrl && primaryS3Url !== signup.oldS3IdImageUrl)
      ? { s3Url: primaryS3Url, amzVersion: primaryAmzVersion }
      : null;
    this.secondaryIdImage = (secondaryS3Url && secondaryS3Url !== signup.secondaryS3IdImageUrl)
      ? { s3Url: secondaryS3Url, amzVersion: secondaryAmzVersion }
      : undefined;
    this.callIdImageObservable(this.primaryIdImage, this.secondaryIdImage);
    if (!this.primaryIdImage && !this.secondaryIdImage) {
      this.primaryIdImage = null;
      this.documentImageUpdated = false;
    }
  }

  getS3ImageUrl() {
    return signup.s3IdImageUrl;
  }

  callIdImageObservable(primaryId: Is3IdImage, secondaryId?: Is3IdImage): void {
    this.idImageObservable.next({ primaryId, secondaryId });
  }

  getNewOptionId(list: any[]): string {
    const option = list.find(i => (i?.name as string)?.toLowerCase() === 'other');
    return option?.id;
  }

  private prepareForms() {
    const idTypes: Subject<GetTestLocationIdentificationTypesItem[]> = new Subject();
    this.personalNIdInformationForm = this.preparePersonalNIdInformationForm();
    this.addressDetailsForm = this.prepareAddressForm();
    this.ieltsInfoForm = this.prepareIeltsInformationForm();
    this.mapperService = new MapperService(this.userProfile,
      this.addressDetailsForm,
      this.personalNIdInformationForm,
      this.idImageObservable,
      this.personalNIdInformationForm,
      this.personalNIdInformationForm,
      idTypes);
  }

  async getPhoto(key: string): Promise<string> {
    const photo = await Storage.get(key) as string;
    return photo;
  }

  get isId2(): boolean {
    return this.imageNo === ImageIds.ID2;
  }

  ngOnInit() {
    this.metaTagService.updateTag(
      {
        name: 'description',
        content: 'Check available IELTS online test dates, your IELTS test results. Book your IELTS test with IDP today.'
      },
    );
    this.getFieldOptions();
    this.loadData();
  }
  featureFlag() {
    const secondaryImageFlag: string = appSettings?.site?.secondaryImageFlag;
    const payload: RetrieveFeatureFlagRequest = {
      flagNames: [
        {
          flagName: 'two_id_images',
          profileName: secondaryImageFlag
        }
      ]
    };
    this.featureFlagService.retrieveFeatureFlag(this.appCorrelationId, payload).subscribe(res => {
      this.featureFlagEnable = res['configDetails']?.length > 0 ? res['configDetails'][0]['enabled'] : false;
    });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.cancelChangesid1$.complete();
    this.cancelChangesid2$.complete();
    this.saveTheChangesid1$.complete();
    this.saveTheChangesid2$.complete();
  }

  initialProfileInfoUpdate() {
    this.subscription.add(this.personalNIdInformationForm.get('mobileNumber').valueChanges.pipe(first())
      .subscribe((v) => {
        if (v) {
          const telInput = this.personalNIdInformationForm.get('mobileNumber').value?.number;
          if (telInput) {
            this.personalNIdInformationForm.get('mobileNumber').setValue(telInput.replaceAll(' ', ''));
          }
          setTimeout(() => {
            this.personalNIdInformationForm.get('mobileNumber').markAsPristine();
          }, 1);
        }
      }));
  }

  fetchLatestApplicationVersion(): void {
    this.subscription.add(this.applicationService.getApplication(this.applicationId, this.commonService?.getUserUuid())
      .subscribe({
        next: application => this.version = application.version
      }));
  }
  modifiedS3Url(imageUrl: string) {
    this.isImgAvailable = imageUrl;
    if (imageUrl?.length > 0) {
      const indx = imageUrl.lastIndexOf('/');
      const fileName = imageUrl.substring(indx + 1);
      return fileName;
    }
    return null;
  }

  private hasUpdatedFiles(): boolean {
    return this.updatedFile.length > 0;
  }

  onUploadDocumentPhoto(e) {
    this.imageNo = e?.imageId ? `id${e.imageId}` : this.imageNo;
    const newImageUrl = e?.URL;
    const newAmzVersion = e?.amzVersion;
    if (this.isId2) {
      this.secondaryIdImage = { s3Url: newImageUrl, amzVersion: newAmzVersion };
    } else {
      this.primaryIdImage = { s3Url: newImageUrl, amzVersion: newAmzVersion };
    }
    this.callIdImageObservable(this.primaryIdImage, this.secondaryIdImage);
    this.documentImageUpdated = true;
    this.documentImageUpdatedLocally[this.imageNo] = false;
    this.clearCachedIdInformation();
    this.updatedFile = this.updatedFile.filter(val => val.imageNo !== this.imageNo);
    if (this.hasUpdatedFiles()) {
      this.saveFileChanges(this.updatedFile[0]);
    }
    else {
      this.updatePersonalNIdDetails();
    }
    this.idImageWasDefault = false;
  }

  saveFileChanges(updatedFile: { imageNo: string; file: File; url: string }): void {
    const saveEvent = { imageNo: updatedFile.imageNo, updatedFile };
    if (updatedFile.imageNo === ImageIds.ID2) {
      this.saveTheChangesid2$.next(saveEvent);
    } else {
      this.saveTheChangesid1$.next(saveEvent);
    }
  }

  onImageTransformReset(url?: string, idNo?: string): void {
    this.clearCachedIdInformation();
    if (url) {
      if (idNo === ImageIds.ID2) {
        this.secondaryIdImage = { s3Url: url, amzVersion: this.secondaryIdImage.amzVersion };
      }
      else {
        this.primaryIdImage = { s3Url: url, amzVersion: this.primaryIdImage.amzVersion };
      }
      this.callIdImageObservable(this.primaryIdImage, this.secondaryIdImage);
      // Only a new document has not been uploaded already
      if (!this.cachedIdInformation) {
        this.updatedFile = this.updatedFile.filter(val => val.imageNo !== idNo);
        this.documentImageUpdatedLocally[idNo] = false;
        this.documentImageUpdated = false;
        this.documentImageUploaded = false;
        this.personalNIdInformationForm.get('idImage').setErrors(null);
        this.personalNIdInformationForm.get('idImage').markAsPristine();
        this.personalNIdInformationForm.get('idImage').markAsUntouched();
        this.personalNIdInformationForm.get('idImage').markAsUntouched();
      }
    }
  }

  deletedIdImage() {
    this.secondaryIdImage = null;
    this.updatedFile = this.updatedFile.filter(val => val.imageNo !== ImageIds.ID2);
    this.personalNIdInformationForm.get('secondaryIdImage').setValue(null);
    this.personalNIdInformationForm.markAsDirty();
    this.resetTransformId2$.next('delete');
    if (this.primaryIdImage) {
      this.callIdImageObservable(this.primaryIdImage);
    }
    this.documentImageUpdated = true;
    this.documentImageUpdatedLocally[ImageIds.ID2] = false;
  }

  /**
   * Get all the ID types available for the test location selected
   */
  getTestLocIdTypes(testLocationId: string) {
    return new Promise<void>((resolve) => {
      if (testLocationId) {
        this.apiService.getTestLocationIdentificationTypes(testLocationId)
          .pipe(first(idTypes => idTypes && idTypes.length > 0), catchError(() => {
            resolve();
            return of([]);
          }))
          .subscribe((idTypes) => {
            this.setIdTypes(idTypes);
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  getProductIdTypes(productId: string) {
    return new Promise<void>((resolve) => {
      if (productId) {
        this.productService.getIdentificationTypesForProduct(this.getProductId, this.appCorrelationId)
          .pipe(first(idTypes => idTypes && idTypes.length > 0), catchError(() => {
            resolve();
            return of([]);
          }))
          .subscribe((idTypes) => {
            this.setIdTypes(idTypes);
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  setIdTypes(idTypes) {
    if (Array.isArray(idTypes) && idTypes.length) {
      this.IdentificationTypes = idTypes;
      const idType = idTypes.find(idtype => {
        if (idtype.id === this.userProfile?.identityDetails?.identificationTypeId) {
          this.selectedIDType = idtype;
        }
      });
    }
  }

  private prepareAddressForm() {
    const addressDetail: UserProfileAddressDetails = this.userProfile?.addressDetails;
    return this.fb.group({
      address1: [(addressDetail && addressDetail.streetAddress1) || '', [
        Validators.required,
        Validators.pattern(ADDRESS_REGEXP),
        addressDefaultValueValidator, emptyWhiteSpaceValidator()]
      ],
      address2: [(addressDetail && addressDetail.streetAddress2) || '', [emptyWhiteSpaceValidator(), Validators.pattern(ADDRESS_REGEXP)]],
      country: [(addressDetail && addressDetail.countryId) || '', [Validators.required, countryDefaultValueValidator]],
      province: [(addressDetail && addressDetail.territoryId) || ''],
      city: [(addressDetail && addressDetail.city) || '', emptyWhiteSpaceValidator()],
      postcode: (addressDetail && addressDetail.postCode) || '',
    }, { validators: [postValidator] });
  }

  getPersonalFormValue(value, defaultValue: string | boolean = false) {
    return value || defaultValue;
  }

  private preparePersonalNIdInformationForm() {
    const user = new User();
    const personalDetail: UserProfile = this.userProfile;
    const userIdentity = this.userProfile?.identityDetails;
    const nationalityId = PrepareSurveyFormService
      .getSavedDataWithOtherProperty('nationality', this.userProfile, this.nationalities);
    const mobileNumber = personalDetail?.mobileNumber;
    this.selectedCountryISO = getCountryCode(mobileNumber || '');
    return this.fb.group({
      firstName: [this.getPersonalFormValue(personalDetail?.firstName, '')],
      lastName: [this.getPersonalFormValue(personalDetail?.lastName, '')],
      email: [this.getPersonalFormValue(personalDetail?.emailAddress, ''),
      { validators: [user.getValidatorFields('email')] }
      ],
      mobileNumber: [this.getPersonalFormValue(personalDetail?.mobileNumber, ''),
      { validators: [user.getValidatorFields('mobileNumber')] }
      ],
      birthDate: [
        personalDetail?.dateOfBirth ?
          DateTime.fromFormat(personalDetail?.dateOfBirth || '', 'yyyy-MM-dd').toFormat('dd/MM/yyyy') : '',
        { validators: [user.getValidatorFields('birthDate')] }
      ],
      title: [this.getPersonalFormValue(personalDetail?.title, ''),
      [Validators.required]
      ],
      gender: [this.getPersonalFormValue(personalDetail?.genderId, ''),
      [Validators.required]
      ],
      identityType: [
        (this.selectedIDType?.id || USER_SIGNUP_CONSTANTS_V2.identificationTypeId),
        [Validators.required]
      ],
      identityNo: [
        this.getPersonalFormValue(userIdentity?.number, ''),
        { validators: [user.getValidatorFields('identityNo')] }
      ],
      countryOfNationality: [nationalityId, [Validators.required, emptyWhiteSpaceValidator(), nationalityDefaultValueValidator]],
      issuingAuthority: [
        this.getPersonalFormValue(userIdentity?.issuingAuthority, ''),
        [Validators.maxLength(50), emptyWhiteSpaceValidator()]
      ],
      idImage: (this.documentImageUpdated && this.primaryIdImage),
      secondaryIdImage: this.secondaryIdImage,
      expiryDate: [
        userIdentity?.expiryDate ?
          DateTime.fromFormat(userIdentity.expiryDate || '', 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
          : '',
      ],
      status: '',
      marketingCheckbox: this.getPersonalFormValue(personalDetail?.marketingDetails?.preparationContactPermission, false),
      communicationsCheckbox: this.getPersonalFormValue(personalDetail?.marketingDetails?.studyContactPermission, false),
    },
      { validators: [nameValidator, expiryDateValidator, titleGenderValidator(this.personalGenderList)] });
  }

  public prepareIeltsInformationForm() {
    const profile = this.userProfile;
    const userProfileDetail = this.userProfile?.marketingDetails;
    const getData = (
      fieldName: string,
      options?: any[],
      upd: any = userProfileDetail) => {
      if (options) {
        const res = PrepareSurveyFormService.getSavedDataWithOtherProperty(fieldName, upd, options);
        return res;
      }
      return this.getSavedData(fieldName, upd);
    };
    return this.fb.group({
      firstLanguage: [
        getData('language', this.languages, profile) || '',
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      educationLevel: [
        getData('educationLevel', this.educationLevelList, userProfileDetail) || '',
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      occupationSector: [
        getData('occupationSector', this.occupationSectorList, userProfileDetail) || '',
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      occupationLevel: [
        getData('occupationLevel', this.occupationLevelList, userProfileDetail) || '',
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      reasonTest: [
        getData('testReason', this.testReasonList, userProfileDetail) || '',
        [Validators.required]],
      yearsStudyingEnglish: [
        +getData('yearsOfStudy') ? +getData('yearsOfStudy') : '',
        Validators.required,
      ],
      locationStudyEnglish: [
        getData('currentlyStudyingEnglishAt') || '',
        [
          Validators.maxLength(150),
          emptyWhiteSpaceValidator(),
          noSpecialCharactersValidator
        ]
      ],
      intendLocation: [
        getData('countryApplyingTo', this.countryList, userProfileDetail) || '',
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
    }, { validators: [statusValidator] });
  }

  updateFormControlErrors(form: UntypedFormGroup, formControlName: string) {
    form.get(formControlName).setErrors({ reviewRequired: true });
    form.get(formControlName).markAsTouched();
  }

  updatePersonalNIdDetails() {
    if ((this.documentImageUpdatedLocally[this.imageNo] || this.documentImageUploaded) && (this.hasUpdatedFiles())) {
      this.saveFileChanges(this.updatedFile[0]);
    } else if (this.personalNIdInformationForm.valid) {
      if (this.isSecondaryImage && !this.secondaryIdImage) {
        this.resetCacheUpload$.next();
      }
      this.updateProfile('personalNIdDetail');
      this.personalNIdInformationForm.markAsPristine();
      this.documentImageUploaded = false;
      this.documentImageUpdated = false;
    }
  }

  updateAddressDetails() {
    if (this.addressDetailsForm.valid) {
      this.updateProfile('addressDetail');
      this.addressDetailsForm.markAsPristine();
    }
  }

  updateIeltsInfoDetails() {
    if (this.ieltsInfoForm.valid) {
      this.mapperService.surveyForm = this.ieltsInfoForm;
      this.updateProfile('survey');
      this.ieltsInfoForm.markAsPristine();
    }
  }

  updateUKVIInfoDetails() {
    if (this.UKVIInfoForm.valid) {
      // TODO: Make an API Call to update the UKVI information
      this.loadingService.increaseLoadingCounter();
      const ukviPayload = {
        isForStudySponsoredStatus: this.UKVIInfoForm.get('isUKVISponsorStatus').value,
        isForUkvi: this.UKVIInfoForm.get('isTestForUKVI').value,
        isReferredByPartner: this.UKVIInfoForm.get('isUKVIReferral').value,
        version: this.version,
        referralPartnerName: this.UKVIInfoForm.get('referralName').value,
        targetCefrLevel: this.UKVIInfoForm.get('targetCefrLevel').value.Id
      }
      this.cleanPayload(ukviPayload)
      this.applicationService.updateApplicationSeltDetails(this.appCorrelationId, this.applicationId, ukviPayload).pipe(first()).subscribe(data => {
        this.loadingService.decreaseLoadingCounter();
        this.showToast(true, 'ukvi');
        this.version = data.version;
      }, () => {
        this.loadingService.decreaseLoadingCounter();
        this.showToast(false, 'ukvi');
      });
    }
  }

  cleanPayload(object) {
    Object.keys(object).forEach(propName => {
      if (object[propName] === null || object[propName] === undefined || object[propName] === '') {
        delete object[propName];
      }
    })
    return object
  }

  surveyChange(args: [{
    key: string,
    selectedModel: any
  }, string]) {
    try {
      if (Array.isArray(args) && args[0] && args[0].selectedModel && Object.keys(args[0].selectedModel).length) {
        const { selectedModel } = args[0];
        if (selectedModel.id === 'other') {
          this.ieltsInfoForm.get(args[1]).setValue(selectedModel);
        }
      }
    } catch (error) { /* empty */ }
  }

  updateProfile(section: ISections) {
    if (this.isVerified) {
      const userProfileRequest: ApplicationUserProfilePatchRequest = this.mapperService.generateApplicationUserProfilePatchRequest(section);
      this.applicationService.applicationUserProfilePatch(this.applicationId, userProfileRequest, this.appCorrelationId).pipe(first()).subscribe(data => {
        this.mapperService.userProfileStore = data.userProfile;
        this.userProfile = data.userProfile;
        this.showToast(true, section);
        this.loadingService.resetLoadingCounter();
      }, err => {
        this.showToast(false, section);
        this.loadingService.resetLoadingCounter();
      });
    } else {
      const userProfileRequest: ApplicationUserProfileUpdateRequest = this.mapperService.generateApplicationUserProfileRequest(section);
      this.applicationService.applicationUserProfileUpdate(this.applicationId, userProfileRequest, this.appCorrelationId).pipe(first()).subscribe(data => {
        this.mapperService.userProfileStore = data.userProfile;
        this.userProfile = data.userProfile;
        this.showToast(true, section);
        this.loadingService.resetLoadingCounter();
      }, err => {
        this.showToast(false, section);
        this.loadingService.resetLoadingCounter();
      });
    }
  }

  showToast(success: boolean, section) {
    switch (section) {
      case 'addressDetail':
        return success ? this.toast.success('Address details updated successfully!')
          : this.toast.error('Error on updating address details!');
      case 'personalNIdDetail':
        return success ? this.toast.success('Personal details updated successfully!')
          : this.toast.error('Error on updating personal details!');
      case 'survey':
        return success ? this.toast.success('IELTS Information updated successfully!')
          : this.toast.error('Error on updating IELTS Information!');
      case 'ukvi':
        return success ? this.toast.success('IELTS UKVI Information updated successfully!')
          : this.toast.error('Error on updating IELTS UKVI Information!');
      default:
        return success ? this.toast.success('Profile updated successfully!')
          : this.toast.error('Something went wrong!');
    }
  }

  getSavedData(fieldName: string, container: object) {
    return (container && (
      container[fieldName] ||
      ''
    )) || '';
  }

  getNationalities() {
    this.apiService.GetNationality.subscribe(values => {
      if (Array.isArray(values)) {
        this.nationalities = [...values];
      }
    });
  }

  getGender() {
    this.apiService.getGender.subscribe(values => {
      if (Array.isArray(values)) {
        this.personalGenderList = [...values
          .filter(i => i?.name.toLowerCase() !== 'other')
        ];
      }
    });
  }

  getYearsStudyingEnglishList() {
    this.studyEnglishYearsList = this.moveOtherToLast(this.options.studyEnglishYears);
  }

  isThisProductIdUKVI(productId): Observable<boolean> {
    if (productId) {
      return this.productService.getProduct(productId, this.appCorrelationId).pipe(
        map(data => data.isSELT), first(),
        catchError(e => of(false))
      );
    }
    return of(false);
  }
  private setIssuingAuthorityValidator(isUKVI, form: UntypedFormGroup) {
    if (isUKVI) {
      const issuingAuthority = form.get('issuingAuthority');
      this.setValidators(issuingAuthority, Validators.required, !issuingAuthority.value ? { required: true } : {}, form);
    }
  }
  private setValidators(ctrl: AbstractControl, validator: ValidatorFn, error, form: UntypedFormGroup) {
    ctrl.setValidators(validator);
    ctrl.setErrors(error);
    form.updateValueAndValidity();
  }
  onDocumentPhotoUpdate(event?: DocumentPhotoUpdateEvent, idNo?: string): void {
    this.documentImageUpdatedLocally[idNo] = true;
    this.id1Updated = idNo === 'id1';
    if (this.id1Updated) {
      this.documentImageUpdated = idNo === 'id1';
    }
    if (event === undefined && this.isImgAvailable) {
      this.documentImageUpdated = true;
    }
    this.imageNo = idNo;
    const exists = this.updatedFile.findIndex(item => item.imageNo === this.imageNo);
    if (exists === -1) {
      this.updatedFile.push({ imageNo: this.imageNo, file: event?.file, url: event?.url });
    }
    else {
      this.updatedFile[exists] = { imageNo: this.imageNo, file: event?.file, url: event?.url }
    }
    if (!this.cachedIdInformation) {
      this.cachedIdInformation = this.personalNIdInformationForm.value;
    }
    if (event?.newDocUploaded && !this.idImageWasDefault && !this.isId2) {
      this.setIdentityDetailsToNull();
    }
    this.personalNIdInformationForm.markAsTouched();
    this.zone.run(() => {
      this.cdr.markForCheck();
    })
    this.cdr.detectChanges();
  }

  private clearCachedIdInformation(): void {
    this.cachedIdInformation = null;
  }

  private setIdentityDetailsToNull(): void {
    this.personalNIdInformationForm.get('identityType').setValue(null);
    this.personalNIdInformationForm.get('identityNo').setValue(null);
    this.personalNIdInformationForm.get('countryOfNationality').setValue(null);
    this.personalNIdInformationForm.get('expiryDate').setValue(null);
    this.personalNIdInformationForm.get('issuingAuthority').setValue(null);
    this.personalNIdInformationForm.updateValueAndValidity();
  }

  onCancelDocumentUpdate(): void {
    this.cancelChangesConfirmation.open().result.then((result: 'accept' | 'reject') => {
      if (result === 'reject') {
        return;
      }
      this.resetIdentityDetailsToCachedVersion();
      // To let the children know it is time to cancel any applied changes
      // For now only `identity-document-upload-form` is care about it
      this.cancelChangesid1$.next();
      this.cancelChangesid2$.next();
      this.documentImageUpdated = false;
      this.documentImageUploaded = false;
      this.documentImageUpdatedLocally = { id1: false, id2: false };
      this.personalNIdInformationForm.markAsPristine();
      this.personalNIdInformationForm.markAsUntouched();
    });
  }

  resetTransform(): void {
    this.resetTransformId1$.next('');
    this.resetTransformId2$.next('');
  }

  private resetIdentityDetailsToCachedVersion(): void {
    if (this.cachedIdInformation) {
      this.personalNIdInformationForm.get('identityType').setValue(this.cachedIdInformation.identityType);
      this.personalNIdInformationForm.get('identityNo').setValue(this.cachedIdInformation.identityNo);
      this.personalNIdInformationForm.get('countryOfNationality').setValue(this.cachedIdInformation.countryOfNationality);
      this.personalNIdInformationForm.get('expiryDate').setValue(this.cachedIdInformation.expiryDate);
      this.personalNIdInformationForm.get('issuingAuthority').setValue(this.cachedIdInformation.issuingAuthority);
      this.personalNIdInformationForm.updateValueAndValidity();
    }
  }

  receivedData(event) {
    this.expiryDateCheck = !!event;
  }
  get formInvalid(): boolean {
    return (this.personalNIdInformationForm.invalid
      || (this.personalNIdInformationForm.pristine && !this.documentImageUpdated && !this.documentImageUpdatedLocally[ImageIds.ID2])
      || (!this.documentImageUpdated && this.primaryIdImage === null));
  }

}
