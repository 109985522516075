import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BracPaymentService, PaymentStatus } from '@idp-education/ors-brac-payment-bff-client-v1';
import { Action, BracPaymentService as BracPaymentServiceV2, PaymentStatus as PaymentStatusV2 } from '@idp-education/ors-brac-payment-bff-client-v2';
import { Auth } from 'aws-amplify';
import { PaymentStates } from 'pages/payment/payment.enum';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import { restoreLocalData } from '../../payment-utils/payment-utils';
import * as uuid from 'uuid';
import { Store } from '@ngrx/store';
import { SplunkService } from 'shared/services/splunk.service';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-brac-callback',
  templateUrl: './brac-callback.component.html',
  styleUrls: ['./brac-callback.component.scss']
})
export class BracCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  paymentNewModalPopup = false;
  title: string;
  acceptText: string;
  rejectText: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  routerSub: Subscription;
  activatedRouteSub: Subscription;
  apiCallingSub: Subscription;
  isNewPaymentJourney: string;
  userName: any;
  imageUrl: string;
  desc: string;
  isBracCyberSource: boolean;
  sessionKey: string;

  constructor(
    private store: Store<{ userStore }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private bracPaymentService: BracPaymentService,
    private bracPaymentServiceV2: BracPaymentServiceV2,
    private splunkService: SplunkService,
    private commomService: CommonService
  ) {
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    this.apiCallingSub = this.store.select(appStore => appStore.userStore?.userDetails).subscribe((userDetails) => {
      this.userName = userDetails?.firstName;
    });
  }

  ngOnInit(): void {
    this.sessionKey = uuid.v4();
    const logger = { name: 'In Brac Payments Callback' };
    this.splunkPayload(logger);
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreData();
    }).catch(error => {
      this.splunkPayload(error);
    });
  }

  splunkPayload(error) {
    const payload = this.splunkService.generatePayload({
      session_key: this.sessionKey,
      source_site: 'test_taker_ui',
      event_type: 'UI Error Brac',
      error_error: error?.error,
      error_name: error?.name,
      error_message: error?.message,
      error_stack: error?.stack,
    });
    this.splunkService.logger(payload, error);
  }

  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp?.id;
    this.isBracCyberSource = JSON.parse(localStorage.getItem('isBracCyberSource'))
    if (!this.isBracCyberSource) {
      this.bracStatus();
    } else {
      this.getBracStatusV2();
    }
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
    this.paymentNewModalPopup = false;
  }


  bracStatus() {
    this.bracPaymentService.configuration.accessToken = this.userToken;
    this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      const requestBody = {
        receiptNumber: queryParams.get('orderId'),
        txData: queryParams.get('txData')
      };

      this.apiCallingSub.add(this.bracPaymentService.getPaymentStatus(this.commomService.appCorrelationId(), requestBody).subscribe(response => {
        this.showModal(response.status);
      },
        error => {
          const message = (error.status === 401) ? 'Unauthorised' : '';
          this.showModal(message);
        }))
    });

  }

  getBracStatusV2() {
    this.bracPaymentServiceV2.configuration.accessToken = this.userToken;
    let requestBody;
    let transData;
    let action;
    this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      transData = queryParams?.get('txData');
      this.activatedRoute.paramMap.subscribe(paramMap => {
        action = paramMap.get('status');
      })
      requestBody = {
        action: action.toUpperCase(),
        txData: transData
      };
      this.apiCallingSub.add(this.bracPaymentServiceV2.getPaymentStatus(this.commomService.appCorrelationId(), requestBody).subscribe(response => {
        this.showModal(response.status);
      },
        error => {
          const message = (error.status === 401) ? 'Unauthorised' : '';
          this.showModal(message);
        }))
    });
  }

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
    this.activatedRouteSub?.unsubscribe();
    this.apiCallingSub?.unsubscribe();
  }

  showModal(message) {
    if (this.isNewPaymentJourney === 'enabled') {
      this.newmodalpopupDescription(message);
    } else {
      this.modalpopupDescription(message);
    }
  }

  newmodalpopupDescription(status: string) {
    switch (status) {
      case PaymentStatusV2.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatusV2.CANCELED:
        this.title = 'Payment Cancelled';
        this.desc = `Hi ${this.userName}, your payment has been cancelled. You will be redirected to your profile.`;
        this.imageUrl = './assets/images/payment-info.svg';
        this.acceptText = 'Ok';
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = 'Unauthorised Payment';
        this.desc = `Hi ${this.userName}, payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.acceptText = 'Unauthorised Payment';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatusV2.FAILED:
        this.title = 'Payment Failed';
        this.desc = `Hi ${this.userName}, your payment has failed, possibly due to incorrect payment information. Please retry your payment.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = 'Payment Error';
        this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  modalpopupDescription(status) {
    switch (status) {
      case PaymentStatusV2.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatusV2.FAILED:
        this.title = `Your payment failed. Please retry the payment.`;
        this.acceptText = 'Retry Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatusV2.CANCELED:
        this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
        this.acceptText = 'Payment Cancelled';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `The payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.acceptText = 'Unauthorised Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.acceptText = 'Return to My Account';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }
}
