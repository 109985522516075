import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
/* Feature Imports */
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { CoreRoutingModule } from './core/core-routing.module';
import { NgbDateFRParserFormatter } from './shared/directives/ngb-date-fr-parser-formatter';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Effects, Store as StoreModule, StoreDevTool as StoreDevtoolsModule } from './store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafePipe } from './pages/safe.pipe';
import { SearchFilterPipe } from './pages/search-filter.pipe';
import { FeatureToggleModule } from 'ngx-feature-toggle';
/* Amplify */
import { Amplify } from '@aws-amplify/core';
import awsConfig from '../aws-amplify/aws-exports';
import { ApiModule, BASE_PATH, Configuration } from '@idp-education/ors-test-taker-bff-client-v1';
import { BASE_PATH as BASE_PATH_V2, ApiModule as ApiModuleV2,
  Configuration as ConfigurationV2 } from '@idp-education/ors-test-taker-bff-client-v2';
import { BASE_PATH as BASE_PATH_PAYSTACK } from '@idp-education/ors-paystack-payment-bff-client-v1';
import { BASE_PATH as VERITRANS_BASE_PATH } from '@idp-education/ors-veritrans-payment-bff-client-v1';
import { BASE_PATH as BASE_PATH_BANK_ALFALAH } from '@idp-education/ors-bankalfalah-payment-bff-client-v1';
import { BASE_PATH as BASE_PATH_BRAC } from '@idp-education/ors-brac-payment-bff-client-v1';
import { BASE_PATH as BASE_PATH_BRAC_V2 } from '@idp-education/ors-brac-payment-bff-client-v2';
import { BASE_PATH as BASE_PATH_ESEWA } from '@idp-education/ors-esewa-payment-bff-client-v1';
import { BASE_PATH as BASE_PATH_PAYDOLLAR } from '@idp-education/ors-paydollar-payment-bff-client-v1';
import { BASE_PATH as BASE_PATH_NEWEBPAY } from '@idp-education/ors-newebpay-payment-bff-client-v1';
import { BASE_PATH as BASE_PATH_INIPAY } from '@idp-education/ors-inipay-payment-bff-client-v1';
import { BASE_PATH as BASE_PATH_HSBC_OMNI } from '@idp-education/ors-hsbcomni-payment-bff-client-v1';
import { BASE_PATH as BASE_PATH_OFFLINE } from '@idp-education/ors-offline-payment-bff-client-v1';
import { AuthGuardService, LoginAuthGuardService, SocialLoginAuthGuardService } from './auth-guard.service';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { IConfig } from 'ngx-mask';
import { LoadingInterceptor } from 'src/app/shared/interceptor/loading.interceptor';
import { AccountModule } from './pages/account/account.module';
import { PaymentModule } from './pages/payment/payment.module';
import { MyAccountModule } from './pages/my-account/my-account.module';
import { OnBoardingModule } from './pages/onboarding/onboarding.module';
import { HomeComponent } from './pages/home/home.component';
import { MyTestsModule } from './pages/my-tests/my-tests.module';
import { MyProfileModule } from './pages/my-profile/my-profile.module';
import { authToken } from '../app/shared/services/auth.service';
import { TermAndConditionsComponent } from './pages/termAndConditions/term-and-conditions.component';
const appSettings = require('../assets/appSettings.json');
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
Amplify.configure({ ...awsConfig });
Amplify.Logger.LOG_LEVEL = 'ERROR';
import { HttpErrorInterceptor } from './core/error-handlers/http-error-interceptor.service';
import { SplunkUIErrorHandler } from './core/error-handlers/splunk-ui-error-handler.service';
import { HaveCognitoProfile } from './pages/account/create-booking-account-can-activate.service';
import { TermsAndConditionsIncentreComponent } from './pages/terms-and-conditions-incentre/terms-and-conditions-incentre.component';
import { TermAndConditionHongKongComponent } from './pages/term-and-condition-hong-kong/term-and-condition-hong-kong.component';
import { OfflinePaymentInstructionComponent } from './pages/offline-payment-instruction/offline-payment-instruction.component';
import { TermsAndConditionUkviComponent } from './pages/terms-and-condition-ukvi/terms-and-condition-ukvi.component';
import { TermsAndConditionUkviOsrComponent } from 'pages/terms-and-condition-ukvi-osr/terms-and-condition-ukvi-osr.component';
import { XhrDetailsService } from './xhr-details.service';
import { UUID_TOKEN, generateUuidV4 } from 'shared/utils/string-utils';
import { TermsAndConditionsUkviHongkongComponent } from './pages/terms-and-conditions-ukvi-hongkong/terms-and-conditions-ukvi-hongkong.component';
import { MockSettingsModule } from 'pages/mock-settings/mock-settings.module';
import { CookieModule, CookieService } from 'ngx-cookie';
const importedModules = [
  BrowserModule,
  SharedModule,
  HttpClientModule,
  AppRoutingModule,
  CoreModule,
  CoreRoutingModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  BrowserAnimationsModule,
  StoreModule,
  StoreDevtoolsModule,
  ApiModule.forRoot(() => {
    const conf = new Configuration({});
    authToken.subscribe(token => conf.accessToken = token);
    return conf;
  }),
  ApiModuleV2.forRoot(() => {
    const config = new ConfigurationV2({});
    authToken.subscribe(token => config.accessToken = token);
    return config;
  }),
  NgxTrimDirectiveModule,
  FeatureToggleModule,
  AccountModule,
  PaymentModule,
  OnBoardingModule,
  MyAccountModule,
  MyTestsModule,
  MyProfileModule,
  MockSettingsModule,
  CookieModule.withOptions(),
  Effects
];

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    SearchFilterPipe,
    HomeComponent,
    TermAndConditionsComponent,
    TermsAndConditionsIncentreComponent,
    TermAndConditionHongKongComponent,
    OfflinePaymentInstructionComponent,
    TermsAndConditionUkviComponent,
    TermsAndConditionUkviOsrComponent,
    TermsAndConditionsUkviHongkongComponent
  ],
  imports: importedModules,
  providers: [
    DatePipe,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    // Huh:
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    AuthGuardService,
    LoginAuthGuardService,
    SocialLoginAuthGuardService,
    { provide: BASE_PATH, useValue: appSettings.site.baseURL },
    { provide: BASE_PATH_V2, useValue: appSettings.site.baseURL },
    { provide: BASE_PATH_OFFLINE, useValue: appSettings.site.baseURLOfflinePayment },
    { provide: BASE_PATH_PAYSTACK, useValue: appSettings.site.paystackURL },
    { provide: VERITRANS_BASE_PATH, useValue: appSettings.site.baseURLVeritrans },
    { provide: BASE_PATH_BANK_ALFALAH, useValue: appSettings.site.baseURLBankAlfalah },
    { provide: BASE_PATH_BRAC, useValue: appSettings.site.baseURLBrac },
    { provide: BASE_PATH_BRAC_V2, useValue: appSettings.site.baseURLBrac },
    { provide: BASE_PATH_ESEWA, useValue: appSettings.site.baseURLeSewa },
    { provide: BASE_PATH_PAYDOLLAR, useValue: appSettings.site.baseURLPaydollar },
    { provide: BASE_PATH_NEWEBPAY, useValue: appSettings.site.baseURLNewebpay },
    { provide: BASE_PATH_INIPAY, useValue: appSettings.site.baseURLInipay },
    { provide: BASE_PATH_HSBC_OMNI, useValue: appSettings.site.baseURLHsbcOmni },
    { provide: 'googleTagManagerId', useValue: appSettings.site.googleTagManagerId },
    { provide: UUID_TOKEN, useFactory: generateUuidV4 },
    { provide: ErrorHandler, useClass: SplunkUIErrorHandler },
    HaveCognitoProfile,
    XhrDetailsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
