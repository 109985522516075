import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Application, CreateReceiptRequest, CreateReceiptResponse, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { Subscription } from 'rxjs';
import { UserState } from 'store/user/user.reducer';
import { ICon } from '../payment-container/payment-container.component';
import { NavigationService } from 'shared/services/navigation.service';
import { Store } from '@ngrx/store';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { PaydollarPaymentService } from '@idp-education/ors-paydollar-payment-bff-client-v1';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from 'shared/services/loading-service.service';
import { SharedStateService } from 'pages/account/shared-state.service';
import { PaymentsService } from 'shared/services/payment.services';
import { CommonService } from 'shared/services/common.service';
const appSettings = require('src/assets/appSettings.json');
@Component({
  selector: 'app-paydollar',
  templateUrl: './paydollar.component.html',
  styleUrls: ['./paydollar.component.scss']
})
export class PaydollarComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() tcPaymentMethod = [];
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @Input() isNewJourney = false;
  postReceipt: Subscription;
  sub: Subscription;
  userState: UserState;
  receiptResponse: CreateReceiptResponse;
  formData: FormGroup;
  paymentUrl: string;
  receiptExpiredValue;

  static get tab(): ICon {
    return {
      alt: 'paydollar',
      key: 'PAYDOLLAR',
      url: '/assets/images/payment/paydollar.svg',
      class: '',
      title: 'Pay online with Visa and Mastercard',
      description: 'Secure payments powered by PayDollar',
    };
  }
  constructor(
    private receiptService: ReceiptService,
    private navigationService: NavigationService,
    private formBuilder: FormBuilder,
    private paydollarPaymentService: PaydollarPaymentService,
    private loadingService: LoadingService,
    private sharedState: SharedStateService,
    private paymentService: PaymentsService,
    private commonService: CommonService,
    private store: Store<{ userStore }>
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.userState = x;
    });
  }

  ngOnInit(): void {
    this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.PAYDOLLAR) {
        this.fetchFormData();
      }
    }));
    this.formData = this.formBuilder.group({
      orderRef: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      currCode: new FormControl('', [Validators.required]),
      merchantId: new FormControl('', [Validators.required]),
      remark: new FormControl('', [Validators.required]),
      successUrl: new FormControl('', [Validators.required]),
      cancelUrl: new FormControl('', [Validators.required]),
      failUrl: new FormControl('', [Validators.required]),
      mpsMode: new FormControl('', [Validators.required]),
      payType: new FormControl('', [Validators.required]),
      lang: new FormControl('', [Validators.required]),
      payMethod: new FormControl('', [Validators.required]),
      redirect: new FormControl('', [Validators.required]),
      secureHash: new FormControl('', [Validators.required])
    });
    this.createReceipt();
  }

  ngOnDestroy(): void {
    this.postReceipt?.unsubscribe();
    this.sub.unsubscribe();
  }

  createReceipt() {
    const tcPaymentMethodId = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.PAYDOLLAR);
    const createReceiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: tcPaymentMethodId?.id
    };

    this.postReceipt = this.receiptService.createReceipt(this.commonService.appCorrelationId(), createReceiptRequest)?.subscribe(result => {
      this.receiptResponse = result;
      this.storeBookingInfo();
    }, (error) => {
      if(error?.includes('APPLICATION_EXPIRED')){
        this.receiptExpiredValue = this.paymentService.getErrorState()
      }
    });
  }

  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.PAYDOLLAR);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
    if(!this.isNewJourney){
      this.fetchFormData();
    }
    
  }

  fetchFormData() {
    this.paydollarPaymentService.configuration.accessToken = this.userState?.userToken;
    const appId = this.applicationId;
    const receiptId = this.receiptResponse.id;
    const postPayDollar = this.paydollarPaymentService.getPaymentFormData(appId, receiptId, this.commonService.appCorrelationId()).subscribe(result => {
      this.loadingService.increaseLoadingCounter();
      this.formData.setValue({
        orderRef: result.orderRef,
        amount: result.amount,
        currCode: result.currCode,
        merchantId: result.merchantId,
        remark: result.remark,
        successUrl: result.successUrl,
        cancelUrl: result.cancelUrl,
        failUrl: result.failUrl,
        mpsMode: result.mpsMode,
        payType: result.payType,
        lang: result.lang,
        payMethod: result.payMethod,
        redirect: result.redirect,
        secureHash: result.secureHash
      });
      this.paymentUrl = result.paymentUrl;
      const formElement: HTMLFormElement = document.getElementById('payDollarForm') as HTMLFormElement;
      this.loadingService.decreaseLoadingCounter();
      if (formElement && this.formData.value) {
        formElement.action = this.paymentUrl;
        formElement.submit();
      }
    }, error => {
      this.loadingService.decreaseLoadingCounter();
    });
    this.sub.add(postPayDollar);
  }

  backToListingPage(): void {
    window.open(`${appSettings.site.bxsearchURL}/book-ielts`, '_self');
  }

}
