export enum IOCLocalStorageItemKeys {
    speakingtesttime = 'speakingtesttime',
    lrwtesttime = 'lrwtesttime',
    isNotIOLProduct = 'isNotIOLProduct',
    testLocalTimezone = 'testLocalTimezone',
    applicationsStore = 'applicationsStore',
    productFee = 'productFee',
    testLocalTimeZoneVal = 'testLocalTimeZone',
    lrwDate = 'lrwDate',
    speakingDate = 'speakingDate'
}
export enum IOCSessionStorageItemKeys {
    
    isNotIOLProduct = 'isNotIOLProduct',
    testLocalTimezone = 'testLocalTimezone',
    productId = 'productId',
    testLocationId = 'testLocationId',
    testLocalTimeZoneVal = 'testLocalTimeZone',
    sessions = 'sessions',
    setLrwDateTime = 'setLrwDateTime',
    setSpeakingDateTime = 'setSpeakingDateTime',
    setLocalTimeZone = 'setLocalTimeZone',
    lrwTestTime = 'lrwTestTime',
    speakingTestTime = 'speakingTestTime',
    lrwUrl = 'lrwUrl',
    speakingUrl = 'speakingUrl'
}



export enum OSRLocalStorageItemKeys {
    ssrComponent = 'ssrComponent',
    ssrFee = 'ssrFee',
    selectedTest = 'selectedTest',
    offlinePayment = 'offlinePayment',
    ssrApplication = 'ssrApplication'
}

export enum EORLocalStorageItemKeys {
    eorApplication = 'eorApplication',
}

export enum PaymentTypes {
    normal = 'Normal',
    osr = 'OSR',
    ssr = 'SSR',
    EOR = 'EOR'
}

export enum PaymentGateways {
    STRIPE = 'STRIPE',
    VERITRANS = 'VERITRANS',
    PAYSTACK = 'PAYSTACK',
    TIPS = 'TIPS',
    BANKALFALAH = 'BANKALFALAH',
    BRAC = 'BRAC',
    ESEWA = 'ESEWA',
    PAYDOLLAR = 'PAYDOLLAR',
    NEWEBPAY = 'NEWEBPAY',
    INIPAY = 'INIPAY',
    PAYMENTOS = 'PAYMENTOS',
    HSBCOMNI = 'HSBCOMNI',
    CREDIT_CARD = 'CREDIT_CARD',
    BRAINTREE = 'BRAINTREE',
    PAYPAL = 'PAYPAL'
}

export enum PaymentStates {
    INITIATED = 'INITIATED',
    COMPLETED = 'COMPLETED'
}
export enum GALocalStorageItemKeys {
    testModule = 'testModule',
    testFormat = 'testFormat',
    testCategory = 'testCategory',
    testLocation = 'testLocation',
    eorObject = 'eorObject',
    testLocalTimeZone = 'testLocalTimeZone'
}

export enum FormValues {
    OTHER_LANGUAGE = '99fcace5-4689-004d-d34f-81799f15b154',
    OTHER_NATIONALITY ='c4542852-a667-a0ff-251e-8b67c2ad34fa',
    UNKNOWN = 'Unknown'
}

export enum DetailFormStatus {
    INCOMPLETE = 'Incomplete',
    COMPLETED = 'Completed'
}

export enum FormTitle {
    DEFAULT_TITLE = 'Complete payment'
}

export enum MethodType {
    SUBMIT = 'submit',
    CANCEL = 'cancel',
    BACK = 'back'
}
