import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Application, CreateReceiptRequest, CreateReceiptResponse, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { UserState } from 'store/user/user.reducer';
import { ICon } from '../payment-container/payment-container.component';
import { NavigationService } from 'shared/services/navigation.service';
import { Store } from '@ngrx/store';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EsewaPaymentService } from '@idp-education/ors-esewa-payment-bff-client-v1';
import { SharedStateService } from 'pages/account/shared-state.service';
import { PaymentsService } from 'shared/services/payment.services';
import { CommonService } from 'shared/services/common.service';
const appSettings = require('src/assets/appSettings.json');
@Component({
  selector: 'app-e-sewa',
  templateUrl: './e-sewa.component.html',
  styleUrls: ['./e-sewa.component.scss']
})
export class ESewaComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() tcPaymentMethod = [];
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  @ViewChild('eSewaForm') eSewaForm: ElementRef;
  @Input() isNewJourney = false;

  sub: Subscription;
  userState: UserState;
  receiptResponse: CreateReceiptResponse;

  title: string;
  acceptText: string;

  formData: UntypedFormGroup;
  paymentUrl: string;
  receiptExpiredValue;

  static get tab(): ICon {
    return {
      alt: 'eSewa',
      key: 'ESEWA',
      url: '/assets/images/payment/eSewa.svg',
      class: '',
      title: 'Pay online with Wallets',
      description: 'Secure payments powered by eSewa',
    };
  }

  constructor(
    private receiptService: ReceiptService,
    private navigationService: NavigationService,
    private formBuilder: UntypedFormBuilder,
    private esewaPaymentService: EsewaPaymentService,
    private sharedState: SharedStateService,
    private paymentService: PaymentsService,
    private commonService: CommonService,
    private store: Store<{ userStore }>
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.userState = x;
    });
  }

  ngOnInit(): void {
    this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.ESEWA) {
        this.fetchFormData();
      }
    }));
    this.formData = this.formBuilder.group({
      amount: new UntypedFormControl('', [Validators.required]),
      taxAmount: new UntypedFormControl('', [Validators.required]),
      totalAmount: new UntypedFormControl('', [Validators.required]),
      transactionUuid: new UntypedFormControl('', [Validators.required]),
      productCode: new UntypedFormControl('', [Validators.required]),
      productServiceCharge: new UntypedFormControl('', [Validators.required]),
      productDeliveryCharge: new UntypedFormControl('', [Validators.required]),
      successUrl: new UntypedFormControl('', [Validators.required]),
      failureUrl: new UntypedFormControl('', [Validators.required]),
      signedFieldNames: new UntypedFormControl('', [Validators.required]),
      signature: new UntypedFormControl('', [Validators.required]),
    });
   
    this.createReceipt();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  createReceipt() {
    const tcPaymentMethodId = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.ESEWA);
    const createReceiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: tcPaymentMethodId?.id
    };

    const postReceipt = this.receiptService.createReceipt(this.commonService.appCorrelationId(), createReceiptRequest)?.subscribe(result => {
      this.receiptResponse = result;
      this.storeBookingInfo();
    }, (error) => {
      if(error?.includes('APPLICATION_EXPIRED')){
        this.receiptExpiredValue = this.paymentService.getErrorState()
      }
    });
    this.sub.add(postReceipt);
  }

  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.ESEWA);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
    if(!this.isNewJourney)
    {
      this.fetchFormData();
    }
    
  }

  fetchFormData() {
    this.esewaPaymentService.configuration.accessToken = this.userState?.userToken;
    const appId = this.applicationId;
    const receiptId = this.receiptResponse.id;
    const postEsewa = this.esewaPaymentService.getPaymentFormData(appId, receiptId, this.commonService.appCorrelationId()).subscribe(result => {
      this.formData.setValue({
        amount: result.amount,
        taxAmount: result.taxAmount,
        totalAmount: result.totalAmount,
        transactionUuid: result.transactionUuid,
        productCode: result.productCode,
        productServiceCharge: result.productServiceCharge,
        productDeliveryCharge: result.productDeliveryCharge,
        successUrl: result.successUrl,
        failureUrl: result.failureUrl,
        signedFieldNames: result.signedFieldNames,
        signature: result.signature
      });
      this.paymentUrl = result.paymentUrl;
      const formElement: HTMLFormElement = document.getElementById('eSewaForm') as HTMLFormElement;
      if (formElement && this.formData.value) {
        formElement.action = this.paymentUrl;
        formElement.submit();
      }
    });
    this.sub.add(postEsewa);
  }

  backToListingPage(): void {
    window.open(`${appSettings.site.bxsearchURL}/book-ielts`, '_self');
  }
}
