import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  PLATFORM_ID,
  Injectable, Output, EventEmitter, Input
} from '@angular/core';
import { Store } from '@ngrx/store';
import { setBackAction } from 'src/app/store/global.actions';
import { CacheService } from 'src/app/shared/services/cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDatepicker, NgbDate, NgbCalendar, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ViewportService } from 'src/app/shared/services/viewport.service';
import { IState, SharedStateService } from '../../shared-state.service';
import { concatMap, first } from 'rxjs/operators';
import { formatDateRange } from '../format-date-range';
import { ApplicationService, DashboardItem, ProductService } from '@idp-education/ors-test-taker-bff-client-v1';
import { dateToString } from '../util';
import { setDateRange, setTestType } from '../../../../store/my-tests/my-tests.actions';
import { DateTime, Info } from 'luxon';
import { CommonService } from 'shared/services/common.service';

@Injectable()
export class I18n {
  language = 'en';
}

const I18N_VALUES: any = {
  en: {
    weekdays: Info.weekdays('short', { locale: 'en' }).map(d => d.substr(0, 1)),
    months: Info.months('long', { locale: 'en' }),
    weekLabel: 'week'
  }
};


@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekLabel(): string {
    return I18N_VALUES[this._i18n.language].weekLabel;
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayShortName(weekday: number): string {
    return '';
  }
}


@Component({
  selector: 'app-ssr-test-date',
  templateUrl: './ssr-test-date.component.html',
  styleUrls: ['./ssr-test-date.component.scss'],
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class SsrTestDateComponent implements OnInit {
  state!: IState;
  currentStepNum = 0;
  totalStep = 1;
  iconSize = 1.167;
  @ViewChild('d')
  datePicker!: NgbDatepicker;
  routeState = '';
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  selectedDate: any;
  minDate: any;
  maxDate: any;
  isDisabled: any;
  showPara: null | boolean = null;
  navigation = 'arrows';
  showError = false;
  firstLoad = true;
  debug = 'test';
  renderCalendar = false;
  @Input() ssrSearchApplication: DashboardItem;
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter();
  @Output() onssrTestResultsClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private sharedState: SharedStateService,
    private store: Store<{ bookingStore: any }>,
    private cacheService: CacheService,
    @Inject(PLATFORM_ID) private platformId: any,
    public router: Router,
    public route: ActivatedRoute,
    private calendar: NgbCalendar,
    public i18n: NgbDatepickerI18n,
    public viewportService: ViewportService,
    private applicationService: ApplicationService,
    private productService: ProductService,
    private commonService: CommonService
  ) {
    this.setState();
    this.isDisabled = (date: NgbDate) => date.before(calendar.getNext(calendar.getToday(), 'd', 1));
    this.minDate = calendar.getNext(calendar.getToday(), 'd', 1);
  }

  ngOnInit(): void {
    this.sharedState.getState().pipe(first()).subscribe(state => {
      this.currentStepNum = state.currentStep || 0;
      this.totalStep = state.totalSteps || 1;
      this.store.dispatch(setBackAction({ BackButtonEnable: true, BackButtonRoute: 'wizard/test-location' }));
    });
    if (this.ssrSearchApplication) {
      const testLocalStartTime = DateTime.fromISO(
        this.ssrSearchApplication.lrwStartDateTimeUtc,
        { zone: this.ssrSearchApplication.testLocationTimeZone });
        this.maxDate = this.calendar.getNext(
          new NgbDate(testLocalStartTime.year, testLocalStartTime.month, testLocalStartTime.day),
          'd',
          60
        );
        this.renderCalendar = true;
    }
  }

  setState() {
    this.route.data.pipe(first()).subscribe(data => {
      this.routeState = data['state'];
    });
  }

  onClearClick() {
    this.fromDate = null;
    this.toDate = null;
    this.selectedDate = '';
    this.showError = false;
  }

  onClear() {
    this.showPara = false;
  }

  onDateSelection(date: NgbDate) {
    try {
      this.showError = false;
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate) {
        if ((date.after(this.fromDate) || date.equals(this.fromDate))) {
          this.toDate = date;
        } else {
          this.toDate = this.fromDate;
          this.fromDate = date;
        }
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
      this.selectedDate = formatDateRange(
        dateToString(this.fromDate),
        this.toDate ? dateToString(this.toDate) : undefined);
    } catch (e: any) {
      this.debug = this.fromDate?.toString() || 'failed';
    }

  }

  onNavigate() {
    if (this.showPara === null && this.firstLoad === true) {
      this.showPara = true;
      this.firstLoad = false;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return this.isFirst(date) || this.isLast(date) || this.isInside(date) || this.isHovered(date);
  }

  isFirst(date: NgbDate) {
    return date.equals(this.fromDate);
  }

  isBeforeFrom(date: NgbDate) {
    return date.before(this.fromDate);
  }

  isSelectingSecond() {
    return this.fromDate !== null && this.toDate === null;
  }

  isLast(date: NgbDate) {
    return (this.toDate && date.equals(this.toDate));
  }

  isSunday(date: NgbDate) {
    return new Date(dateToString(date)).getDay() === 0;
  }

  findSession($event: MouseEvent) {
    if (this.fromDate) {
      if (!this.toDate) {
        this.toDate = this.fromDate;
      }

      this.store.dispatch(setDateRange({ from: dateToString(this.fromDate), to: dateToString(this.toDate) }));
      this.applicationService.getApplication(this.ssrSearchApplication.applicationId, this.commonService?.appCorrelationId()).pipe(concatMap(application => {
        return this.productService.getProduct(application.bookings[0].bookableProductId, this.commonService?.appCorrelationId());
      }), concatMap(product => {
        this.store.dispatch(setTestType({ testType: product.testCategory + ',' + product.testModule }));
        return this.sharedState.getState();
      }), first()).subscribe(state => {
        this.onssrTestResultsClick.emit();
      });
    } else {
      this.showError = true;
    }
  }

}
