<div class="card" *ngIf="!isNewJourney">
  <div class="card-body">
  </div>
</div>
<div class="form-display">
  <form [formGroup]="formData" action="{{paymentUrl}}" method="post" name="eSewaForm" id="eSewaForm" #eSewaForm>
    <input type="text" id="amount" name="amount" formControlName="amount">
    <input type="text" id="tax_amount" name="tax_amount" formControlName="taxAmount">
    <input type="text" id="total_amount" name="total_amount" formControlName="totalAmount">
    <input type="text" id="transaction_uuid" name="transaction_uuid" formControlName="transactionUuid">
    <input type="text" id="product_code" name="product_code" formControlName="productCode">
    <input type="text" id="product_service_charge" name="product_service_charge" formControlName="productServiceCharge">
    <input type="text" id="product_delivery_charge" name="product_delivery_charge"
      formControlName="productDeliveryCharge">
    <input type="text" id="success_url" name="success_url" formControlName="successUrl">
    <input type="text" id="failure_url" name="failure_url" formControlName="failureUrl">
    <input type="text" id="signed_field_names" name="signed_field_names" formControlName="signedFieldNames">
    <input type="text" id="signature" name="signature" formControlName="signature">
    <input value="Submit" type="submit">
  </form>
</div>

<app-payment-v2-popup *ngIf="receiptExpiredValue?.isReceiptExpiredError" [paymentErrorModal]="'receiptError'" [title]="receiptExpiredValue?.receiptExpiredTitle"
  [description]="receiptExpiredValue?.receiptExpiredDescription" [acceptText]="'Okay'" [showCloseIcon]="false" (onAcceptClicked)="backToListingPage()" imageUrl="">
</app-payment-v2-popup>