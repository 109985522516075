import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApplicationService } from '@idp-education/ors-test-taker-bff-client-v1';
import { ApplicationsService } from 'shared/services/applications.service';
import {
  updateApplicationConsentForm,
  updateApplicationConsentFormFailed,
  updateApplicationConsentFormSuccess,
  uploadConsentForm,
  uploadConsentFormFailed,
  uploadConsentFormSuccess,
} from './application.action';
import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of, pipe } from 'rxjs';
import { v4 } from 'uuid';
import { Auth } from 'aws-amplify';
import { LoadingService } from 'shared/services/loading-service.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class ApplicationEffects {
  uploadConsentForm$ = createEffect(() => this.actions$.pipe(
    ofType(uploadConsentForm),
    exhaustMap((action) => {
      this.loadingService.increaseLoadingCounter();
      return this.applicationsService
        .uploadConsentFormToBucket(action.consentForm, action.s3UploadConfig)
        .pipe(
          switchMap((uploadRes: { key: string }) => (from(Auth.currentUserInfo()))),
          map((userIdentityPoolId: any) => (uploadConsentFormSuccess({
            applicationId: action.applicationId,
            s3Details: {
              note: action.note,
              s3Url: `/${userIdentityPoolId.id}/${action.consentForm.name}`,
              version: action.applicationVersion,
            }
          }))),
          catchError((error) => {
            this.loadingService.resetLoadingCounter();
            this.toastService.error('Upload failed, please try again');
            return of(error);
          })
        );
    }
    )
  ));

  uploadConsentFormSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(uploadConsentFormSuccess),
    pipe(map((action) => {
      this.loadingService.decreaseLoadingCounter();
      return (updateApplicationConsentForm({
        xCorrelationId: v4(),
        applicationId: action.applicationId,
        update: action.s3Details
      }));
    }))
  ));

  uploadConsentFormFailed$ = createEffect(() => this.actions$.pipe(
    ofType(uploadConsentFormFailed),
    pipe(map(() => {
      this.loadingService.resetLoadingCounter();
      this.toastService.error('Upload failed, please try again');
    }))
  ), { dispatch: false });

  updateApplicationConsentForm$ = createEffect(() => this.actions$.pipe(
    ofType(updateApplicationConsentForm),
    mergeMap((action) => {
      return (this.applicationService
        .updateApplicationUnderAgeConsentDetails(this.cookieService?.get('user_uuid') || action.xCorrelationId, action.applicationId, action.update))
        .pipe(
          map(res => {
            return updateApplicationConsentFormSuccess({ updatedApplication: res });
          }),
          catchError(error => (of(updateApplicationConsentFormFailed({ error }))))
        );
    })
  ));

  updateApplicationConsentFormFailed$ = createEffect(() => this.actions$.pipe(
    ofType(updateApplicationConsentFormFailed),
    pipe(map(() => {
      this.loadingService.decreaseLoadingCounter();
      this.toastService.error('Upload failed, please try again');
    }))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private applicationsService: ApplicationsService,
    private applicationService: ApplicationService,
    private loadingService: LoadingService,
    private toastService: ToastrService,
    private cookieService: CookieService
  ) {
  }
}
