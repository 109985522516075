// tslint:disable: no-unused-expression
import { Component, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { DashboardItem, DashboardItemStatus, UserProfileResponse } from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ResetState, SetLatestPageUrl } from '../booking/store/booking.actions';
import { UntypedFormGroup } from '@angular/forms';
import { CalcRemainingTimeService } from 'src/app/shared/services/calcRemaining-time.service';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Observable, Subscription } from 'rxjs';
import {  first } from 'rxjs/operators';
import Auth from '@aws-amplify/auth';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { Title, Meta } from '@angular/platform-browser';
import { setCurrentApplication } from 'src/app/store/applications/application.action';
import { CallToActionService } from 'src/app/shared/services/call-to-action.service';
import { error } from 'console';
import { Router } from '@angular/router';
import { getUserProfileName } from 'src/app/store/user/user.reducer';
import { AuthService } from 'shared/services/auth.service';
import { UtmLinkKeys, generateUtmUrl } from 'shared/utils/utm-params-utils';
import { CommonService } from 'shared/services/common.service';


type IDashboardApplications = {
  pastTests?: DashboardItem[],
  upcomingTests?: DashboardItem[],
};
type IResult = {
  speaking?: number,
  reading?: number,
  listening?: number,
  writing?: number,
  overall?: number,
  applicationId: string;
  speakingStartDateTimeUtc?: string;
  lrwStartDateTimeUtc?: string;
  productName?: string;
  testLocationTimeZone?: string
};

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, OnDestroy {
  callToActions = [];
  doesApplicationExist: boolean | null = null;
  dashboardApplications: Observable<IDashboardApplications>;
  upComingTests: DashboardItem[];
  form: UntypedFormGroup;
  stTitle = $localize`Speaking test`;
  lrwTitle = $localize`LiDashboardItem[],stening, Reading and Writing test`;
  lrwTitleMd = $localize`Listening, Reading & Writing test`;
  private timerCounter = 0;
  worker: Worker;
  private _lastResult: IResult = {
    applicationId: ''
  };
  private _hasResult = false;
  askitems = [{
    title: $localize`Where can I take an IELTS test?`,
    description: `IELTS is offered at more than 500 test locations in more than 70 countries. Find a test location that suits your needs <a target="_blank" href=${generateUtmUrl(UtmLinkKeys.OrsUrl)}>here</a>.`
  },
  {
    title: $localize`Will I get a better band score, if I keep practising sample tests?`,
    description: `<p>Practising <a target="_blank" href=${generateUtmUrl(UtmLinkKeys.CxPreparationUrl)}>sample tests</a> will help you learn what is expected in each part of the test and is good test preparation in order to maximise the English skills you have. However, remember that to improve your English you need to use English in everyday contexts - speak English with your friends, write notes and email in English, read English newspapers, listen to English radio.</p><p>If you feel you need help with your English, you should also consider taking an English course.</p>`
  },
  {
    title: $localize`How is my IELTS test marked?`,
    description: `IELTS uses a 9-band scoring system to measure and report test scores in a consistent manner. You receive individual band scores for Listening, Reading, Writing and Speaking and an Overall Band Score on a band scale from one to nine. The Speaking and Writing tests are marked by certificated IELTS examiners using the IELTS Speaking and Writing assessment criteria. Find out more about how IELTS is <a target="_blank" href=${generateUtmUrl(UtmLinkKeys.CxBandscoreUrl)}>marked</a>.`
  }];

  timerSubscription: Subscription = new Subscription();
  getUserProfileName$ = this.store.select(getUserProfileName);
  private readonly second = 1;

  constructor(private dashboardService: DashboardService,
              private store: Store<{ bookingStore, dashboardStore, globalStore, userStore }>,
              private calcRemainingTime: CalcRemainingTimeService,
              private loading: LoadingService,
              private callToActionService: CallToActionService,
              private applicationService: ApplicationsService,
              private router: Router,
              private titleService: Title, private metaTagService: Meta,
              private authService: AuthService,
              private commonService: CommonService) {
    this.titleService.setTitle('Home | IDP IELTS');
    this.store.dispatch(SetLatestPageUrl({ latestPage: null }));
    this.dashboardService.getLastResultAvailable().pipe(first()).subscribe(data => {
      if (data) {
        this._lastResult = {
          listening: data?.results?.listeningScore,
          overall: data?.results?.overallScore,
          reading: data?.results?.readingScore,
          speaking: data?.results?.speakingScore,
          writing: data?.results?.writingScore,
          applicationId: data?.applicationId,
          speakingStartDateTimeUtc: data?.speakingStartDateTimeUtc,
          lrwStartDateTimeUtc: data?.lrwStartDateTimeUtc,
          productName: data?.productName,
          testLocationTimeZone: data?.testLocationTimeZone
        };
      }
    });
    // this.dashboardService.isThereResultAvailable().pipe(first()).subscribe(data => this._hasResult = data);
  }

  ngOnDestroy(): void {
    this.timerSubscription?.unsubscribe();
    try {
      this.worker.postMessage({ key: 'stop' });
    } catch (error) { /* empty */ }
  }

 

  getUpcomingTest() {
    this.timerSubscription.add(this.dashboardService.getUpcomingTests().subscribe(upcomingTest => {
      this.upComingTests = upcomingTest;
      this.callToActions = this.callToActionService.addToCallAction(this.upComingTests);
      if (this.upComingTests.filter(t => t.status === DashboardItemStatus.UNPAID).length) {
        this.startTimer();
      }
    }));
  }
  startTimer() {
    this.worker.postMessage({ key: 'start' });
    this.worker.onmessage = ({ data }) => {
      this.callToActions = this.callToActionService.addToCallAction(this.upComingTests, this.second * this.timerCounter);
      this.timerCounter += 1;
    };
  }
  toStopTimer() {
    this.worker.postMessage({ key: 'stop' });
    this.callToActions = [];
    localStorage.removeItem('appCorrelationId');
    this.commonService?.removeCorrelationIdFromCookie();
  }
  isThereAnyApplication() {
    this.timerSubscription.add(this.dashboardService.isThereUpcomingTests().subscribe(data => {
      this.doesApplicationExist = data;
    }));
    this.store.dispatch(SetLatestPageUrl({ latestPage: null }));
  }

  ngOnInit(): void {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('./../../../assets/worker-js/timer.worker.js', { type: 'module' });
    }
    this.metaTagService.updateTag(
      {
        name: 'description',
        content: 'Check available IELTS online test dates, your IELTS test results. Book your IELTS test with IDP today.'
      },
    );
    this.authService.checkToken().pipe(first()).subscribe((user) => {
      this.fetchTests();
    }, () => {
      this.router.navigate(['/account']);
    });
    this.callToActionService.setHidden(false);
    this.calcRemainingTime.toStopTimerCallBack(this.toStopTimer.bind(this));
  }

  fetchTests() {
    this.timerSubscription.add(this.dashboardService.getAllDashboardApplications(false).subscribe());
    this.store.select(appStore => appStore.dashboardStore).pipe(first()).subscribe(() => {
      this.isThereAnyApplication();
      this.getUpcomingTest();
    });
  }

  reload() {
    this.store.dispatch(setCurrentApplication({ application: null }));
    this.store.dispatch(ResetState());
  }

  get lastResult() {
    if (!this._lastResult || !this._lastResult.overall) {
      return null;
    }
    const toFixed = (num: number) => num.toFixed(1);
    return {
      ...this._lastResult,
      listening: toFixed(this._lastResult.listening),
      overall: toFixed(this._lastResult.overall),
      reading: toFixed(this._lastResult.reading),
      speaking: toFixed(this._lastResult.speaking),
      writing: toFixed(this._lastResult.writing),
      applicationId: this._lastResult.applicationId
    };
  }

  onDownloadResult(appId) {
    if (appId) {
      this.getURL(appId).subscribe(url => {
        if (url) {
          const a = document.createElement('a');
          a?.setAttribute('href', url);
          a?.setAttribute('target', '_blank');
          a.click();
        }
      });
    }
  }

  getURL(appId: string): Observable<string> {
    return this.applicationService.getPDFUrl(appId);
  }

  get hasResult() {
    return !!this.lastResult;
  }
}
