import { Gender, IdentificationType, Nationality, TestReason } from '@idp-education/ors-test-taker-bff-client-v1';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, TemplateRef } from '@angular/core';
import { USER_SIGNUP_CONSTANTS_V2 } from 'src/app/pages/account/signup/signup.constants';
import { ID_CONSTANTS } from 'src/app/pages/onboarding/onboarding.contants';
import { NAME_REGEXP } from 'src/app/shared/sharedRegex';
import { getAutomationTestMobileFieldToggle } from '../../../utils/automation-test-toggle';
import { FormGroup } from '@angular/forms';
import { distinctUntilChanged, map, skip, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  styleUrls: ['./personal-info-form.component.scss']
})
export class PersonalInfoFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup | null = null;
  @Input() set disableEmail(value: boolean) {
    if(value) {
      this.disableFields('email');
    }
  }
  @Input() disableIdType = false;
  @Input() set disableFirstName(value: boolean) {
    if(value) {
      this.disableFields('firstName');
    }
  }
  @Input() set disableFamilyName(value: boolean) {
    if(value) {
      this.disableFields('lastName');
    }
  }
  @Input() set disableDateOfBirth(value: boolean) {
    if(value) {
      this.disableFields('birthDate');
    }
  }
  @Input() set disableIdNumber(value: boolean) {
    if(value) {
      this.disableFields('identityNo');
    }
  }
  @Input() disableCountryOfNationality = false;
  @Input() set disableExpiryDate(value: boolean) {
    if(value) {
      this.disableFields('expiryDate');
    }
  }
  @Input() disableGender = false;
  @Input() testReasonList: TestReason[] = [];
  @Input() nationalityList: Array<Nationality>;
  @Input() genderList: Array<Gender>;
  @Input() identificationTypes: Array<IdentificationType>;
  @Input() dateOfBirthToolTip?: string | TemplateRef<any>;
  @Input() dateOfBirthToolTipClass?: string;
  @Input() isUKVIApplication?: boolean;
  @Input() isOSRApplication?: boolean;
  @Input() selectedcountryISO = '';
  @Input() testDate;
  @Output() expiryValidate = new EventEmitter();
  identityType = '';

  BirthDateTooltip = 'Your date of birth';
  under16YearsOld = false;
  email: string;
  expiryDateCheck = false;

  private destroy$ = new Subject();

  userTitle: { name: string, value: string }[] = [
    { name: 'Mr', value: 'MR' },
    { name: 'Mrs', value: 'MRS' },
    { name: 'Miss', value: 'MISS' },
    { name: 'Dr', value: 'DR' },
  ];

  customMessages = {
    email: {
      pattern: () => $localize`Valid email address required`,
    },
    mobileNumber: {
      required: () => $localize`A valid telephone number is required`,
    },
    birthDate: {
      pattern: () => $localize`A valid date is required`,
    },
    identityNo: {
      pattern: () => $localize`Special characters are not allowed`,
    },
    expiryDate: {
      pattern: () => $localize`Invalid expiry date`,
      required: () => $localize`Expiry date is required`,
    }
  };

  ID_CONSTANTS = ID_CONSTANTS;

  ngOnChanges(changes: { form: SimpleChange }): void {
    if (changes.form?.currentValue) {
      this.initFormValueChanges();
    }
  }

  ngOnInit(): void {
    this.onChangeExpiryDate(this.form?.get('expiryDate')?.value, 'init');
    this.email = localStorage.getItem('emailID');
    if (this.form && this.email !== '') {
      this.form?.get('email')?.setValue(this.email);
    }
    this.setExpiryDateMandatory();
  }

  initFormValueChanges(): void {
    this.form?.valueChanges
    .pipe(
      map((val) => val.identityType),
      distinctUntilChanged(),
      skip(1),
      tap(() => {
        this.form.patchValue({
          identityNo: null,
          countryOfNationality: null,
          issuingAuthority: null,
          expiryDate: null
        });
        this.form.updateValueAndValidity();
      }),
      takeUntil(this.destroy$))
      .subscribe();
  }

  setExpiryDateMandatory() {
    const idType = this.form?.get('identityType')?.value;
    let idValue;
    if (idType && typeof idType === 'string') {
      idValue = (idType === USER_SIGNUP_CONSTANTS_V2.identificationTypeId) ? 'P' : 'I';
    } else {
      idValue = idType?.option?.code;
    }
    this.identityType = idValue;
    if (!ID_CONSTANTS[this.identityType]?.isExpiryDateMandatory) {
      this.form?.get('expiryDate')?.clearValidators();
      this.form?.get('expiryDate')?.updateValueAndValidity();
    }
  }

  getBirthDateTooltip() {
    if (this.under16YearsOld) {
      return $localize`Under 16 years old`;
    }
    return $localize`Your date of birth`;
  }

  onlyAlphaNumeric(event) {
    const eventData = event.clipboardData ? event.clipboardData : event.dataTransfer;
    const pastedData = eventData.getData('Text');
    if (!NAME_REGEXP.test(pastedData)) {
      event.preventDefault();
    } else {
      return;
    }
  }

  getNewOptionId(list: any[]): string {
    const option = list.find(
      (i) => (i?.name as string)?.toLowerCase() === 'other'
    );
    return option?.id;
  }

  // To retain the emailID on page refresh
  @HostListener('window:beforeunload')
  setLocalStorage() {
    localStorage.setItem('emailID', this.email);
  }

  public getAutomationTestToggle() {
    return getAutomationTestMobileFieldToggle();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  disableFields(formControlName) {
    this.form?.get(formControlName).disable();
  }

  onChangeExpiryDate(event: any, expiryDateValue?: string) {
    const expiryDate = typeof event === 'string' ? event : event?.target?.value || expiryDateValue;
    if (expiryDate && this.testDate) {
      const expiry = new Date(expiryDate.split('/').reverse().join('-'));
      const test = new Date(this.testDate.split('/').reverse().join('-'));
      this.expiryDateCheck = test > expiry;
      this.expiryValidateCall();
    }
  }
  expiryValidateCall() {
    this.expiryValidate.emit(this.expiryDateCheck);
  }
}
