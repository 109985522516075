import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentStatus, PaystackPaymentService } from '@idp-education/ors-paystack-payment-bff-client-v1';
import { Application } from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { EORLocalStorageItemKeys, IOCLocalStorageItemKeys, OSRLocalStorageItemKeys, PaymentStates } from 'pages/payment/payment.enum';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { CommonService } from 'shared/services/common.service';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';

@Component({
  selector: 'app-paystack-callback',
  templateUrl: './paystack-callback.component.html',
  styleUrls: ['./paystack-callback.component.scss']
})
export class PaystackCallbackComponent implements OnInit {

  @ViewChild('paymentErrorModalPaypal') paymentErrorModalPaypal: ConfirmModalComponent;
  paymentNewModalPopup = false;
  title: string;
  acceptText: string;
  rejectText: string;
  isNewPaymentJourney: string;
  userName: any;
  imageUrl: string;
  desc: string;

  constructor(
    private store: Store<{ userStore }>,
    private router: Router,
    private route: ActivatedRoute,
    private paystackPaymentService: PaystackPaymentService,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private commonService: CommonService
  ) {
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    this.store.select(appStore => appStore.userStore?.userDetails).subscribe((userDetails) => {
      this.userName = userDetails?.firstName;
    });
   }

  ngOnInit(): void {
    if(this.isNewPaymentJourney === 'enabled')
    {
      this.newmodalpopupDescription();
    }
    else{
      this.modalpopupDescription();
    }
    
  }
modalpopupDescription(){
  Auth.currentSession().then(session => {
    const paymentType = localStorage.getItem('paymentType');
    const userToken = session.getAccessToken().getJwtToken();
    let CurrentApp: Application;
    switch (paymentType) {
      case 'EOR':
        CurrentApp = JSON.parse(localStorage.getItem(EORLocalStorageItemKeys.eorApplication));
        break;
      case 'IOC':
        CurrentApp = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.applicationsStore)).currentApplication;
        break;
      case 'OSR':
        CurrentApp = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.ssrApplication));
        break;
      default:
        break;
    }
    this.paystackPaymentService.configuration.accessToken = userToken;
    const applicationId = CurrentApp?.id;
    this.route.queryParamMap.subscribe((queryParams) => {
      const reference = queryParams.get('reference');
      this.paystackPaymentService.paystackPaymentStatus(reference, applicationId, this.commonService.appCorrelationId()).subscribe((response) => {
        if (response?.status) {
          switch (response.status) {
           case PaymentStatus.Failed:
              this.title = `Your payment failed. Please retry the payment.`;
              this.acceptText = 'Retry Payment';
              this.paymentErrorModalPaypal.open();
              this.loadingService.decreaseLoadingCounter();
              break;
            case PaymentStatus.Success:
                this.navigationService.storePaymentState(PaymentStates.COMPLETED);
                this.navigationService.navigateOnPaymentType(paymentType);
                break;
            case PaymentStatus.Abandoned:
              this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
              this.acceptText = 'Payment Cancelled';
              this.paymentErrorModalPaypal.open();
              this.loadingService.decreaseLoadingCounter();
              break;
            case PaymentStatus.Pending:
              this.title = `Your payment is Pending confirmation from Paystack. Please check your IELTS Profile after some time for the latest status or contact your test centre.`;
              this.acceptText = 'Return to My Account';
              this.paymentErrorModalPaypal.open();
              this.loadingService.decreaseLoadingCounter();
              break;
            default:
              this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
              this.acceptText = 'Return to My Account';
              this.paymentErrorModalPaypal.open();
              this.loadingService.decreaseLoadingCounter();
              break;
          }
        }
      }, error => {
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.acceptText = 'Return to My Account';
        this.paymentErrorModalPaypal.open();
        this.loadingService.decreaseLoadingCounter();
      });
    });
  });
}

newmodalpopupDescription(){
  Auth.currentSession().then(session => {
    const userToken = session.getAccessToken().getJwtToken();
    const paymentType = localStorage.getItem('paymentType');
    let CurrentApp: Application;
    switch (paymentType) {
      case 'IOC':
        CurrentApp = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.applicationsStore))?.currentApplication;
        break;
      case 'OSR':
        CurrentApp = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.ssrApplication));
        break;
      case 'EOR':
        CurrentApp = JSON.parse(localStorage.getItem(EORLocalStorageItemKeys.eorApplication));
        break;
      default:
        break;
    }
    const applicationId = CurrentApp?.id;
    this.paystackPaymentService.configuration.accessToken = userToken;
    this.route.queryParamMap.subscribe((queryParams) => {
      const reference = queryParams.get('reference');
      this.paystackPaymentService.paystackPaymentStatus(reference, applicationId, this.commonService.appCorrelationId()).subscribe((response) => {
        if (response?.status) {
          switch (response.status) {
            case PaymentStatus.Success:
              this.navigationService.storePaymentState(PaymentStates.COMPLETED);
              this.navigationService.navigateOnPaymentType(paymentType);
              break;
            case PaymentStatus.Failed:
              this.title = `Payment Failed`;
              this.desc = `Hi ${this.userName}, your payment has failed, possibly due to incorrect payment information. Please retry your payment.`;
              this.acceptText = 'Try again';
              this.imageUrl = './assets/images/payment-alert.svg'
              this.paymentNewModalPopup = true;
              this.loadingService.decreaseLoadingCounter();
              break;
            case PaymentStatus.Abandoned:
              this.title = `Payment Cancelled`;
              this.desc = `Hi ${this.userName}, your payment has been cancelled. You will be redirected to your profile.`;
              this.acceptText = 'Ok';
              this.imageUrl = './assets/images/payment-info.svg'
              this.paymentNewModalPopup = true;
              this.loadingService.decreaseLoadingCounter();
              break;
            case PaymentStatus.Pending:
              this.title = `Payment Pending`;
              this.desc = `Hi ${this.userName}, your payment confirmation is pending. Please check back shortly to view the status or contact your test centre for assistance.`;
              this.acceptText = 'Ok';
              this.imageUrl = './assets/images/payment-wait.svg'
              this.paymentNewModalPopup = true;
              this.loadingService.decreaseLoadingCounter();
              break;
            default:
              this.title = `Payment Error`;
              this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
              this.acceptText = 'Try again';
              this.imageUrl = './assets/images/payment-alert.svg'
              this.paymentNewModalPopup = true;
              this.loadingService.decreaseLoadingCounter();
              break;
          }
        }
      }, error => {
        this.title = `Payment Error`;
        this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
      });
    });
  });
}
  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    if(this.isNewPaymentJourney === 'enabled'){
      this.paymentNewModalPopup = false;
    }
    else{
      this.paymentErrorModalPaypal.closeModal(true);
    }
    
  }
}
