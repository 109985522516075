import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Auth } from 'aws-amplify';
import { CookieService } from 'ngx-cookie';
import { gtmConst } from 'shared/utils/trackingConstants';
import { NavigationService } from './navigation.service';
import { sha256 } from 'js-sha256';
const appSettings = require('src/assets/appSettings.json');
interface GtmEvent {
  event: string;
  "event category": string;
  websitename: string;
  language: string;
  userstatus?: string;
  pagename: string;
  page_referrer: string;
  version: string;
  step: any;
  OnetrustActiveGroups?: string;
  user_id?: string;
  old_user_id?: string;
}
@Injectable({
  providedIn: 'root'
})
export class GtmTrackingService {
  previousUrl = '';
  currentStep = 0;
  dontUpdateCurrentStep = false;
  isLoggedIn: boolean;

  constructor(
    private gtmService: GoogleTagManagerService,
    private readonly cookie: CookieService,
    private readonly onetrust: NavigationService
  ) { }

  initGoogleAnalytics() {
    this.checkUserStatus();
  }

  setOrGetClientTracking(): any {
    let clientTracking: any = this.cookie.get('client_tracking'); 
    if (clientTracking) {
      clientTracking = JSON.parse(clientTracking);
      if (clientTracking?.userstatus === gtmConst.common.loggedIn && clientTracking?.old_user_id === 'na') {
        clientTracking.old_user_id = this.getGaValue();
        this.cookie.put('client_tracking', JSON.stringify(clientTracking),
        { domain: appSettings.site.envSubDomain, path: '/', secure: true, sameSite: 'none'}
      );
      }
    } else {
      clientTracking = {
        userstatus: gtmConst.common.anonymous,
        userId: this.getGaValue(),
        old_user_id: 'na'
      };
      this.cookie.put('client_tracking', JSON.stringify(clientTracking),
      { domain: appSettings.site.envSubDomain, path: '/', secure: true, sameSite: 'none'}
    );
    }
  
    if (this.isLoggedIn) {
      this.getUserEmail().then(email => {
        if (email) {
          const hashedEmail = sha256(email);  
          const currentUserId = clientTracking.userId;         
          clientTracking.userstatus = gtmConst.common.loggedIn;
          if (currentUserId !== hashedEmail) {
            clientTracking.userId = hashedEmail;
            clientTracking.old_user_id = currentUserId;
          }  
          this.cookie.put('client_tracking', JSON.stringify(clientTracking),
          { domain: appSettings.site.envSubDomain, path: '/', secure: true, sameSite: 'none'}        
        );
        }
      }).catch((error) => {
        console.error("Error fetching email:", error);
      });
      
    } else {
      if (clientTracking?.userstatus === gtmConst.common.loggedIn && clientTracking?.userstatus !== gtmConst.common.anonymous) {
        clientTracking.userstatus = gtmConst.common.loggedOut;
        this.cookie.put('client_tracking', JSON.stringify(clientTracking),
        { domain: appSettings.site.envSubDomain, path: '/', secure: true, sameSite: 'none'}
      );      
    }
    }  
    return clientTracking;
  }

  async getUserEmail(): Promise<string> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user?.attributes?.email || '';
    } catch (e) {
      console.error("Error fetching user email:", e);
      return '';
    }
  }
 
  getGaValue() {
    let userId = '';
    const gaValue = this.cookie.get('_ga');
    if (gaValue) {
      userId = this.extractGaValue(gaValue);
    }
    return userId;
  }

  extractGaValue(gaCookieValue: string): string | null {
    if (!gaCookieValue) return null;
    const parts = gaCookieValue.split('.');
    if (gaCookieValue.startsWith('GA1.1.') && parts.length >= 4) {
      // For _ga
      return parts[2] + '.' + parts[3];
    }
    return null;
  }

  updateCurrentStep(reduceStep?: any) {
    if (this.dontUpdateCurrentStep) {
      this.dontUpdateCurrentStep = false;
    } else {
      this.dontUpdateCurrentStep = !!reduceStep;
      this.currentStep += reduceStep ? -1 : 1;
    }
  }

  trackGa4(eventVal: any, titleVal: string, eventCategory?: string) {
   
     
   
    if (!this.isLoggedIn) {
      this.checkUserStatus();
    }
    const gtmTag: any = this.formGtmEvents(eventVal, titleVal, eventCategory, this.previousUrl, this.currentStep);
    this.gtmService.pushTag(gtmTag);
  }

  formGtmEvents(event: string, pagename: string, eventCategory?: string, pageReferrerUrl?: string, currentStep?: number) {
    const gtmEvent: GtmEvent = {
      event: event,
      "event category": eventCategory || gtmConst.common.na,
      websitename: gtmConst.websiteName.bookingExperience,
      language: gtmConst.common.english,
      userstatus: this.isLoggedIn ? gtmConst.common.loggedIn : gtmConst.common.anonymous,
      pagename: pagename || '',
      page_referrer: pageReferrerUrl ? location.origin + pageReferrerUrl : gtmConst.common.na,
      version: gtmConst.common.v2,
      step: currentStep || 1,
      OnetrustActiveGroups: this.getConsentGroupsMatch()
    };
   
    this.onetrust.isFeatureEnabled$.subscribe((isFeatureEnabled) => {
      if (isFeatureEnabled) {
        let clientTracking: any = this.cookie.get('client_tracking');
        if (typeof clientTracking === 'string') {
          clientTracking = JSON.parse(clientTracking);
        }

        gtmEvent['userstatus'] = clientTracking?.userstatus;
        gtmEvent['user_id'] = clientTracking?.userId;
        gtmEvent['old_user_id'] = clientTracking?.old_user_id;
      }
    });
    
    return gtmEvent;
  }

  getConsentGroupsMatch() {
    const cookieConsent = this.getCookieValue()
    const consentGroupsMatch = cookieConsent.match(/groups=([^&]*)/)
    const finalValue = []
    if (consentGroupsMatch?.length) {
      decodeURIComponent(consentGroupsMatch[1]).split(',').forEach(data => {
        if (data?.includes(':1')) {
          finalValue.push(data?.split(':')[0])
        }
      })
    }
    return finalValue?.length ? ',' + finalValue.join(',') + ',' : ',C0001,C0004,C0003,C0002,';
  }

  checkUserStatus() : any{
    let clientTracking: any = this.cookie.get('client_tracking');
    if(clientTracking){
    clientTracking = JSON.parse(clientTracking);
    }
    const isUserAlreadyLoggedIn = clientTracking?.userstatus === 'logged in' && clientTracking.old_user_id === 'na';
    if (isUserAlreadyLoggedIn) {
      this.isLoggedIn = true
      this.onetrust.isFeatureEnabled$.subscribe((isFeatureEnabled) => {
        if (isFeatureEnabled) {
          this.setOrGetClientTracking()
        }
      })     
    }
    else{
     this.checkUserAuthentication()
  }
  } 

  checkUserAuthentication() {
    Auth.currentAuthenticatedUser()?.then(user => {
      this.isLoggedIn = true;
      // this.userId = user.attributes.sub;
      this.onetrust.isFeatureEnabled$.subscribe((isFeatureEnabled) => {
        if (isFeatureEnabled) {
          this.setOrGetClientTracking()
        }
      })  
    }).catch(() => {
      this.isLoggedIn = false;
    });
  }

  getCookieValue(): string {
    const optanonConsent = document.cookie.split('; ').find(cookie => cookie.startsWith('OptanonConsent='));
    if (!optanonConsent) {
      return '';
    }
    return optanonConsent;
  }
}
