import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setFee, setLrwtesttime, setOfflinePayment, setSpeakingtesttime } from 'pages/payment/store/payment.actions';
import { setCurrentApplication } from 'store/applications/application.action';
import {
  setCallToActionState,
  setOSRCurrentApplication,
  setSelectedTest,
  setSsrComponent,
  setSSRFee
} from 'store/my-tests/my-tests.actions';
import {
  Application,
  ApplicationPayment,
  ApplicationPaymentStatus,
  ApplicationService,
  ApplicationVerifyPaymentRequest,
  ApplicationVerifyPaymentResponse,
  PaymentMethod
} from '@idp-education/ors-test-taker-bff-client-v1';
import { Observable, of, timer } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { SetLatestPageUrl, SetLRWRedirectUrl, SetNonIOLLocationId, SetNonIOLProductId, SetProductType, SetSpeakingRedirectUrl, SetTestLocalTimeZone } from 'pages/booking/store/booking.actions';
import { CallToActionState } from 'store/my-tests/my-tests.reducer';
import { skillsLabel } from 'pages/my-tests/EOR/eor-request-form/eor-request-form.component';
import { NavigationService } from 'shared/services/navigation.service';
import { Router } from '@angular/router';
import { PaymentUtilService } from 'pages/payment/payment-util.service';
import { isIOCProduct, isOSRIOCProduct } from 'shared/utils/is-ioc-product';
import { IBookingStoreState } from 'pages/booking/store/booking.reducer';
import { CommonService } from './common.service';
export enum FeeType {
    APPLICATION_REGISTRATION = 'APPLICATION_REGISTRATION',
    EOR = 'EOR'
}
@Injectable({
  providedIn: 'any'
})
export class TipsService {
  isNewPaymentJourney: string;
  constructor(
    private store: Store<{ applicationsStore }>,
    private router: Router,
    private paymentUtilService: PaymentUtilService,
    private navigationService: NavigationService,
    private applicationService: ApplicationService,
    private commonService: CommonService
  ) {
   
  }


  verifyTipsPayment(
    applicationId: string,
    isEOR: boolean,
    isCancel: boolean
  ): Observable<{ application: Application, applicationPayment: ApplicationPayment, error?: any }> {
    let application: Application;
    let paymentId: string;
    let applicationPayment: ApplicationPayment;
    let error: any;
    return this.applicationService.getApplication(applicationId, this.commonService?.getUserUuid()).pipe(
      concatMap((app: Application) => {
        application = app;
        const appPayment = app?.applicationPayments.find(
          payment =>
            (
              payment.paymentMethod === PaymentMethod.TIPS &&
              !!payment.paymentDocument &&
              !isEOR
            ) || (
              payment.paymentMethod === PaymentMethod.TIPS &&
              !!payment.paymentDocument &&
              isEOR &&
              payment.id === app.EOR.applicationPaymentId
            )
        );
        applicationPayment = appPayment;
        if (appPayment?.paymentDocument?.tips?.paymentToken && !isCancel && appPayment.status !== ApplicationPaymentStatus.PAID) {
          paymentId = appPayment.id;
          return this.applicationService.paymentVerification(app.id, {
            paymentToken: appPayment.paymentDocument.tips.paymentToken
          } as ApplicationVerifyPaymentRequest, this.commonService?.appCorrelationId()).pipe(catchError((err: any) => {
            error = err;
            return of(null);
          }));
        } else if (isCancel || appPayment.status === ApplicationPaymentStatus.PAID) {
          return of(null);
        } else {
          throw new Error('error in getting TIPS payment token from the applicationId: ' + app.id);
        }
      }),
      map((paymentVerificationResponse: ApplicationVerifyPaymentResponse) => {
        if (!isCancel && paymentVerificationResponse) {
          const appPayment = (paymentVerificationResponse.applicationPayments || []).find(
            payment =>
              payment.paymentMethod === PaymentMethod.TIPS &&
              payment.id === paymentId &&
              !!payment.paymentDocument
          );
          return {
            application,
            applicationPayment: appPayment,
            error
          };
        } else {
          return {
            application,
            applicationPayment,
            error
          };
        }
      })
    );
  }

  convertToJSDateWithoutTimezone(date: string) {
      const timezoneRemoved = (date && date.length > 6) ? date.substring(0, date.length - 6) : '';
      return new Date(timezoneRemoved);
    }

  navigateToIocPaymentJourney(app: Application, payment: ApplicationPayment, isCancel: boolean) {
    this.store.dispatch(SetProductType({
      isNotIOLProduct: false
    }));
    const { speaking, lrw } = this.paymentUtilService.getSpeakingAndLRW(app);
    this.store.dispatch(setSpeakingtesttime({
      speakingtesttime: {
        from: this.convertToJSDateWithoutTimezone(speaking.bookingLines[0].startDateTimeLocal),
        to: this.convertToJSDateWithoutTimezone(speaking.bookingLines[0].endDateTimeLocal)
      }
    }));
    this.store.dispatch(setLrwtesttime({
      lrwtesttime: {
        from: this.convertToJSDateWithoutTimezone(lrw.bookingLines[0].startDateTimeLocal),
        to: this.convertToJSDateWithoutTimezone(lrw.bookingLines[0].endDateTimeLocal)
      }
    }));
    this.store.dispatch(setFee({ fee: payment.applicationFee as any }));
    this.store.dispatch(setCurrentApplication({ application: app }));
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    timer(0).subscribe(() => {
      this.router.navigate([`${isCancel ? this.getRoute() : this.getRedirectionRoute()}`]);
    });
  }
  getRedirectionRoute() {
    return this.isNewPaymentJourney === 'enabled'
      ?  '/account/booking-confirmation'
      :  '/payment/confirmation';
  }
  
  getRoute(){
    localStorage.setItem('isFromTips',JSON.stringify(true));
    const bookingStoreStateString = localStorage?.getItem('bookingstore');
    if (bookingStoreStateString) {
      const bookingStoreState: IBookingStoreState = JSON.parse(bookingStoreStateString);
      this.store.dispatch(SetProductType({
        isNotIOLProduct: bookingStoreState?.isNotIOLProduct
      }));
      this.store.dispatch(SetSpeakingRedirectUrl({
        speakingUrl: bookingStoreState?.speakingUrl
      }));
      this.store.dispatch(SetLRWRedirectUrl({
        lrwUrl: bookingStoreState?.lrwUrl
      }));
      this.store.dispatch(SetTestLocalTimeZone({
        testLocalTimezone: bookingStoreState?.testLocalTimezone
      }));
      this.store.dispatch(SetNonIOLLocationId({
        nonIOLLocationId: bookingStoreState?.nonIOLLocationId
      }));
      this.store.dispatch(SetNonIOLProductId({
        nonIOLProductId: bookingStoreState?.nonIOLProductId
      }));
      this.store.dispatch(SetLatestPageUrl({
        latestPage: bookingStoreState?.latestPageUrl
      }));
    }

    
    return this.isNewPaymentJourney === 'enabled'
    ?  '/account/booking-summary/payment-options'
    :  '/payment/card-detail'

  }


  navigateToMyTestsPage(app: Application, payment: ApplicationPayment, callToActionState: CallToActionState) {
    if (callToActionState.includes('osr-')) {
      this.store.dispatch(setSelectedTest({
        selectedTest: {
          testStartLocalDatetime: app.bookings[0]?.startDateTimeUtc,
          testLocalTimeZone: app.bookings[0]?.testLocationTimeZone
        } as any
      }));
      const languageSkill = app.bookings[0]?.bookingLines.find(b => b.status === 'ACTIVE').languageSkill;
      this.store.dispatch(setSsrComponent({ ssrComponent: skillsLabel[languageSkill] }));
      this.store.dispatch(setOfflinePayment({ isOffline: false }));
      this.store.dispatch(setSSRFee({ ssrFee: payment.applicationFee as any }));
    } else if (callToActionState.includes('eor-')) {
      if (callToActionState.includes('-post-')) {
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
      } else {
        this.navigationService.storePaymentState(PaymentStates.INITIATED);
      }
      this.navigationService.storePaymentGateway(PaymentGateways.TIPS);
      this.navigationService.storePaymentType('EOR');
      this.navigationService.storeEORInfo(app.EOR);
    }
    this.store.dispatch(setCallToActionState({ callToActionState }));
    this.store.dispatch(setOSRCurrentApplication({ currentOSRApplication: app }));
    timer(0).subscribe(() => {
      this.router.navigate(['/my-tests']);
    });
  }

  onPaidApplicationPayment(
    feeType: FeeType,
    productName: string,
    application: Application,
    applicationPayment: ApplicationPayment
  ) {
    const isEOR = feeType === FeeType.EOR;
    if (feeType === FeeType.APPLICATION_REGISTRATION && isIOCProduct(productName)) {
      this.navigateToIocPaymentJourney(application, applicationPayment, false);
    } else if (feeType === FeeType.APPLICATION_REGISTRATION && isOSRIOCProduct(productName)) {
      this.navigateToMyTestsPage(application, applicationPayment, CallToActionState.OSR_POST_PAYMENT);
    } else if (isEOR) {
      this.navigateToMyTestsPage(application, applicationPayment, CallToActionState.EOR_POST_PAYMENT);
    }
  }

  onCancel(feeType: FeeType, productName: string, application: Application, applicationPayment: ApplicationPayment) {
    if (feeType === FeeType.APPLICATION_REGISTRATION && isIOCProduct(productName)) {
      this.navigateToIocPaymentJourney(application, applicationPayment, true);
    } else if (feeType === FeeType.APPLICATION_REGISTRATION && isOSRIOCProduct(productName)) {
      this.navigateToMyTestsPage(application, applicationPayment, CallToActionState.OSR_PRE_PAYMENT);
    } else if (feeType === FeeType.EOR) {
      this.navigateToMyTestsPage(application, applicationPayment, CallToActionState.EOR_PRE_PAYMENT);
    }
  }
}
