import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Application, CreateReceiptRequest, CreateReceiptResponse, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { Subscription } from 'rxjs';
import { UserState } from 'store/user/user.reducer';
import { ICon } from '../payment-container/payment-container.component';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { NavigationService } from 'shared/services/navigation.service';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { Store } from '@ngrx/store';
import { DeviceType, InipayPaymentService, PaymentMethod } from '@idp-education/ors-inipay-payment-bff-client-v1';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingService } from 'shared/services/loading-service.service';
import { DynamicScriptLoaderService } from 'shared/services/dynamic-script-loader.service';
import { SharedStateService } from 'pages/account/shared-state.service';
import { PaymentsService } from 'shared/services/payment.services';
import { CommonService } from 'shared/services/common.service';
const appSettings = require('./../../../../../assets/appSettings.json');
declare function iniPayAutosubmit(P_MID: any, P_OID: any, P_PAY_TYPE: any, P_DEVICE_TYPE: any, P_IDCCODE: any,
  P_AMT: any, P_GOODS: any, P_UNAME: any, P_TIMESTAMP: any, P_NEXT_URL: any,
  P_NOTI_URL: any, P_CLOSE_URL: any, PAYMENT_URL: any, P_CHKFAKE: any,
  P_NOTI: any, P_CHARSET: any): any

@Component({
  selector: 'app-inipay',
  templateUrl: './inipay.component.html',
  styleUrls: ['./inipay.component.scss']
})
export class InipayComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() tcPaymentMethod = [];
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @Input() isNewJourney = false;

  postReceipt: Subscription;
  sub: Subscription;
  userState: UserState;
  receiptResponse: CreateReceiptResponse;

  formData: FormGroup;
  paymentUrl: string;

  paymentMethods = [];
  cardTitle: string;
  bankTransferTitle: string;
  tcPaymentMethodId: string;
  paymentSelected: any;
  isPaymentMethod = false;
  isLoading = false;
  P_MID: string;
  P_OID: string;
  P_PAY_TYPE: string;
  P_DEVICE_TYPE: string;
  P_IDCCODE: string;
  P_AMT: string;
  P_GOODS: string;
  P_UNAME: string;
  P_TIMESTAMP: string;
  P_NEXT_URL: string;
  P_NOTI_URL: string;
  P_CLOSE_URL: string;
  PAYMENT_URL: string;
  P_CHKFAKE: string;
  P_NOTI: string;
  P_CHARSET: string;
  receiptExpiredValue;

  static get tab(): ICon {
    return {
      alt: 'inipay',
      key: 'INIPAY',
      url: '/assets/images/payment/INIpay.svg',
      class: '',
      title: 'Pay online with Credit Card and Bank Transfer',
      description: 'Secure payments powered by INIPay',
    };
  }

  constructor(
    private receiptService: ReceiptService,
    private navigationService: NavigationService,
    private deviceService: DeviceDetectorService,
    private inipayPaymentService: InipayPaymentService,
    private loadingService: LoadingService,
    private scriptLoaderService: DynamicScriptLoaderService,
    private sharedState: SharedStateService,
    private paymentService: PaymentsService,
    private commonService: CommonService,
    private store: Store<{ userStore }>
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.userState = x;
    });
  }

  ngOnInit(): void {
    this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.INIPAY) {
        this.onCardClick(paymentDetails.typeVal);
      }
    }));
    this.isPaymentMethod = false;
    this.isLoading = false;
    const tcPaymentMethod = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.INIPAY);
    this.tcPaymentMethodId = tcPaymentMethod?.id;
    this.paymentMethods = tcPaymentMethod?.paymentMethodConfig?.document?.paymentMethodConfiguration?.paymentMethods;
    if (this.paymentMethods?.length) {
      this.paymentMethods?.forEach(method => {
        if (method === PaymentMethod.CREDITCARD) {
          this.cardTitle = 'Card Payment';
          this.isPaymentMethod = true;
        } else if (method === PaymentMethod.BANKTRANSFER) {
          this.bankTransferTitle = 'Bank Transfer';
          this.isPaymentMethod = true;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onCardClick(paymentType) {
    this.paymentSelected = paymentType === 'card' ? PaymentMethod.CREDITCARD : PaymentMethod.BANKTRANSFER;
    this.isLoading = true;
    this.createReceipt();
  }

  createReceipt() {
    const tcPaymentMethodId = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.INIPAY);
    const createReceiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: tcPaymentMethodId?.id,
      paymentMethod: this.paymentSelected
    };

    this.postReceipt = this.receiptService.createReceipt(this.commonService.appCorrelationId(), createReceiptRequest)?.subscribe(result => {
      this.receiptResponse = result;
      this.storeBookingInfo();
    }, (error) => {
      if(error?.includes('APPLICATION_EXPIRED')){
        this.receiptExpiredValue = this.paymentService.getErrorState();
      }
    });
  }

  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.INIPAY);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
    this.fetchFormData();
  }

  fetchFormData() {
    this.inipayPaymentService.configuration.accessToken = this.userState?.userToken;
    const deviceType = this.deviceService.isMobile() ? DeviceType.MOBILE : DeviceType.WEB;
    const appId = this.applicationId;
    const receiptId = this.receiptResponse.id;
    const postSub = this.inipayPaymentService.getPaymentFormData(appId, this.paymentSelected, deviceType, receiptId, this.commonService.appCorrelationId()).subscribe(result => {
      this.loadingService.increaseLoadingCounter();
      const resultData = result;
      this.scriptLoaderService.loadScript(appSettings.site.iniPayScriptURL, '', 'UTF-8')
        .then(() => {
          this.P_MID = resultData.merchantId;
          this.P_OID = resultData.receiptNumber;
          this.P_PAY_TYPE = resultData.paymentType;
          this.P_DEVICE_TYPE = resultData.deviceType;
          this.P_IDCCODE = resultData.idcCode;
          this.P_AMT = resultData.amount;
          this.P_GOODS = resultData.productName;
          this.P_UNAME = resultData.testCentreName;
          this.P_TIMESTAMP = resultData.timeStamp;
          this.P_NEXT_URL = resultData.nextUrl;
          this.P_NOTI_URL = resultData.notiUrl;
          this.P_CLOSE_URL = resultData.closeUrl;
          this.PAYMENT_URL = resultData.paymentUrl;
          this.P_CHKFAKE = resultData.signature;
          this.P_NOTI = resultData.receiptId;
          this.P_CHARSET = resultData.chatset;
          this.loadingService.decreaseLoadingCounter();
          iniPayAutosubmit(this.P_MID,
            this.P_OID,
            this.P_PAY_TYPE,
            this.P_DEVICE_TYPE,
            this.P_IDCCODE,
            this.P_AMT,
            this.P_GOODS,
            this.P_UNAME,
            this.P_TIMESTAMP,
            this.P_NEXT_URL,
            this.P_NOTI_URL,
            this.P_CLOSE_URL,
            this.PAYMENT_URL,
            this.P_CHKFAKE,
            this.P_NOTI,
            this.P_CHARSET);
        });
    }, error => {
      this.loadingService.decreaseLoadingCounter();
    });
    this.sub.add(postSub);
  }

  backToListingPage(): void {
    window.open(`${appSettings.site.bxsearchURL}/book-ielts`, '_self');
  }

}
