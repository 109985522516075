import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, pairwise } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CacheService } from './shared/services/cache.service';
import { OneTrustService } from 'shared/services/one-trust.service';
import { GtmTrackingService } from 'shared/services/gtm-tracking.service';
import { setUtminSession } from 'shared/utils/utm-params-utils';
import { NavigationService } from 'shared/services/navigation.service';
const appSettings = require('src/assets/appSettings.json');
declare let dataLayer;
declare global {
  interface Window {
    dataLayer: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'idp';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{ globalStore }>,
    private gtmService: GoogleTagManagerService,
    private cacheService: CacheService,
    private oneTrustservice:OneTrustService,
    private gtmTrackingService: GtmTrackingService,
    private mock: NavigationService,
  ) {
    window.dataLayer = window.dataLayer || [];
    this.cacheService.startEvent();
  }


  ngOnInit() {
    this.mock.featureFlag().subscribe();
    this.getCurrentStepCount();
    setUtminSession();
    this.getCurrentStepCount();
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {

      const mainHeader: any = document.querySelector('#NavBar');
      if (mainHeader) {
        mainHeader.focus();
      }
    });
    this.store.select(appState => appState.globalStore).subscribe(x => {
      if (x?.enableOnetrustPolicy) {
        this.oneTrustservice.denyGrantGATag();
       } else {
        this.oneTrustservice.addGaScript();
      }
    });
    this.gtmTrackingService.initGoogleAnalytics();
    this.getPreviousUrl();
  }

  getCurrentStepCount() {
    const urlString = window.location.search;
    const params = new URLSearchParams(urlString);
    const currentStep = params.get('currentStep');
    this.gtmTrackingService.currentStep = currentStep ? Number(currentStep) : 0;
  }

  @HostListener('window:beforeunload')
  clearLocalStorage() {
    this.cacheService.stop();
    localStorage.removeItem('page');
    localStorage.removeItem('prefilled');
    localStorage.setItem('emailID', '');
  }

  @HostListener('window:popstate')
  onPopState() {
    this.gtmTrackingService.updateCurrentStep(true);
  }

  getPreviousUrl() {
    this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.gtmTrackingService.previousUrl = events[0].urlAfterRedirects;
      });
  }
}
