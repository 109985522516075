<div class="container-sm">
    <h1 class="mt-3 mb-3">Mock settings</h1>
    <div class="mb-3 hide">
        <label class="form-label">Pre-payment additional fields</label>
        <div class="input-group mb-3">
            <button class="btn btn-outline" [class.active]="(sharedService.getState() | async)?.enablePrePaymentAddFields"
                type="button" (click)="setState('enablePrePaymentAddFields',true)">Yes</button>
            <button class="btn btn-outline" [class.active]="!(sharedService.getState() | async)?.enablePrePaymentAddFields"
                type="button" (click)="setState('enablePrePaymentAddFields',false)">No</button>
        </div>
    </div>
    <div class="mb-3">
        <label class="form-label">Payment Confirmation additional questions</label>
        <div class="input-group mb-3">
            <button class="btn btn-outline" [class.active]="(sharedService.getState() | async)?.enablePaymentConfirmation"
                type="button" (click)="setState('enablePaymentConfirmation',true)">Yes</button>
            <button class="btn btn-outline" [class.active]="!(sharedService.getState() | async)?.enablePaymentConfirmation"
                type="button" (click)="setState('enablePaymentConfirmation',false)">No</button>
        </div>
    </div>
    <div class="mb-3">
        <label class="form-label">Promotional Banner</label>
        <div class="input-group mb-3">
            <button class="btn btn-outline" [class.active]="(sharedService.getState() | async)?.enablePromotionalBanner"
                type="button" (click)="setState('enablePromotionalBanner',true)">Yes</button>
            <button class="btn btn-outline"
                [class.active]="!(sharedService.getState() | async)?.enablePromotionalBanner" type="button"
                (click)="setState('enablePromotionalBanner',false)">No</button>
        </div>
    </div>
    <div class="mb-3 hide">
        <label class="form-label">Dashboard</label>
        <div class="input-group mb-3">
            <button class="btn btn-outline" [class.active]="(sharedService.getState() | async)?.enableDashboard"
                type="button" (click)="setState('enableDashboard',true)">Yes</button>
            <button class="btn btn-outline" [class.active]="!(sharedService.getState() | async)?.enableDashboard"
                type="button" (click)="setState('enableDashboard',false)">No</button>
        </div>
    </div>
    <div class="mb-3">
        <label class="form-label">Captcha</label>
        <div class="input-group mb-3">
            <button class="btn btn-outline" [class.active]="(sharedService.getState() | async)?.enableCaptcha"
                type="button" (click)="setState('enableCaptcha',true)">Yes</button>
            <button class="btn btn-outline"
                [class.active]="!(sharedService.getState() | async)?.enableCaptcha" type="button"
                (click)="setState('enableCaptcha',false)">No</button>
        </div>
    </div>
    <div class="mb-3">
        <a href="/">Home</a>
    </div>
    <div id="build-info" class="buildInfo">
        Bx App {{buildInfo}}
    </div>
</div>