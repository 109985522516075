import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedStateService, IState } from './../shared-state.service';
import { CountdownComponent } from 'ngx-countdown';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from './../signup/userProfile.model';
import { MustMatch } from 'src/app/shared/validators/must-match';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { isNumber } from 'lodash';
import { CognitoErrorService } from 'src/app/shared/services/cognito-error.service';

@Component({
  selector: 'app-reset-password-confirm',
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['./reset-password-confirm.component.scss'],

})
export class ResetPasswordConfirmComponent implements OnInit {
  showCountDown = false;
  hasValidationError = false;
  validationError = '';
  showInput = true;
  @ViewChild('cd', { static: false }) countdown: CountdownComponent;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  @ViewChild('sessionsError') sessionsError: ConfirmModalComponent;
  stateData: IState = {};
  $form: UntypedFormGroup;
  currentMouseOver: string;
  visibility = false;
  passwordType = 'password';
  nudghInput = {};
  error = '';
  hasError = false;
  validPin = false;
  validPassword = false;
  networkError = false;
  hasResendCodeError = false;
  resendCodeErrorMessage = '';
  pin = '';
  constructor(
    private fb: UntypedFormBuilder,
    private state: SharedStateService,
    private router: Router,
    private cognitoError: CognitoErrorService,
    public loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.state.getState().subscribe(data => {
      this.stateData = data;
      this.hasValidationError = !!data.validationMessage;
      this.validationError = data.validationMessage;
      const user = new User();
      this.$form = this.fb.group({
        password: [data.password || '', user.getValidatorFields('pass')],
        confirmPassword: [data.password || '', user.getValidatorFields('confirmPass')]
      }, { validators: [MustMatch('password', 'confirmPassword')] });
    });
  }
  onComplete(code: string) {
    if (isNumber(+code)) {
      this.pin = code;
      this.validPin = true;
    } else {
      this.validPin = false;
    }
    setTimeout(() => {
      this.passwordInput.nativeElement.focus();
    }, 10);
  }

  keyUp(k) {
    if (k.key === 'Delete' || k.key === 'Backspace'){
      this.validPin = false;
    }
  }

  mouseEvent(e) {
    if (e.type === 'cut') {
      this.validPin = false;
    }
  }
  onEnterCode() {
    this.hasValidationError = false;
  }
  onResendCode() {
    if (this.stateData.email) {
      this.loadingService.isLoading.next(true);
      this.showCountDown = true;
      Auth.forgotPassword(this.stateData.email).then(result => {
        this.loadingService.isLoading.next(false);
        if (this.countdown) {
          this.countdown.begin();
          this.state.changeState({
            ...this.stateData,
            resendCodeErrorMessage: ''
          });
        }
        this.hasResendCodeError = false;
        this.showInput = false;
        setTimeout(() => this.showInput = true, 200);
      }).catch(error => {
        this.hasResendCodeError = true;
        this.resendCodeErrorMessage = this.cognitoError.getErrorMessage(error);
        this.loadingService.isLoading.next(false);
      });
    } else {
      return false;
    }
  }
  handleEvent(event) {
    if (event.action === 'done') {
      this.showCountDown = false;
    }
  }
  mouseOver(event: any) {
    const name = event.target.getAttribute('name');
    if (event.target.offsetWidth - event.offsetX < 40) {
      event.target.style.cursor = 'pointer';
      this.currentMouseOver = name.substr(0, 1);
    }
    else {
      event.target.style.cursor = 'auto';
    }
  }
  enableNext() {
    if ((this.$form.controls.password.valid &&
      this.$form.controls.confirmPassword.valid)) {
      this.validPassword = true;
    }
    else {
      this.validPassword = false;
    }
  }
  setNudgh(fieldName: string) {
    this.enableNext();
    this.nudghInput[fieldName] = this.checkShowError(fieldName);
    setTimeout(() => {
      this.nudghInput[fieldName] = false;
    }, 1000);
  }
  checkShowError(fieldName: string): boolean {
    this.enableNext();
    return this.$form.get(fieldName).invalid
      && (this.$form.get(fieldName).touched || this.$form.get(fieldName).dirty);
  }
  changeVisiblity(event) {
    if (event.target.offsetWidth - event.offsetX < 40) {
      this.visibility = !this.visibility;
      this.passwordType = this.visibility ? 'text' : 'password';
    }
  }
  changePassword() {
    let isClicked = true;
    if (this.$form.invalid) {
      return;
    }

    this.state.changeState({
      ...this.stateData,
      code: this.pin,
      password: this.$form.get('password').value,
      validationMessage: ''
    });
    this.loadingService.isLoading.next(true);
    this.state.getState().subscribe(data => {
      if (!isClicked) {
        return;
      }
      if (data.completeForgetPassword) {
        this.router.navigate(['account-v1/reset-password/final']);
        return;
      }
      if (data.code && data.email && data.password) {
        Auth.forgotPasswordSubmit(data.email, data.code, data.password).then(result => {
          this.state.changeState({
            ...data,
            completeForgetPassword: true,
          });
          this.router.navigate(['account-v1/reset-password/final']);
          this.loadingService.isLoading.next(false);
          isClicked = false;

        }).catch(e => {
          this.loadingService.isLoading.next(false);
          this.validationError = this.cognitoError.getErrorMessage(e);
          this.state.changeState({
            ...data,
            validationMessage: this.validationError
          });
          if (this.validationError === 'No Internet Connection') {
            this.networkError = true;
            this.validationError = '';
            this.sessionsError.open().result.then((result: 'accept' | 'reject') => {
              if (result === 'reject') {
                return;
              }
              this.changePassword();
            });
          }
          this.loadingService.isLoading.next(false);
          this.router.navigate(['account-v1/reset-password/step1']);
          isClicked = false;
          this.networkError = false;

        });
      }
    });


  }
}

