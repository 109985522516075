import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MockSettingsRoutingModule } from './mock-settings-routing.module';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MockSettingsRoutingModule
  ]
})
export class MockSettingsModule { }
