import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ICon } from '../payment-container/payment-container.component';
import { Application, CreateReceiptRequest, CreateReceiptResponse, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { NavigationService } from 'shared/services/navigation.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserState } from 'store/user/user.reducer';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { DynamicScriptLoaderService } from 'shared/services/dynamic-script-loader.service';
import { BankalfalahPaymentService } from '@idp-education/ors-bankalfalah-payment-bff-client-v1';
import { LoadingService } from 'shared/services/loading-service.service';
import { SharedStateService } from 'pages/account/shared-state.service';
import { PaymentsService } from 'src/app/shared/services/payment.services';
import { CommonService } from 'shared/services/common.service';
const appSettings = require('./../../../../../assets/appSettings.json');
declare const Checkout: any;

@Component({
  selector: 'app-bank-alfalah',
  templateUrl: './bank-alfalah.component.html',
  styleUrls: ['./bank-alfalah.component.scss']
})
export class BankAlfalahComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() tcPaymentMethod = [];
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @Input() isNewJourney = false;
  postReceipt: Subscription;
  sub: Subscription;
  userState: UserState;
  receiptExpiredValue;
  receiptResponse: CreateReceiptResponse;  

  static get tab(): ICon {
    return {
      alt: 'bankAlfalah',
      key: 'BANKALFALAH',
      url: '/assets/images/payment/bank-alfalah.svg',
      class: '',
      title: 'Pay online with Visa and Mastercard',
      description: 'Secure payments powered by Bank Alfalah',
    };
  }
  constructor(
    private receiptService: ReceiptService,
    private navigationService: NavigationService,
    private scriptLoaderService: DynamicScriptLoaderService,
    private bankalfalahPaymentService: BankalfalahPaymentService,
    private loadingService: LoadingService,
    private sharedState:  SharedStateService,
    private store: Store<{ userStore }>,
    private paymentService: PaymentsService,
    private commonService: CommonService
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.userState = x;
    });
  }

  ngOnInit(): void {
    this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.BANKALFALAH) {
        this.openBankAlfalah();
      }
    }));
    this.createReceipt();
  }

  ngOnDestroy(): void {
    this.postReceipt?.unsubscribe();
    this.sub.unsubscribe();
  }

  createReceipt() {
    const tcPaymentMethodId = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.BANKALFALAH);
    const createReceiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: tcPaymentMethodId?.id
    };

    this.postReceipt = this.receiptService.createReceipt(this.commonService.appCorrelationId(), createReceiptRequest)?.subscribe(result => {
      this.receiptResponse = result;
      this.storeBookingInfo();
      if(!this.isNewJourney){
        this.openBankAlfalah();
      }
    }, (error) => {
      if(error?.includes('APPLICATION_EXPIRED')){
        this.receiptExpiredValue = this.paymentService.getErrorState();
      }
    });
  }

  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.BANKALFALAH);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
  }

  getScriptUrl(): string {
    return `https://${appSettings.site.bankAlfalahJSSubDomain}.gateway.mastercard.com/static/checkout/checkout.min.js`;
  }

  openBankAlfalah() {
    this.bankalfalahPaymentService.configuration.accessToken = this.userState?.userToken;
    this.bankalfalahPaymentService.paymentSession(this.commonService.appCorrelationId(), this.receiptResponse.id, this.applicationId).subscribe(result => {
      this.loadingService.increaseLoadingCounter();
      const localdata = JSON.parse(localStorage.getItem('bankAlfalahResult'));
      const sessionData = [{
        successIndicator: result.successIndicator,
        orderId: this.receiptResponse.receiptNumber
      }];
      if (localdata?.length > 0) {
        localdata.forEach(element => {
          sessionData.push(element);
        });
      }
      this.navigationService.storeBankAlfalahResult(sessionData);
      this.scriptLoaderService.loadBankAlfalahScript(this.getScriptUrl(), '', '')
        .then(() => {
          Checkout.configure(
            {
              session: { id: result.checkoutSessionId },
            }
          );
          Checkout.showPaymentPage();
          this.loadingService.decreaseLoadingCounter();
        });
    });
  }

  backToListingPage(): void {
    window.open(`${appSettings.site.bxsearchURL}/book-ielts`, '_self');
  }

}
