import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VeritransPaymentService } from '@idp-education/ors-veritrans-payment-bff-client-v1/api/veritransPayment.service';
import { PaymentCancelRequest } from '@idp-education/ors-veritrans-payment-bff-client-v1/model/paymentCancelRequest';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { PaymentStates } from 'pages/payment/payment.enum';
import { CommonService } from 'shared/services/common.service';
import { NavigationService } from 'shared/services/navigation.service';
@Component({
  selector: 'app-veritrans-callback',
  templateUrl: './veritrans-callback.component.html',
  styleUrls: ['./veritrans-callback.component.scss']
})
export class VeritransCallbackComponent implements OnInit {
  cancelAction: string;
  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private veritransService: VeritransPaymentService,
    private router: Router,
    private commonService: CommonService,
    private store: Store<{ userStore }>
  ) { }

  ngOnInit(): void {
    this.route?.queryParams.subscribe((data => {
      if (data) {
        this.cancelAction = data?.action;
      }
    }));
    try {
      Auth.currentSession().then(session => {
        const userToken = session.getAccessToken().getJwtToken();

        const paymentType = localStorage.getItem('paymentType');
        this.route.params.subscribe(params => {
          if (params?.status) {
            switch (params.status) {
              case 'success':
                this.navigationService.navigateOnPaymentType(paymentType);
                this.navigationService.storePaymentState(PaymentStates.COMPLETED);
                break;
              case 'failed':
                this.router.navigate(['/my-tests']);
                break;
              case 'cancelled': {
                const paymentCancelRequest: PaymentCancelRequest = {
                  action: this.cancelAction
                };
                this.veritransService.configuration.accessToken = userToken;
                this.veritransService.cancelVeritransPayment(this.commonService.appCorrelationId(), paymentCancelRequest).subscribe(data => {
                  if (data) {
                    this.router.navigate(['/my-tests']);
                  }
                });
                break;
              }
              default:
                this.router.navigate(['/my-tests']);
                break;
            }
          } else {
            this.router.navigate(['/my-tests']);
          }
        });
      });
    } catch (error) {
      this.router.navigate(['/my-tests']);
    }
  }
}
