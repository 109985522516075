import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { GtmTrackingService } from './gtm-tracking.service';
import { NavigationService } from './navigation.service';
import { combineLatest, switchMap, of, catchError, Observable, tap } from 'rxjs';
import { considerFbClickIds, setClickIdInCookie } from 'shared/utils/utm-params-utils';
const appSettings = require('src/assets/appSettings.json');
declare let dataLayer;
declare global {
  interface Window {
    dataLayer: any;
  }
}
@Injectable({
  providedIn: 'root'
})
export class OneTrustService {
  constructor(
    private readonly gtm: GtmTrackingService,
    private readonly cookie: CookieService,
    private mock : NavigationService  
  ) { }
  isGaScriptadded: boolean 
  isOnetrustscriptadded: boolean 
  
  getCookieValue(key: string): string {
    const cookies = document.cookie.split('; ');
    const cookieName = `${key}=`;
    for (const cookie of cookies) {
      if (cookie.startsWith(cookieName)) {
        return cookie.substring(cookieName?.length);
      }
    }
    return '';
  }

  denyGrantGATag() {
    const existingConsent = this.getCookieValue('OptanonConsent') || null;
    let existingConsentArray = existingConsent ? existingConsent.split('&groups=') : [];
    if (existingConsentArray?.length) {
      existingConsentArray =  decodeURIComponent(existingConsentArray[1]).split(',')
    }
    const consentGroups = { 
      'C0004:0': ['ad_storage', 'ad_user_data', 'ad_personalization'],
      'C0003:0': ['analytics_storage'],
      'C0002:0': ['functionality_storage'],
      'C0001:0': ['personalization_storage', 'security_storage']
    };
    const consentStatus = Object.entries(consentGroups).reduce((acc, [group, purposes]) => {
      const isGranted = !existingConsentArray.includes(group);
      purposes.forEach(purpose => acc[purpose] = isGranted ? 'granted' : 'denied');
      return acc;
    }, {});
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push('consent', 'default', {
      ...consentStatus,
      wait_for_update: 500
    });
    const scriptUrl = 'https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js';
    const scriptType = 'text/javascript';
    const charset = 'UTF-8';
    if (!this.isOnetrustscriptadded) {
      this.addexternalScript(scriptUrl, scriptType, charset, appSettings.site.onetrustCookieId);
    }
  }
  
  addexternalScript(scriptUrl: string, type: string, charset: string, dataDomainScript: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.setAttribute('type', type);
      script.setAttribute('charset', charset);
      script.setAttribute('data-domain-script', dataDomainScript);
      script.onload = () => {
        this.addGaScript();
        resolve();
      };
      script.onerror = (error) => {
        this.addGaScript();
        reject(console.info('Bx Error: Something went wrong'));
      };
      document.head.appendChild(script);
      this.isOnetrustscriptadded = true
    });
  }

  checkForQueryparamData(): boolean {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString); 
    const fbcFromQuery = urlParams.get('s2s_fbc');
    const fbpFromQuery = urlParams.get('s2s_fbp');
    const gaFromQuery = urlParams.get('s2s_ga');
    const gaContainerFromQuery = urlParams.get('s2s_gac');
  
    return !!(fbcFromQuery || fbpFromQuery || gaFromQuery || gaContainerFromQuery)
  }

  addGaScript() {
  if (!this.isGaScriptadded) {
      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){
        w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P2QKHDD');`
    document.head.appendChild(script)
   }
   this.isGaScriptadded = true
   this.mock.isFeatureEnabled$.subscribe((isFeatureEnabled) => {
    if (isFeatureEnabled) {
      if (this.checkForQueryparamData()) {
        setClickIdInCookie(this.cookie).subscribe();
        this.waitForGaUserId().subscribe({
          next: (gaUserId) => {
            this.gtm.setOrGetClientTracking();
          },
          error: (error) => {
            console.error('Error waiting for GA User ID:', error);
          }
        });
      } else {
        const gaUserId$ = this.waitForGaUserId();
        const cookiesSet$ = this.waitForCookies();
        combineLatest([gaUserId$, cookiesSet$]).pipe(
          switchMap(([gaUserId, cookiesSet]) => {
            if (gaUserId) {
              if (cookiesSet) {
                this.gtm.setOrGetClientTracking();
                setClickIdInCookie(this.cookie).pipe(
                  tap((isFromCookies) => {
                    if(isFromCookies){
                    considerFbClickIds(this.cookie)
                    }
                  })
                ).subscribe();
                return of('Success');
              } else {
                return of('Error: Missing Cookies');
              }
            } else {
              return of('Error: Missing GA User ID');
            }
          }),
          catchError((error) => {
            console.error('Error:', error);
            return of('Error');
          })
        ).subscribe({
          next: (message) => {
            console.log(message);
          },
          error: (err) => {
            console.error('Error Getting Scripts ids:', err);
          }
        });
      }
    }
  })
  }

  waitForGaUserId(): Observable<string> {
    return new Observable((observer) => {
      const intervalId = setInterval(() => {
        const ga4UserId = this.cookie.get('_ga');
        if (ga4UserId) {
          clearInterval(intervalId);
          observer.next(ga4UserId);
          observer.complete();
        }
      }, 100);
    });
  }

  waitForCookies(): Observable<boolean> {
    return new Observable((observer) => {
      const intervalId = setInterval(() => {
        const ga4UserId = this.cookie.get('_ga');
        const gaContainer = this.cookie.get(appSettings.site.gaContainer);
        if (ga4UserId && gaContainer) {
          const availableCookies: any = {};
          if (ga4UserId !== undefined && ga4UserId !== null) {
            availableCookies['_ga'] = ga4UserId;
          }
          if (gaContainer !== undefined && gaContainer !== null) {
            availableCookies[appSettings.site.gaContainer] = gaContainer;
          }
          clearInterval(intervalId);
          observer.next(true);
          observer.complete();
        }
      }, 500);
    });
  }
}
