<div class="popup-overlay" id="infoPopup" *ngIf="paymentErrorModal">
    <div class="popup-wrapper d-flex flex-column">
      <a class="mob-close d-flex d-lg-none justify-content-end" (click)="onCloseClick()" (keyup)="onCloseClick()" *ngIf="showCloseIcon"><img src="./assets/images/close-white.svg"
          alt="close-icon"></a>
      <div class="popup-inner-wrapper d-flex flex-column">
        <div *ngIf="imageUrl" class="popup-images d-flex align-items-center justify-content-between">
          <img class="popup-img"
            [src]="imageUrl"
            alt="visual">
          <a class="close d-none d-lg-flex" *ngIf="showCloseIcon"  (click)="onCloseClick()" (keyup)="onCloseClick()"><img src="./assets/images/close.svg"
              alt="close-icon"></a>
        </div>
        <div class="popup-inner-content d-flex flex-column">
          <h3 class="popup-heading">{{title}}</h3>
          <div class="popup-paragraphs d-flex flex-column open-sans">
           {{description}}
          </div>
        </div>
        <div class="popup-buttons d-flex flex-column flex-lg-row align-items-center">
          <button #btn1 class="popup-secondary-btn"
          *ngIf="showReject" (click)="onRejectClick()">{{rejectText}}</button>
          <button #btn2 class="popup-primary-btn text-center" (click)="onAcceptClick()">{{acceptText}}</button>
        </div>
      </div>
    </div>
  </div>
