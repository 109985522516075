import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Application, StripeForwardingCaptureRequest, StripeForwardingCaptureResponse, StripeForwardingCaptureStatus, StripeService } from '@idp-education/ors-test-taker-bff-client-v1';
import { Auth } from 'aws-amplify';
import { PaymentStates } from 'pages/payment/payment.enum';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { CommonService } from 'shared/services/common.service';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';

@Component({
  selector: 'app-stripe-callback',
  templateUrl: './stripe-callback.component.html',
  styleUrls: ['./stripe-callback.component.scss']
})
export class StripeCallbackComponent implements OnInit, OnDestroy {
  @ViewChild('confirmationModal') confirmationModal: ConfirmModalComponent;
  title: string;
  acceptText: string;
  applicationId: string;
  application: Application;
  userToken: string;
  paymentType: string;
  routerSub: Subscription;
  stripeSubscription: Subscription;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private stripePaymentService: StripeService,
    private loadingService: LoadingService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.loadingService.increaseLoadingCounter();
    Auth.currentSession().then(session => {
      this.paymentType = localStorage.getItem('paymentType');
      this.routerSub = this.activatedRoute.queryParamMap.subscribe(queryParams => {
        const authorizeActionId = queryParams.get('authorize_action_id')
        const paymentId = queryParams.get('payment_id');
        const paymentTransactionId = this.activatedRoute.snapshot?.paramMap.get('transactionId')
        const status = queryParams.get('status') as StripeForwardingCaptureStatus
        const stripeForwardingCaptureRequest: StripeForwardingCaptureRequest = {
          authorizeActionId,
          status,
          paymentId,
          paymentTransactionId
        };
        const userToken = session.getAccessToken().getJwtToken();
        this.stripePaymentService.configuration.accessToken = userToken;
        if (status?.toLowerCase() === 'succeed' || status?.toLowerCase() === 'success') {
          this.stripeSubscription = this.stripePaymentService.stripeForwardingCapture(stripeForwardingCaptureRequest, this.commonService.appCorrelationId()).subscribe((stripeForwardingCaptureResponse: StripeForwardingCaptureResponse) => {
            if (stripeForwardingCaptureResponse?.status) {
              switch (stripeForwardingCaptureResponse?.status) {
                case StripeForwardingCaptureStatus.SUCCESS:
                  this.loadingService.decreaseLoadingCounter();
                  this.navigationService.storePaymentState(PaymentStates.COMPLETED);
                  this.navigationService.navigateOnPaymentType(this.paymentType);
                  break;
                case StripeForwardingCaptureStatus.FAILED:
                  this.title = `Your current payment status is Failed, Please retry the payment.`;
                  this.acceptText = 'Retry Payment';
                  this.confirmationModal.open();
                  this.loadingService.decreaseLoadingCounter();
                  break;
                case StripeForwardingCaptureStatus.PENDING:
                  this.title = `You current payment is in progress please check your IELTS profile for the latest status or
                                   contact your test centre`;
                  this.acceptText = 'Return to My Account';
                  this.confirmationModal.open();
                  this.loadingService.decreaseLoadingCounter();
                  break;
                default:
                  this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
                  this.acceptText = 'Return to My Account';
                  this.confirmationModal.open();
                  this.loadingService.decreaseLoadingCounter();
                  break;
              }
            } else {
              this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
              this.acceptText = 'Return to My Account';
              this.confirmationModal.open();
              this.loadingService.decreaseLoadingCounter();
            }
          });
        } else {
          this.title = `Your current payment status is Failed or Pending. Please retry after sometime.`;
          this.acceptText = 'Retry Payment';
          this.confirmationModal.open();
          this.loadingService.decreaseLoadingCounter();
        }
      });
    });
  }

 
  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
    this.stripeSubscription?.unsubscribe();
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.confirmationModal.closeModal(true);
  }
}
