import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Store } from '@ngrx/store';
import { concatMap, first } from 'rxjs/operators';
const { featureFlags } = require('src/assets/appSettings.json');
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { Logout, setBackAction } from 'src/app/store/global.actions';
import { Title, Meta } from '@angular/platform-browser';
import { setFromLoginPage, setLogOut } from 'src/app/store/user/user.actions';
import { CognitoErrorService } from 'src/app/shared/services/cognito-error.service';
import { IOCSessionStorageItemKeys } from 'pages/payment/payment.enum';
import { CommonService } from 'shared/services/common.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  showLoading = false;
  fieldNames: string[];
  passwordType = 'password';
  visibility = false;
  validate = false;
  button: 'facebook' | 'google' | 'apple' | '' = '';
  $form: UntypedFormGroup;
  public featureFlags: any = featureFlags;
  isNotIOLProduct: any;

  passwordCustomMessage = {
    required: () => $localize`Password is required`,
    minLength: () => $localize`Password must be at least 8 characters`,
  };
  userNameCustomMessage = {
    required: () => $localize`Username is required`,
    email: () => $localize`Username must be an email address`,
    invalidLogin: (param) => `${param}`,
  };
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    public loadingService: LoadingService,
    private store: Store<{ bookingStore, globalStore }>,
    private userProfile: UserProfileService,
    private cognitoError: CognitoErrorService,
    private titleService: Title, private metaTagService: Meta,
    private commonService: CommonService
  ) {
    this.titleService.setTitle('Sign in to your IDP account | IDP IELTS');
    this.store.dispatch(setBackAction({ BackButtonEnable: true, BackButtonRoute: '/account' }));
    this.$form = this.defineForm();
  }
  protected defineForm() {
    return this.fb.group({
      userName: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      remembered: ''
    });
  }
  ngOnInit() {
    this.isNotIOLProduct = this.commonService.getSessionStorageValue(IOCSessionStorageItemKeys.isNotIOLProduct);
    if (this.isNotIOLProduct || this.isNotIOLProduct === '') {
      this.router.navigate(['/account/signin']);
    }
    this.metaTagService.updateTag(
      { name: 'description', content: 'Sign in to your IDP account to access your profile and check your IELTS Online test dates and test results.' },
    );
  }

  mouseOver(event: any) {
    if (event.target.offsetWidth - event.offsetX < 40) {
      event.target.style.cursor = 'pointer';
    } else {
      event.target.style.cursor = 'auto';
    }
  }
  onSubmit() {
    this.validate = true;
    if (this.$form.invalid && !this.$form.get('userName').errors.invalidLogin) {
      return false;
    }
    this.loadingService.isLoading.next(true);
    this.showLoading = true;
    Auth.signIn((this.$form.get('userName').value || '').trim(), this.$form.get('password').value)
      .then(() => {
        this.store.select((appStore) => appStore.bookingStore)
          .pipe(first(), concatMap((store) => {
            if (store) {
              return this.userProfile.getUserProfile(!!store?.latestPageUrl);
            }
          }))
          .subscribe({
            error: err => {
              if (err) {
                // To know whether landing is done from login page to create-booking page and to restrict direct routing to create-booking.
                this.store.dispatch(setFromLoginPage({ fromLoginPage: true }));
                localStorage.setItem('emailID', this.$form.get('userName').value);
                localStorage.setItem('fromLoginPage', JSON.stringify(true));
                this.router.navigate(['/create-booking']);
              }
            }, next: (data) => {
              this.store
                .select((appStore) => appStore.bookingStore)
                .pipe(first())
                .subscribe((x) => {
                  if (x.latestPageUrl) {
                    this.router.navigate([x.latestPageUrl]);
                  } else {
                    this.router.navigate(['/my-account']);
                  }
                  this.showLoading = false;
                });
            }
          });
      })
      .catch((err) => {
        this.showLoading = false;
        this.$form.controls.userName.setErrors({
          invalidLogin: this.cognitoError.getErrorMessage(err),
        });
      })
      .finally(() => {
        this.loadingService.isLoading.next(false);
      });
  }

  resetError() {
    this.$form.controls.userName.setErrors(null);
  }

  changeVisibility(event) {
    if (event.target.offsetWidth - event.offsetX < 40) {
      this.visibility = !this.visibility;
      this.passwordType = this.visibility ? 'text' : 'password';
    }
  }

  facebookLogin() {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
      .then((credentials) => {
        console.log(credentials);
      })
      .catch((error) => {
        console.error(`error signing in: `, error);
      });
  }
  AppleLogin() {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple })
      .then((credentials) => {
        console.log(credentials);
      })
      .catch((error) => {
        console.error('error signing in: ', error);
      });
  }
  googleLogin() {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    })
      .then((credentials) => {
        console.log(credentials);
      })
      .catch((error) => {
        console.error('error signing in: ', error);
      });
  }

  onHoverAction(button: 'facebook' | 'google' | 'apple') {
    this.button = button;
  }

  onBlur() {
    this.button = '';
  }
}
