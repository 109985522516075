import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  loadScript,
  PayPalNamespace,
  PurchaseItem,
} from '@paypal/paypal-js';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  first,
  pluck,
  tap,
} from 'rxjs/operators';
import { LoadingService } from './../../../services/loading-service.service';
import { IPaymentInterface, IPaymentMethods } from '../../../interfaces/payment.interface';
import { ICon } from '../payment-container/payment-container.component';
import {
  CreatePaymentResponse,
  PaymentService,
} from '@idp-education/ors-test-taker-bff-client-v1';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-paypal',
  template: `
    <ng-container>
      <div class="card">
        <div class="card-body" id="paypalWrapper"></div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./paypal.component.scss'],
})
export class PaypalComponent implements OnInit, OnChanges, IPaymentInterface {
  constructor(
    private loading: LoadingService,
    private paymentService: PaymentService,
    private commonService: CommonService
  ) {}
  public get visible(): boolean {
    return this._visible;
  }
  public set visible(v: boolean) {
    this._visible = v;
    if (v) {
      this.initPaymentCard();
    }
  }
  @Input() clientId;
  @Input() applicationId;
  @Input() profileId;
  @Input() applicationPaymentId;
  @Input() currency;
  @Input() purchaseItems: PurchaseItem[];
  @Input() purchaseTotal: number;

  @Output() onLoaded: EventEmitter<void> = new EventEmitter();
  @Output() onApproval: EventEmitter<{ id: string }> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Output() onValidationChange: EventEmitter<any> = new EventEmitter();

  paypal$: BehaviorSubject<PayPalNamespace> = new BehaviorSubject(null);
  icon: ICon = {
    alt: 'Paypal',
    key: 'PAYPAL',
    url: '',
    class: 'fa fa-cc-paypal fa-4x',
  };
  type: IPaymentMethods = 'PAYPAL';
  title = 'Paypal';
  static get tab(): ICon {
    return {
      alt: 'Paypal',
      key: 'PAYPAL',
      url: '',
      class: 'fa fa-paypal',
      title: 'Pay online with PayPal',
      description: 'Secure payments powered by PayPal',
    };
  }
  private _visible = true;
  initPaymentCard(type?: IPaymentMethods): Observable<void> | void {
    this.paypal$
      ?.pipe(
        tap(() => this.loading.increaseLoadingCounter()),
        filter((i) => !!i),
        concatMap((data) => this.renderBtn$(data)),
        first(),
        catchError((err) => {
          this.loading.resetLoadingCounter();
          return throwError((e) => e);
        })
      )
      ?.subscribe(
        () => {
          this.loading.decreaseLoadingCounter();
          this.onLoaded.emit();
        },
        (err) => {
          this.onError.emit(err);
          this.loading.resetLoadingCounter();
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const clientId = this.clientId || changes.clientId.currentValue;
    const currency = this.currency || changes.currency.currentValue;
    const purchaseItems =
      this.purchaseItems || changes.purchaseItems.currentValue;
    const purchaseTotal =
      this.purchaseTotal || changes.purchaseTotal.currentValue;
    if (!this.applicationId || !this.profileId || !this.clientId) {
      return;
    }
    if (clientId && currency && purchaseItems && purchaseTotal) {
      this.loadPaypal(this.clientId, currency);
    }
  }

  ngOnInit(): void {
    if (!this.visible) {
      return;
    }
    this.initPaymentCard();
  }

  loadPaypal(clientId, currency) {
    this.loading.increaseLoadingCounter();
    const resetLoading = () => this.loading.decreaseLoadingCounter();
    loadScript({
      'clientId': clientId,
      'disableFunding': 'card,paylater,mybank',
      currency,
    })
      .then((data) => {
        this.paypal$.next(data);
        this.loading.decreaseLoadingCounter();
      })
      .catch((err) => {
        resetLoading();
        this.onError.emit(err);
      })
      .finally(resetLoading);
  }

  private renderBtn$(paypal: PayPalNamespace) {
    let errorIsEmitted = false;
    return from(
      paypal
        .Buttons({
          style: {
            label: 'pay',
            layout: 'vertical',
            color: 'blue',
          },
          createOrder: (data, actions) => this.createOrder(data, actions),
          // Finalize the transaction after payer approval
          onApprove: (data, actions) => {
            const createPayment: Observable<CreatePaymentResponse> =
              this.paymentService.createPayment({
                gatewayPayment: {
                  paypalPayment: {
                    orderId: data.orderID,
                  },
                },
                paymentMethod: 'PAYPAL',
                paymentSourceId: {
                  applicationId: this.applicationId,
                  applicationPaymentId: this.applicationPaymentId,
                  userProfileId: this.profileId,
                },
              }, this.commonService?.appCorrelationId());
            return new Promise((resolve, reject) => {
              createPayment.pipe(first()).subscribe(
                (items) => {
                  this.onApproval.emit({ id: items.id });
                  resolve();
                },
                (err) => {
                  errorIsEmitted = true;
                  this.onError.emit(err);
                  reject();
                }
              );
            });
          },
          onError: (err) => {
            if (!errorIsEmitted) {
              this.onError.emit(err);
            } else {
              errorIsEmitted = false;
            }
          },
          onInit: () => { /* empty */ },
        })
        .render('#paypalWrapper')
    );
  }
  createOrder(data, actions) {
    // Sets up the transaction when a payment button is clicked
    const order = this.paymentService
      .createOrder({
        paymentMethod: 'PAYPAL',
        paymentSourceId: {
          applicationId: this.applicationId,
          applicationPaymentId: this.applicationPaymentId,
          userProfileId: this.profileId,
        },
      }, this.commonService?.appCorrelationId())
      .pipe(pluck('gatewayResponse', 'paypalResponse', 'id'))
      .pipe(first());
    return order.toPromise();
  }
}
