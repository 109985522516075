import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedStateService } from './shared-state.service';
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordStep1CanActivateService  {

  constructor(private state: SharedStateService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.state.getState().pipe(map((data) => {
      if (data.email) {
        return true;
      }
      return this.router.parseUrl('/account-v1/reset-password');
    }));
  }
}
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordStep2CanActivateService  {

  constructor(private state: SharedStateService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.state.getState().pipe(map((data) => {
      if (data.email || data.code) {
        return true;
      }
      return this.router.parseUrl('/account-v1/reset-password/step1');
    }));
  }
}

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordFinalStepCanActivateService  {

  constructor(private state: SharedStateService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.state.getState().pipe(map((data) => {
      if (data.completeForgetPassword) {
        return true;
      }
      return this.router.parseUrl('/account-v1/reset-password/step2');
    }));
  }
}
