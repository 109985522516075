<div class="card" *ngIf="!isNewJourney">
  <div class="card-body">
    <button (click)="onSubmit()" class="btn btn-payAction">
      Pay with Paystack
    </button>
  </div>
</div>

<app-payment-v2-popup *ngIf="receiptExpiredValue?.isReceiptExpiredError" [paymentErrorModal]="'receiptError'" [title]="receiptExpiredValue?.receiptExpiredTitle"
  [description]="receiptExpiredValue?.receiptExpiredDescription" [acceptText]="'Okay'" [showCloseIcon]="false" (onAcceptClicked)="backToListingPage()" imageUrl="">
</app-payment-v2-popup>