import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MockSettingsComponent } from './mock-settings.component';

const routes: Routes = [{
  path: '',
  component: MockSettingsComponent,
  data: { pagename: 'Mock Settings' }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MockSettingsRoutingModule { }
