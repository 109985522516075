import { CookieService } from "ngx-cookie";
import { Observable } from "rxjs";

const appSettings = require('src/assets/appSettings.json');
export const generateUtmUrl = (url, code?): string => {
    if (!sessionStorage.getItem('utm_value')) {
        setUtminSession();   
    }
    if (!getCookie('click_id_value')) {
        setClickIdInCookie().subscribe();   
    }
    const url_List = {
        cxUrl: 'https://ielts.idp.com',
        orsUrl: 'https://ielts.idp.com/book',
        cxPreparationUrl: 'https://ielts.idp.com/prepare/ielts-test-preparation-practice-tests-material',
        cxBandscoreUrl: 'https://ielts.idp.com/results/how-ielts-is-marked/band-score-calculator',
        cxSystemRequirements: 'https://ielts.idp.com/lp/ielts-online-system-requirements',
        bxSearchWizardUrl: 'https://bxsearch.ielts.idp.com/wizard',
        legalNoticeURLPrivacy: 'https://ielts.idp.com/legal-notices#ielts-online-privacy-notice',
        legalNoticeAusUrl: 'https://ielts.idp.com/legal-notices#ielts-australia-privacy-policy',
        legalNoticeTnc: 'https://ielts.idp.com/legal-notices#ielts-test-and-payment-terms-and-conditions',
        legalNotice: 'https://ielts.idp.com/legal-notices',
        bxInstructionURL: `https://ielts.idp.com/test-centres/${code}/offline-payment-instructions`
    }
    const utm_value = sessionStorage.getItem('utm_value');
    const clickIdValue = getCookie('click_id_value') ? decodeURIComponent(getCookie('click_id_value')) : '';
    let baseUrl = '';
    if (typeof url === 'string' && url.includes('://')) {
        baseUrl = url;
    } else if (url_List[url]) {
        baseUrl = url_List[url];
    } else {
        baseUrl = '';
    }
    const appendQueryParams = (url: string, params: string): string => {
        if (url.includes('?')) {
            return url + '&' + params;
        } else {
            return url + '?' + params;
        }
    };
 
    let queryParams = '';
    if (utm_value) {
        queryParams += `&${utm_value.substring(1)}`;
    }

    if (clickIdValue && baseUrl !== url_List.orsUrl) {
        queryParams += `&${clickIdValue.substring(1)}`;
    }

    if (queryParams) {
        baseUrl = appendQueryParams(baseUrl, queryParams.substring(1));
    }

    return baseUrl;
};

export const setUtminSession = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utm_url = formUtmparams(urlParams, 'u_id', 'utm_id') +
        formUtmparams(urlParams, 'u_src', 'utm_source') +
        formUtmparams(urlParams, 'u_mdm', 'utm_medium') +
        formUtmparams(urlParams, 'u_trm', 'utm_term') +
        formUtmparams(urlParams, 'u_cmp', 'utm_campaign') +
        formUtmparams(urlParams, 'u_cnt', 'utm_content');
    if (!sessionStorage.getItem('referrerUrl')) {
        sessionStorage.setItem('referrerUrl', (urlParams.get('referrerUrl') || document.referrer || document.location.origin)) 
    }  
    if (utm_url) {   
        sessionStorage.setItem('utm_value', utm_url);
    }
};
export const setClickIdInCookie = (cookieService?: CookieService): Observable<any> => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    return new Observable(observer => {
        getClickIdValue(cookieService, urlParams).subscribe(({ clickId, isFromCookies }) => {
            if (clickId) {
                cookieService?.put('click_id_value', clickId);
            }
            observer.next({ clickId, isFromCookies });
            observer.complete();
        });
    });
};


export const getClickIdValue = (cookieService?: CookieService, urlParams?: URLSearchParams): Observable<any> => {
    return new Observable(observer => {
    let isFromCookies = false;
    const fbcFromQuery = urlParams?.get('s2s_fbc');
    const fbpFromQuery = urlParams?.get('s2s_fbp');
    const gaFromQuery = urlParams?.get('s2s_ga');
    const gaContainerFromQuery = urlParams?.get('s2s_gac');
    if (!cookieService?.get('click_id_value')) {
        if (isQueryParamsValid(fbcFromQuery, fbpFromQuery, gaFromQuery, gaContainerFromQuery)) {
            const clickIds = formClickIds(urlParams, null, 's2s_fbc') +
                formClickIds(urlParams, null, 's2s_fbp') +
                formClickIds(urlParams, null, 's2s_ga') +
                formClickIds(urlParams, null, 's2s_gac');
                observer.next({ clickId: clickIds, isFromCookies: false });
        } else {
            isFromCookies = true;
            const clickIdFromCookies = getClickIdFromCookies(cookieService);
            observer.next({ clickId: clickIdFromCookies, isFromCookies });
        }
    }
    else {
        observer.next(null);
    }
    observer.complete();

})
}

export const isQueryParamsValid = (fbc: string | null, fbp: string | null, ga: string | null, gaContainer: string | null) => {
    return fbc || fbp || ga || gaContainer;
}

export const getClickIdFromCookies = (cookieService?: CookieService) => {
    const fbcFromCookie = cookieService?.get('_fbc');
    const fbpFromCookie = cookieService?.get('_fbp');
    const gaFromCookie = cookieService?.get('_ga');
    const gaContainerFromCookie =cookieService?.get(appSettings.site.gaContainer);
    const gaFromCookieExtracted = gaFromCookie ? extractGaValue(gaFromCookie) : null;
    const gaContainerFromCookieExtracted = gaContainerFromCookie? extractGaConatinerValue(gaContainerFromCookie) : null;

    return formClickIds(null, fbcFromCookie, 's2s_fbc') +
        formClickIds(null, fbpFromCookie, 's2s_fbp') +
        formClickIds(null, gaFromCookieExtracted, 's2s_ga') +
        formClickIds(null, gaContainerFromCookieExtracted, 's2s_gac');
}

export const considerFbClickIds = (cookieService: CookieService): void=> {
        const intervalId = setInterval(() => {
            const fbp = cookieService.get('_fbp');
            const fbc = cookieService.get('_fbc');

            if (fbp || fbc) { 
                updateClickIdWithParams(cookieService, fbp, fbc); 
            }

            if (fbp && fbc) { 
                clearInterval(intervalId);
            }
        }, 100);
};

export const updateClickIdWithParams = (cookieService: CookieService, fbp?: string, fbc?: string): void => {
    let clickIdUrl = cookieService.get('click_id_value') || '';
    const existingParams = new Set(clickIdUrl.split('&'));

    if (fbc && !existingParams.has(`s2s_fbc=${fbc}`)) {
        existingParams.add(`s2s_fbc=${fbc}`);
    }

    if (fbp && !existingParams.has(`s2s_fbp=${fbp}`)) {
        existingParams.add(`s2s_fbp=${fbp}`);
    }

    clickIdUrl = Array.from(existingParams).join('&');
    cookieService.put('click_id_value', clickIdUrl);
};




export const formClickIds = (urlParams, dataFromCookie, encode_clickid_value) => {
    const clickIdFromUrl =   urlParams ?  decodeURIComponent(urlParams.get(encode_clickid_value)): null;
    if (clickIdFromUrl && clickIdFromUrl !== 'null') {
        return '&' + encode_clickid_value + '=' + clickIdFromUrl;
    }
    if (dataFromCookie) {
        const clickIdFromCookie = decodeURIComponent(dataFromCookie);
        if (clickIdFromCookie && clickIdFromCookie !== 'null') {
            return '&' + encode_clickid_value + '=' + clickIdFromCookie;
        }
    }
    return '';
};


export const formUtmparams = (urlParams, encode_utm_value, utm_value) => {
    const utm_paranm = decodeURIComponent(urlParams.get(encode_utm_value) || decodeURIComponent(urlParams.get(utm_value)));
    return (utm_paranm && utm_paranm != 'null') ? '&' + encode_utm_value + '=' + utm_paranm : '';
};

export const  extractGaValue = (gaCookieValue: string)=> {
    if (!gaCookieValue) return null;
    const parts = gaCookieValue.split('.');
    if (gaCookieValue.startsWith('GA1.1.') && parts.length >= 4) {
      return parts[2] + '.' + parts[3];
    }
    return null;
  }

  export const  extractGaConatinerValue = (gaCookieValue: string)=> {
    if (!gaCookieValue) return null;
    const parts = gaCookieValue.split('.');
    if (gaCookieValue.startsWith('GS1.1.') && parts.length >= 3) {
      return parts[2];
    }

    return null;
  }


  export const getCookie=(cookieStr: string): string | null =>{
    const cookieName = `${cookieStr}=`;
    const cookieDetails = document.cookie?.split(';');
    for (let i = 0; i < cookieDetails?.length; i++) {
      const c = cookieDetails[i]?.trim();
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return null;
  }

export enum UtmLinkKeys {
    CxUrl = 'cxUrl',
    OrsUrl = 'orsUrl',
    CxPreparationUrl = 'cxPreparationUrl',
    CxBandscoreUrl = 'cxBandscoreUrl',
    CxSystemRequirements = 'cxSystemRequirements',
    BxSearchWizardUrl = 'bxSearchWizardUrl',
    legalNoticeURLPrivacy = 'legalNoticeURLPrivacy',
    LegalNoticeAusUrl = 'legalNoticeAusUrl',
    LegalNoticeTnc = 'legalNoticeTnc',
    LegalNotice = 'legalNotice',
    BxInstructionUrl = 'bxInstructionURL'
}

