import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DATE_REGEXP, NAME_REGEXP, SPL_CHAR_REGEXP, WHITESPACE_REGEXP } from '../sharedRegex';
import { DateTime } from 'luxon';
import { USER_SIGNUP_CONSTANTS_V2 } from 'src/app/pages/account/signup/signup.constants';
import { ConfirmationType } from '@idp-education/ors-test-taker-bff-client-v1';

export function nameValidator(formGroup: UntypedFormGroup) {
  const firstName = formGroup.controls['firstName'];
  const lastName = formGroup.controls['lastName'];

  const isFirstnameValid = NAME_REGEXP.test(firstName.value);
  const isLastNameValid = NAME_REGEXP.test(lastName.value);

  if (!firstName.value.length && !lastName.value.length) {
    firstName.setErrors({ firstRLastNameRequired: true });
  } else if (firstName.value.length && firstName.value.length < 2) {
    firstName.setErrors({ minlength: { requiredLength: 2 } });
  } else if (firstName.value.length && !isFirstnameValid) {
    firstName.setErrors({ nameInvalid: true, singleHyphen: true });
  } else if (firstName.value.length && firstName.value.length > 100) {
    firstName.setErrors({ maxlength: { requiredLength: 100 } });
  } else {
    firstName.setErrors(null);
  }

  if (!firstName.value.length || lastName.value.length) {
    if (lastName.value.length  && lastName.value.length < 1) {
      lastName.setErrors({ minlength: { requiredLength: 1 } });
    } else if (lastName.value.length && !isLastNameValid) {
      lastName.setErrors({ nameInvalid: true, singleHyphen: true });
    } else if (lastName.value.length && lastName.value.length > 100) {
      lastName.setErrors({ maxlength: { requiredLength: 100 } });
    } else {
      lastName.setErrors(null);
    }
  } else {
    lastName.setErrors(null);
  }
  return null;
}

export function expiryDateValidator(formGroup: UntypedFormGroup) {
  const expiryDateControl = formGroup.controls['expiryDate'];
  const expiryDate = formGroup.controls['expiryDate'].value;
  const hasIdImage = formGroup.controls['idImage'].value;
  const identityType = formGroup.controls['identityType'].value;
  const dateTime = DateTime.fromFormat(expiryDate || '', 'd/M/yyyy');
  let error = null;
  if (expiryDate?.length > 0 && expiryDate?.length < 10) {
    return expiryDateControl.setErrors({ dateLengthMatch: true });
  } else {
    if ((!expiryDate && identityType?.option?.code === 'P') ||
       (!expiryDate && identityType === USER_SIGNUP_CONSTANTS_V2.identificationTypeId)) {
      error = { required: true };
      expiryDateControl.setErrors(error);
    } else if ((expiryDate && !expiryDateControl.hasError('required')) && !DATE_REGEXP.test(expiryDate)) {
      error = { date: true };
      expiryDateControl.setErrors(error);
    } else if ((expiryDate && !expiryDateControl.hasError('required')) && !(dateTime.isValid && dateTime > DateTime.now().startOf('day'))) {
      error = { invalidExpireDate: true };
      expiryDateControl.setErrors(error);
    } else if ((expiryDate === '31/12/2099') && !hasIdImage) {
      error = { reviewRequired: true };
      expiryDateControl.setErrors(error);
      expiryDateControl.markAsTouched();
    } else {
      expiryDateControl.setErrors(error);
    }
  }
}

export function under16YrsOldValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) { return null; }

    const bdDate = DateTime.fromFormat(control.value || '', 'd/M/yyyy');
    const years = DateTime.now().diff(bdDate, 'years').years;
    // don't display error if the years <= 0 since the date is more than today
    // it display separate error.
    return years > 0 && years < 16 ? { under16YrsOld: true } : null;
  };
}

export function invalidDateValidator(format = 'd/M/yyyy'): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) { return null; }

    const bdDate = DateTime.fromFormat(control.value || '', format);
    const today = DateTime.now();

    return bdDate.startOf('day') > today.startOf('day') ? { date: true } : null;
  };
}

export function invalidExpiryDateValidator(format = 'd/M/yyyy'): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) { return null; }
    const expiryDate = DateTime.fromFormat(control.value || '', format);
    const today = DateTime.now();
    const isInvalid = expiryDate.startOf('day') <= today.startOf('day');
    return isInvalid ? { invalidExpireDate: true } : null;
  };
}

export function namePatternValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) { return null; }

    return !NAME_REGEXP.test(control.value) ? { nameInvalid: true, singleHyphen: true } : null;
  };
}

export function noWhitespaceValidator(control: UntypedFormControl) {
  const hasWhiteSpace = (control.value || '').match(WHITESPACE_REGEXP);
  const isValid = !hasWhiteSpace;
  return isValid ? null : { noSpace : true };
}

export function noSpecialCharactersValidator(control: UntypedFormControl) {
  const hasSplChars = (control.value || '').match(SPL_CHAR_REGEXP);
  const isValid = !hasSplChars;
  return isValid ? null : { noSpace : true };
}

export function addressDefaultValueValidator(control: UntypedFormControl) {
  const addressValue = control.value;
  const isValid = (addressValue !== USER_SIGNUP_CONSTANTS_V2.streetAddress1) ? true : false;
  if (!isValid) {
    control.markAsTouched();
  }
  return isValid ? null : { reviewRequired: true };
}

export function countryDefaultValueValidator(control: UntypedFormControl) {
  const countryValue = control.value;
  let isValid
  if (typeof countryValue === 'object' && 'Id' in countryValue) {
    isValid = countryValue.Id !== USER_SIGNUP_CONSTANTS_V2.countryId ? true : false;
  } else {
    isValid = countryValue !== USER_SIGNUP_CONSTANTS_V2.countryId ? true : false;
  }
  if (!isValid) {
    control.markAsTouched();
  }
  return isValid ? null : { reviewRequired: true };
}

export function nationalityDefaultValueValidator(control: UntypedFormControl) {
  const nationalityValue = control.value;
  const isValid = (nationalityValue !== USER_SIGNUP_CONSTANTS_V2.nationalityId) ? true : false;
  if (!isValid) {
    control.markAsTouched();
  }
  return isValid ? null : { reviewRequired: true };
}

export function statusValidator(formGroup: UntypedFormGroup) {
  const formControlsArr = [
    { formControlKey: 'intendLocation', constantKey: 'countryApplyingToId', otherKey: 'countryApplyingToOther' },
    { formControlKey: 'firstLanguage', constantKey: 'languageId', otherKey: 'languageOther' },
    { formControlKey: 'occupationLevel', constantKey: 'occupationLevelId', otherKey: 'occupationLevelOther' },
    { formControlKey: 'occupationSector', constantKey: 'occupationSectorId', otherKey: 'occupationSectorOther' },
    { formControlKey: 'reasonTest', constantKey: 'testReasonId', otherKey: 'testReasonOther' },
    { formControlKey: 'educationLevel', constantKey: 'educationLevelId ', otherKey: '' },
    { formControlKey: 'yearsStudyingEnglish', constantKey: 'yearsOfStudy ', otherKey: '' }
  ];
  const hasDefaultValues = formControlsArr.find((fc) => {
    return (formGroup.get(fc.formControlKey).value?.extendValue?.addNewOptionsId === USER_SIGNUP_CONSTANTS_V2[fc.constantKey] &&
      (fc.otherKey && formGroup.get(fc.formControlKey).value?.label === USER_SIGNUP_CONSTANTS_V2[fc.otherKey]));
  });
  formControlsArr.forEach((fc) => {
    if (formGroup.get(fc.formControlKey).value?.extendValue?.addNewOptionsId === USER_SIGNUP_CONSTANTS_V2[fc.constantKey]
      && (fc.otherKey && formGroup.get(fc.formControlKey).value?.label === USER_SIGNUP_CONSTANTS_V2[fc.otherKey]) && hasDefaultValues) {
      formGroup.controls[fc.formControlKey].setErrors({ reviewRequired: true });
    }
  });
}

export function dateLengthValidator(formGroup: UntypedFormGroup) {
  const birthDate = formGroup?.controls['birthDate'];
  if (birthDate?.value?.length > 0 && birthDate?.value?.length < 10) {
    return birthDate.setErrors({ dateLengthMatch: true });
  }
}
export function birthdateLengthValidator(control: UntypedFormControl) {
  const birthDateControl = control.value;
  return (birthDateControl?.length > 0 && birthDateControl?.length < 10) ? { dateLengthMatch: true } : null;
}

export const conditionalValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const fields = [
    'overall',
    'listening',
    'reading',
    'writing',
    'speaking'
  ];
  const confirmationStatus = formGroup.get('confirmationStatus')?.value;
  let hasError = false;

  if ((confirmationStatus?.key as ConfirmationType) === 'CONDITIONAL' || (confirmationStatus?.Id as ConfirmationType) === 'CONDITIONAL') {
    fields.forEach(item => {
      const control = formGroup.get(item);
      if (!control?.value) {
        control?.setErrors({ required: true });
        hasError = true;
      } else {
        control.setErrors(null);
      }
    });
  } else {
    fields.forEach(item => {
      const control = formGroup.get(item);
      control?.setErrors(null);
    });
  }

  return hasError ? { conditionalError: true } : null;
};
