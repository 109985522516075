export const gtmConst = {
    eventName: {
      events: 'Events',
      eventsGa4: 'events_ga4',
      pagenav: 'Pagenav'
    },
    category: {
        proceedWithEmail: 'Proceed with email',
        createPassword: 'Create Password',
        signin: 'Signin',
        saveAndContinue: 'Save and Continue',
        confirmAndProceedWithPayment: 'Confirm and proceed with payment',
        payOnline: 'Pay Online',
        payOffline: 'Pay Offline',
        reserveOfflineBooking: 'Reserve offline booking',
        completeProfile: 'Complete Profile',
        braintreePayment: 'Braintree Payment',
        stripePayment: 'Stripe Payment',
        paypalPayment: 'Paypal Payment',
        newebpayConvenientStorePayment: 'Newebpay Convenient Store Payment',
        newebpayCardPayment: 'Newebpay Card Payment',
        veritransConvenientStorePayment: 'Veritrans Convenient Store Payment',
        veritransCardPayment: 'VeriTrans Card Payment',
        hsbcWechatpayPayment: 'HSBC Wechatpay Payment',
        hssbcAlipayPayment: 'HSBC Alipay Payment',
        hsbcCardPayment: 'HSBC Card Payment',
        iniPayBankTransfer: 'IniPayBankTransfer',
        inipayConvenientStorePayment: 'Inipay Convenient Store Payment',
    },
    pageName: {
        enterEmail: 'Enter Email',
        signup: 'Signup',
        signin: 'Signin',
        accountSetup: 'Account Setup',
        bookingSummary: 'Booking Summary',
        paymentOptions: 'Payment Options',
        offlinePaymentMethods: 'Offline Payment Methods',
        onlinePaymentMethods: 'Online Payment Methods',
        incompleteProfile: 'Incomplete Profile',
        paymentConfirmation: 'Payment Confirmation',
        bookingSummaryPage: 'Booking Summary Page',
        bXPaymentOptionsPage: 'BX Payment Options Page',
        bXOnlinePaymentPage: 'BX Online Payment Page',
        bXOfflinePaymentPage: 'BX Offline Payment Page',
        bXPaymentConfirmation: 'BX Payment Confirmation'
    },
    common: {
        na: 'na',
        english: 'English',
        anonymous: 'anonymous',
        loggedIn: 'logged in',
        loggedOut: 'logged out',
        v2: 'V2',
        no: 'N',
        yes: 'Y',
        ieltsTT: 'IELTS TT',
        IELTS_Desktop_Sites: 'IELTS_Desktop_Sites',
        null: 'null',
        userUuid: 'user_uuid'
    },
    websiteName: {
        bookingExperience: 'Booking Experience'
    },
    actionType: {
        engagement: 'Engagement',
        interaction: 'Interaction'
    },
    eventType: {
        pageViewed: 'PageViewed',
        buttonClicked: 'ButtonClicked'
    },
    sectionName: {
        bookingSummaryPage: 'Booking Summary Page',
        confirmBooking: 'Confirm Booking',
        editSession: 'Edit Session',
        back: 'Back',
        paymentOptions: 'Payment Options',
        paymentMethods: 'Payment Methods',
        proceedToPayment: 'Proceed to Payment',
        reserveNow: 'Reserve now',
        offlinePaymentConfirmed: 'Offline Payment Confirmed',
        onlinePaymentConfirmed: 'Online Payment Confirmed'
    },
    functionalityName: {
        bookingSummary: 'BookingSummary',
        paymentOptions: 'PaymentOptions',
        onlinePayments: 'OnlinePayments',
        offlinePayments: 'OfflinePayments',
        onlinePaymentConfirmed: 'OnlinePaymentConfirmed',
        offlinePaymentConfirmed: 'OfflinePaymentConfirmed'
    },
    ctaTitle: {
        confirmAndProceedWithPayment: 'Confirm and proceed with payment',
        editSpeakingSession: 'Edit Speaking Session',
        editLRWSession: 'Edit LRW Session',
        back: 'Back',
        na: 'na',
        payOnline: 'Pay online',
        payOfflineNowToday: 'Pay Offline now today',
        reserveNow: 'Reserve now'
    }
}
  