import { setOfflinePayment } from '../../pages/payment/store/payment.actions';
import { concatMap, first, map } from 'rxjs/operators';
import { TestCentreService } from '@idp-education/ors-test-taker-bff-client-v1/api/testCentre.service';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { Application, PaymentMethod, ReceiptService, TestLocationResponse } from '@idp-education/ors-test-taker-bff-client-v1';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { PaymentGateways } from 'pages/payment/payment.enum';
import { CommonService } from 'shared/services/common.service';

const testCentre$ = new BehaviorSubject<string | undefined>(undefined);
export const testCentreCode$: Observable<string> = testCentre$.asObservable();

export const initializePaymentMethod = (
  store: any,
  isNotIOLProduct: boolean,
  locationId: string,
  testCentreService: TestCentreService,
  receiptService: ReceiptService,
  testCentreCode?: string,
  isOSRorEORProduct?: boolean,
  commonService?: CommonService,
): Observable<{ paymentMethod: IPaymentMethods[], location?: TestLocationResponse, testcentrePaymentMethods?: any[] }> => {
  testCentre$.next(testCentreCode);
  store.dispatch(setOfflinePayment({
    isOffline: false
  }));
  if (isNotIOLProduct) {
    // If not paying for IOL product, call get test location api
    // to list all payment methods supported in this test location
    const name = [];
    // If not paying for IOL product, call get test location api
    // to list all payment methods supported in this test location
    return testCentreService.getTestLocation(locationId, commonService?.appCorrelationId()).pipe(first(), concatMap(location => {
      return receiptService.getTestCentrePaymentMethods(location?.testCentreId, commonService?.appCorrelationId()).pipe(map((testcentrePaymentMethods) => {
        testcentrePaymentMethods?.filter(method => {
          // Added condition to check for disallowed location for test centre
          // Currently added for Paystack, Bank of Alfalah, BRAC, eSewa, PayDollar, NewebPay and INIpay
          const gateWays = [
            PaymentGateways.PAYSTACK,
            PaymentGateways.BANKALFALAH,
            PaymentGateways.BRAC,
            PaymentGateways.ESEWA,
            PaymentGateways.PAYDOLLAR,
            PaymentGateways.NEWEBPAY,
            PaymentGateways.INIPAY,
            PaymentGateways.HSBCOMNI
          ] as string[];
          if (gateWays.includes(method?.paymentMethod?.code)) {
            if (method?.disallowedTestLocationIds?.find(x => x === locationId)) {
              return;
            }
          }

          // ignore OFFLINE when product is OSR or EOR
          if (
            isOSRorEORProduct &&
            method.paymentMethod.code === PaymentMethod.OFFLINE
          ) {
            return;
          }

          name.push(method?.paymentMethod?.code);
        });
        const result: any[] = location?.configuration?.paymentMethods.concat(name) || [];
        return { paymentMethod: result, location, testcentrePaymentMethods };
      }));
    }));
  } else {
    // If paying for IOL product, default payment method to credit card
    return of({ paymentMethod: ['CREDIT_CARD'] as IPaymentMethods[] });
  }
};

export const initializePaymentMethodV2 = ({
  store,
  isNotIOLProduct,
  locationId,
  testCentreService,
  receiptService,
  testCentreCode,
  isOSRorEORProduct,
  isFromOffline,
  isNewOfflinePaymentEnabled,
  commonService
}: {
  store: any;
  isNotIOLProduct: boolean;
  locationId: string;
  testCentreService: TestCentreService;
  receiptService: ReceiptService;
  testCentreCode?: string;
  isOSRorEORProduct?: boolean;
  isFromOffline?: boolean;
  isNewOfflinePaymentEnabled?: boolean;
  commonService?: CommonService;
}): Observable<{
  paymentMethod: IPaymentMethods[];
  location?: TestLocationResponse;
  testcentrePaymentMethods?: any[];
}> => {
  testCentre$.next(testCentreCode);
  store.dispatch(
    setOfflinePayment({
      isOffline: false,
    })
  );

  if (!isNotIOLProduct) {
    // If paying for IOL product, default payment method to credit card
    return of({ paymentMethod: ['CREDIT_CARD'] as IPaymentMethods[] });
  }

  // If not paying for IOL product, call get test location api
  // to list all payment methods supported in this test location
  const name = [];
  // If not paying for IOL product, call get test location api
  // to list all payment methods supported in this test location
  const gateWays = [
    PaymentGateways.PAYSTACK,
    PaymentGateways.BANKALFALAH,
    PaymentGateways.BRAC,
    PaymentGateways.ESEWA,
    PaymentGateways.PAYDOLLAR,
    PaymentGateways.NEWEBPAY,
    PaymentGateways.INIPAY,
    PaymentGateways.HSBCOMNI,
  ] as string[];

  return testCentreService.getTestLocation(locationId, commonService?.appCorrelationId()).pipe(
    first(),
    concatMap((location) =>
      forkJoin([
        of(location),
        receiptService.getTestCentrePaymentMethods(location?.testCentreId, commonService?.appCorrelationId()),
      ])
    ),
    first(),
    map(([location, testcentrePaymentMethods]) => {
      testcentrePaymentMethods?.forEach((method) => {
        // Added condition to check for disallowed location for test centre
        // Currently added for Paystack, Bank of Alfalah, BRAC, eSewa, PayDollar, NewebPay and INIpay
        if (
          gateWays.includes(method?.paymentMethod?.code) &&
          method?.disallowedTestLocationIds?.find((x) => x === locationId)
        ) {
          return;
        }

        /**
         * ignore OFFLINE
         * when product is OSR and EOR
         * ignore OFFLINE
         * when product is OSR and EOR
         * or if it came from change payment CTA
         */
        if (
          (isFromOffline || isOSRorEORProduct) &&
          method.paymentMethod.code === PaymentMethod.OFFLINE
        ) {
          return;
        }

        name.push(method?.paymentMethod?.code);
      });
      let result: any[] = isFromOffline
        ? name
        : location?.configuration?.paymentMethods.concat(name) || [];

      const offlineTCPaymentMethod = testcentrePaymentMethods?.find(
        (method) => method.paymentMethod.code === PaymentMethod.OFFLINE
      );

      if (
        isNewOfflinePaymentEnabled &&
        !isOSRorEORProduct &&
        (!offlineTCPaymentMethod ||
          offlineTCPaymentMethod?.disallowedTestLocationIds?.some(
            (tlId) => tlId === locationId
          ))
      ) {
        result = result.filter((x) => x !== PaymentMethod.OFFLINE);
      }

      return {
        paymentMethod: result,
        location,
        testcentrePaymentMethods,
      };
    })
  );
};

export const clearTestCentre = () => testCentre$.next(undefined);
export const getTestCentreCode = (app: Application) => {
  if (app && app.bookings && app.bookings.length > 0 && app.bookings[0]) {
    return app.bookings[0].testCentreCode;
  }
  return undefined;
};
