import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  Address,
  Application, ApplicationPaymentStatus,
  ApplicationService,
  DashboardItem,
  DashboardItemActionCode,
  DashboardItemStatus,
  DashboardResponse
} from '@idp-education/ors-test-taker-bff-client-v1';
import { DeviceDetectorService } from 'ngx-device-detector';
import { of, from, Subscription, Observable, combineLatest } from 'rxjs';
import {
  ICreateApplication,
  IProductTypes,
  IWhenCompleteDetailIsClickable
} from '../../models/components/upcoming-test';
import { IStatus, ITimelineItem } from '../../models/components/time-line';
import { DateTime } from 'luxon';
import { getTestType, getTestFormat } from '../../utils/infer-product-name';
import { NgbModal, NgbOffcanvas, NgbOffcanvasConfig } from '@ng-bootstrap/ng-bootstrap';
import { UploadConsentFormModalComponent } from 'src/app/shared/components/upload-consent-form-modal/upload-consent-form-modal.component';
import { Store } from '@ngrx/store';
import { concatMap, finalize, first, map, mergeMap, skip, switchMap, take, tap, toArray } from 'rxjs/operators';
import { uploadConsentForm } from 'src/app/store/applications/application.action';
import { getApplicationWithConsentFormUpdated } from 'src/app/store/applications/application.selectors';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import awsConfig from '../../../../aws-amplify/aws-exports';
import { Amplify } from '@aws-amplify/core';
import { Storage } from 'aws-amplify';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { FeeType, TipsService } from 'shared/services/tips.service';
import { setFromTips } from 'pages/payment/store/payment.actions';
import { isObject } from 'shared/utils';
import { DashboardService } from 'shared/services/dashboard.service';
import { OfflinePaymentService } from 'shared/services/offline-payment-service';
import { selectChangePaymentToOnlineEnabled, selectEnableNewOfflinePayment } from 'store/global.reducer';
import { isOSRProduct } from 'shared/utils/is-osr-product';
import { CommonService } from 'shared/services/common.service';

const { s3Links } = require('src/assets/appSettings.json');

export interface IDashboardItemMeta {
  isLoading?: boolean;
  callToAction?: () => void;
  productName?: string;
  dashboardItem?: DashboardItem;
}

const parseDate =
  (dateString: string, item: DashboardItem) =>
    DateTime.fromISO(dateString, { zone: 'utc' })
      .setZone(item?.productName.includes('on Computer') ? item?.testLocationTimeZone : 'local');
@Component({
  selector: 'app-upcoming-test',
  templateUrl: './upcoming-test.component.html',
  styleUrls: ['./upcoming-test.component.scss']
})
export class UpcomingTestComponent implements OnDestroy, OnChanges {
  @Input() stTitle: string = null;
  @Input() lrwTitle: string = null;
  @Input() lrwTitleMd: string = null;
  @Input() textClass = '';
  @Input() status = false;
  @Input() title: string;
  @Input() tests: DashboardItem[];
  @Input() showOptions = false;
  @Input() showViewTestsLink = true;
  @Input() showHeader = false;
  @Input() selectTimeline: any;
  @Input() timelineItems: Array<ITimelineItem>;
  @Input() showGetDetail = false;
  @Input() showConsentForm = false;
  @Output() onRefetchTests: EventEmitter<any> = new EventEmitter();
  @Output() onClickOptions: EventEmitter<{
    key: DashboardItemActionCode,
    application: DashboardItem
  }> = new EventEmitter();
  @Output() onTimelineItemClick?: EventEmitter<any> = new EventEmitter();
  @ViewChild('uploadSuccessful') uploadSuccessfulModal: ConfirmModalComponent;
  applications: ICreateApplication[] = [];
  showErrorIcon = false;
  unpaidApplicationSubscription: Subscription;
  localStatus = '';
  nonIOLTime;
  utcTime;
  itemMeta: {
    [key: string]: IDashboardItemMeta
  } = {};
  enableNewOfflinePayment = false;
  isChangePaymentEnabled = false;
  selectedTestLocationId: string;

  private _consentUrl: string;
  public get consentUrl(): string {
    return this._consentUrl;
  }

  private _timeLeft = {
    speaking: true,
    lrw: true
  };

  get timeLeftToSpeaking() {
    return this._timeLeft.speaking;
  }

  get timeLeftToLRW() {
    return this._timeLeft.lrw;
  }

  private readonly defaultAndClickable: {
    change: { clickable: boolean; }, status?: 'default' | 'active' | 'text-disabled' | 'disabled'
  } = {
      status: 'default',
      change: {
        clickable: true
      }
    };
  public readonly consentFormDownloadLink = s3Links.underageConsentForm;
  get hasConsentForm() {
    return this;
  }
  constructor(
    public deviceService: DeviceDetectorService,
    private modalService: NgbModal,
    private store: Store<{ applicationsStore, globalStore }>,
    private applicationService: ApplicationService,
    private tipsService: TipsService,
    private loadingService: LoadingService,
    private dashboardService: DashboardService,
    offcanvasConfig: NgbOffcanvasConfig,
    private offcanvasService: NgbOffcanvas,
    private offlinePaymentService: OfflinePaymentService,
    private commonService: CommonService
  ) {
    combineLatest([
      this.store.select(selectEnableNewOfflinePayment),
      this.store.select(selectChangePaymentToOnlineEnabled),
    ])
      .pipe(first())
      .subscribe(([isNewOfflinePaymentEnabled, isChangePaymentEnabled]) => {
        this.enableNewOfflinePayment = isNewOfflinePaymentEnabled;
        this.isChangePaymentEnabled = isChangePaymentEnabled;
      });

    offcanvasConfig.position = 'end';
  }

  private setConsentBucket() {
    const appSettings = require('src/assets/appSettings.json');
    this.setBucket(appSettings.consentUpload.BucketName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tests.currentValue) {
      this.initialApplication();
      this.showError();
    }
  }

  private resetBucket() {
    const appSettings = require('src/assets/appSettings.json');
    this.setBucket(appSettings.S3Upload.BucketName);
  }
  toDate(d: string) {
    return new Date(d);
  }

  private showError() {
    this.tests.forEach(x => {
      if ((x.status === 'UNPAID') || (x.status === 'PAID_BUT_INCOMPLETE')) {
        this.showErrorIcon = true;
      }
    });
  }

  onGetDetailClicked(type: 'speaking' | 'lrw', timeLineItems: ITimelineItem[], app: Application) {
    if (!this.onTimelineItemClick) {
      return;
    }
    if (type === 'speaking') {
      this.onTimelineItemClick.emit({
        timeLineItem: timeLineItems && timeLineItems?.find(item => item.key === 'speakingDetail'),
        application: app
      });
    } else {
      this.onTimelineItemClick.emit({
        timeLineItem: timeLineItems && timeLineItems?.find(item => item.key === 'completeSpeaking'),
        application: app
      });
    }
  }

  private async initialApplication() {

    if (this.timelineItems) {
      this.applications = this.tests.map(app => {
        return this.createApplications(app, this.timelineItems);
      });
    } else {
      this.applications = (this.tests.map(app => ({
        app,
        speakingDate: parseDate(app.speakingStartDateTimeUtc, app),
        lrwDate: parseDate(app.lrwStartDateTimeUtc, app),
        underAgeConsentDetails: {
          ...app.underAgeConsentDetails
        }
      } as ICreateApplication)));
    }
    const unPaidLocationName = this.applications.filter(item => item.app.status === "UNPAID").map(item => item.app.testLocationName); 
    localStorage.setItem('testLocationName', JSON.stringify(unPaidLocationName));
    this.setConsentBucket();
    this.applications = await this.updateConsentFile();
    this.applications = this.applications.map(app => ({
      ...app,
      type: this.getProductName(app.app.productName)
    }));
    this.resetBucket();

    this.applicationService.defaultHeaders = this.applicationService.defaultHeaders.set('loading', 'off');
    this.applicationService.defaultHeaders = this.applicationService.defaultHeaders.set('suppressError', 'on');
    this.unpaidApplicationSubscription = from(this.tests.filter(dashboardItem => (
      dashboardItem.status === DashboardItemStatus.CONFIRMTIPSPAYMENT
    ))).pipe(concatMap(dashboardItem => {
      this.itemMeta[dashboardItem.applicationId] = {
        isLoading: true,
        productName: dashboardItem.productName,
        dashboardItem
      };
      return this.tipsService.verifyTipsPayment(dashboardItem.applicationId, false, false);
    }), concatMap(verificationResult => {
      const itemMeta = this.itemMeta[verificationResult.application.id];
      if (itemMeta) {
        if (verificationResult.applicationPayment.status === ApplicationPaymentStatus.PAID) {
          return this.refreshDashboardStatus(verificationResult.application.id);
        } else {
          itemMeta.isLoading = false;
          itemMeta.callToAction = () => {
            this.store.dispatch(setFromTips({ fromTips: true }));
            this.tipsService.onCancel(
              FeeType.APPLICATION_REGISTRATION,
              itemMeta.productName,
              verificationResult.application,
              verificationResult.applicationPayment
            );
          };
        }
      }
      return of(null);
    }), toArray(), finalize(() => {
      Object.keys(this.itemMeta).forEach(key => {
        const meta = this.itemMeta[key];
        if (isObject(meta) && meta.isLoading === true) {
          delete meta.isLoading;
        }
      });
      this.applicationService.defaultHeaders = this.applicationService.defaultHeaders.delete('loading');
      this.applicationService.defaultHeaders = this.applicationService.defaultHeaders.delete('suppressError');
    })).subscribe();
  }

  refreshDashboardStatus(applicationId: string) {
    return this.fetchDashboardItems().pipe(tap(response => {
      const dashboardItem = response.upcomingTests.find(item => item.applicationId === applicationId);
      if (dashboardItem) {
        const item = this.itemMeta[applicationId].dashboardItem;
        if (item) {
          item.status = dashboardItem.status;
        }
      }
    }));
  }

  private fetchDashboardItems(): Observable<DashboardResponse> {
    return this.dashboardService.getAllDashboardApplications();
  }

  private async updateConsentFile() {
    return await Promise.all(this.applications.map(async (app) => {
      let url = '';
      if (app.underAgeConsentDetails.s3Url) {
        url = await this.getConsentForm$(app.underAgeConsentDetails.s3Url) as string;
      }
      return {
        ...app,
        underAgeConsentDetails: {
          ...app.underAgeConsentDetails,
          fileName: this.getFileName(url),
          fileDate: app.underAgeConsentDetails?.modifiedOn,
          fileUrl: url
        }
      } as ICreateApplication;
    }));
  }

  getTestDate(dateTime: DateTime, type: string) {
    if (dateTime) {
      switch (type) {
        case 'month':
          return (dateTime?.toFormat('MMM') || '').toUpperCase();
        case 'day':
          return dateTime.toFormat('dd');
        case 'year':
          return dateTime.toFormat('yyyy');
        case 'time':
          return `${dateTime.toFormat('h:mm a').toLowerCase()}`;
      }
    }
    return '';
  }

  format30minsSubtactedTime(dateTime: DateTime) {
    return `${dateTime.minus({ minute: 30 }).toFormat('h:mm a').toLowerCase()}`;
  }

  getTimezone(testTime: string) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  timeDone({ index, type }) {
    this._timeLeft = {
      speaking: this._timeLeft.speaking === false ? false : type !== 'lrw',
      lrw: this._timeLeft.lrw === false ? false : type === 'lrw'
    };
    return {
      ...this.tests[index],
      lrwReady: type === 'lrw' ? true : undefined,
      speakingReady: type !== 'lrw' ? true : undefined
    };
  }

  public trackItem(index, item) {
    return item.lrwReady;
  }

  getDiffFromNow = (dateTime: DateTime) => dateTime.diff(DateTime.now().setZone(dateTime.zoneName), ['hours', 'minutes']);

  createApplications(application: DashboardItem, timelineItems: ITimelineItem[]): ICreateApplication {
    const lrwDate = parseDate(application.lrwStartDateTimeUtc, application);
    const speakingDate = parseDate(application.speakingStartDateTimeUtc, application);
    const tillLRWStartAsHours = this.getDiffFromNow(lrwDate)?.hours;
    const tillLRWStartAsMin = this.getDiffFromNow(lrwDate)?.minutes;
    const tillSpeakingStartAsHours = this.getDiffFromNow(speakingDate)?.hours;
    const tillSpeakingStartAsMin = this.getDiffFromNow(speakingDate)?.minutes;
    const tempApplication = {
      app: application,
      timelineItems,
    };
    this._timeLeft = {
      speaking: tillSpeakingStartAsMin >= 1,
      lrw: tillLRWStartAsMin >= 1
    };
    let result: ICreateApplication = {
      ...tempApplication, speakingDate, lrwDate, underAgeConsentDetails: {
        ...application.underAgeConsentDetails,
      }
    };
    if (application.status === 'PAID_BUT_INCOMPLETE') {
      //  CD: CompleteDetail
      result = this.whenProfileIsIncomplete({ timelineItems, tempApplication }, speakingDate, lrwDate);
    } else if (application.status === 'COMPLETED' || application.status === 'AWAITING_RESULT') {
      result.timelineItems = this.changeTimelineItems(timelineItems, [{
        key: 'completeDetail',
        ...this.defaultAndClickable,
        status: 'text-disabled',
        change: {
          clickable: false
        }
      }, {
        key: 'downloadPlayer',
        ...this.defaultAndClickable
      }, {
        key: 'speakingDetail',
        status: tillSpeakingStartAsHours < 24 && tillSpeakingStartAsMin >= 1 ? 'active' : 'default',
        change: {
          clickable: true
        }
      }, {
        key: 'completeSpeaking',
        status: tillLRWStartAsHours < 24 && tillLRWStartAsMin >= 1 ? 'active' : 'default',
        change: {
          clickable: true
        }
      }]);
    }
    return result;
  }

  private whenProfileIsIncomplete(
    { timelineItems, tempApplication }: IWhenCompleteDetailIsClickable,
    speakingDate: DateTime,
    lrwDate: DateTime): ICreateApplication {
    const tempTimeline = this.manipulateItemsOnPaidButIncomplete(timelineItems);
    return { ...tempApplication, timelineItems: tempTimeline, speakingDate, lrwDate };
  }

  private manipulateItemsOnPaidButIncomplete(items: ITimelineItem[]) {
    if (Array.isArray(items)) {
      return this.changeTimelineItems(items, [{
        key: 'completeDetail',
        status: 'active',
        change: {
          clickable: true
        },
      }, {
        key: 'downloadPlayer',
        status: 'disabled',
        change: {
          clickable: false
        },
      }, {
        key: 'speakingDetail',
        status: 'disabled',
        change: {
          clickable: false
        },
      }, {
        key: 'completeSpeaking',
        status: 'disabled',
        change: {
          clickable: false
        },
      }
      ]);
    }
    return [];
  }

  private changeTimelineItems(items: ITimelineItem[], changes: {
    key: IStatus,
    change: {
      clickable: boolean
    },
    status?: 'default' | 'active' | 'text-disabled' | 'disabled'
  }[]) {
    return items.map(item => ({
      ...item,
      clickAble: !!changes.find(i => i.key === item.key)?.change?.clickable,
      status: changes.find(i => i.key === item.key)?.status
    }));
  }

  timerDone() {
    this.onRefetchTests.emit();
  }

  isVisibleGetDetailBtn(targetTime: DateTime) {
    const now = DateTime.now().setZone(targetTime.zoneName);
    return now > targetTime.minus({ hour: 24 }) && now < targetTime.plus({ hour: 48 });
  }

  onClickJourneyItem(item: ITimelineItem, app: DashboardItem) {
    if (!this.onTimelineItemClick) {
      return;
    }
    this.onTimelineItemClick.emit({ timeLineItem: item, application: app });
  }

  showSpeakingTestCard(productName: string, speakingTestTime: string): boolean {
    if (productName.includes('IELTS OSR') && speakingTestTime === '0001-01-01T00:00:00+00:00') {
      return false;
    } else {
      return true;
    }
  }

  showLRWTestCard(productName: string, lrwTestTime: string): boolean {
    if (productName.includes('IELTS OSR') && lrwTestTime === '0001-01-01T00:00:00+00:00') {
      return false;
    } else {
      return true;
    }
  }

  getOSRTestName(): string {
    return 'One Skill Retake test';
  }

  getProductName(productName): { [key in IProductTypes]?: any } {
    const keywords: Record<IProductTypes, RegExp> = {
      IOL: /Online/,
      IOC: /Computer/,
      SSR: /OSR/,
      SELT: /SELT/
    };
    return Object.keys(keywords)
      .filter((keyword: IProductTypes) => keywords[keyword].test(productName))
      .map((characteristic: IProductTypes) => characteristic).reduce((a, v) => ({ ...a, [v]: v }), {});
  }

  isOfflinePaymentPending(item) {
    if (item.app) {
      return item.app.status === DashboardItemStatus.OFFLINEPAYMENTINPROGRESS;
    } else {
      return false;
    }
  }

  isOfflinePaymentComplete(item) {
    if (item.app) {
      return item.app.status === DashboardItemStatus.OFFLINEPAYMENTCONFIRMED;
    } else {
      return false;
    }
  }

  addCol9Class(item) {
    return item.app.status !== DashboardItemStatus.COMPLETED && item.app.status !== DashboardItemStatus.OFFLINEPAYMENTINPROGRESS;
  }

  displayUpcomingTimer(item) {
    return (item.app.status === DashboardItemStatus.COMPLETED || item.app.status === DashboardItemStatus.OFFLINEPAYMENTCONFIRMED)
      && item.app.isComplete;
  }

  getTestType(bookableProductName: string) {
    return getTestType(bookableProductName);
  }

  getTestFormat(bookableProductName: string) {
    return getTestFormat(bookableProductName);
  }

  getAddress(addresses: Address[]) {
    const physicalAddress = addresses?.find(a => a.addressType === 'PHY');
    if (physicalAddress) {
      const append = (info: string | undefined, prefix = '') => {
        if (info === undefined || info === '' || info === null) {
          return '';
        } else {
          return `${prefix} ${info}`;
        }
      };
      const addressLines = physicalAddress.addressLine1 +
        append(physicalAddress.addressLine2) +
        append(physicalAddress.addressLine3) +
        append(physicalAddress.addressLine4) +
        append(physicalAddress.city, ',') +
        append(physicalAddress.zipPostCode);
      return addressLines;
    } else {
      return '';
    }
  }
  private setBucket(bucketName) {
    Amplify.configure({
      ...awsConfig,
      Storage: {
        AWSS3: {
          ...awsConfig.Storage.AWSS3,
          bucket: bucketName
        }
      }
    });
  }
  private getConsentForm$(s3Url) {
    const s3UrlArray = s3Url.split('/');
    return Storage.get(s3UrlArray[s3UrlArray.length - 1]);
  }
  onUploadConsentFormModal(application: ICreateApplication): void {
    this.setConsentBucket();
    const modalRef = this.modalService.open(UploadConsentFormModalComponent, { size: 'lg', animation: true, backdrop: 'static' });
    this.fetchConsentFormIfExist(application, modalRef);
    modalRef.closed
      .pipe(
        mergeMap((res: { consentForm: File, note: string }) =>
        (this.applicationService.getApplication(application.app.applicationId, this.commonService?.getUserUuid())
          .pipe(map(appRes => ({ consentRes: res, getAppRes: appRes }))))
        ),
        switchMap(res => {
          this.store.dispatch(uploadConsentForm({
            consentForm: res.consentRes.consentForm,
            note: res.consentRes.note,
            applicationVersion: res.getAppRes.version,
            applicationId: application.app.applicationId
          }));
          return this.store.select(getApplicationWithConsentFormUpdated)
            .pipe(skip(1));
        }),
        take(1)
      )
      .subscribe((res) => {
        this.getConsentForm$(res.underAgeConsentDetails.s3Url).then(data => {
          const index = this.applications.findIndex(app => app.app.applicationId === res.id);
          if (index >= 0) {
            this.applications[index].app.underAgeConsentDetails = {
              ...res.underAgeConsentDetails,
              s3Url: data,
            };
            this.applications[index].underAgeConsentDetails = {
              ...res.underAgeConsentDetails,
              fileDate: res.underAgeConsentDetails?.modifiedOn,
              fileName: this.getFileName(data as string),
              fileUrl: data
            };
          }
          this.resetBucket();
          this.uploadSuccessfulModal.open({ size: 'lg', animation: true, keyboard: false });
        });
      }, () => {
        this.resetBucket();
        this.loadingService.resetLoadingCounter();
      });
  }
  private fetchConsentFormIfExist(application: ICreateApplication, modalRef) {
    if (!application?.underAgeConsentDetails?.s3Url) {
      return;
    }
    this.loadingService.increaseLoadingCounter();
    this.getConsentForm$(application?.underAgeConsentDetails?.s3Url).then(s3Url => {
      if (s3Url) {
        (modalRef.componentInstance as UploadConsentFormModalComponent).consentFormDetail = {
          url: s3Url,
          fileDate: application.underAgeConsentDetails?.modifiedOn,
          fileName: this.getFileName(s3Url as string),
          note: application?.underAgeConsentDetails?.note
        };
      }
      this.loadingService.decreaseLoadingCounter();
    }).catch(() => this.loadingService.decreaseLoadingCounter());
  }

  getFileName(s3Url: string) {
    if (!s3Url) {
      return '';
    }
    try {
      const url = new URL(s3Url);
      const objectKey = url.pathname.substring(1);
      const fileName = objectKey.split('/').pop();
      return this.decodeUrlEncodedString(fileName);
    } catch (error) {
      return '[unknown]';
    }
  }
  getFileDate(s3URL) {
    if (!s3URL) {
      return '';
    }
    try {
      const url = new URL(s3URL);
      const fileDate = url.searchParams.get('X-Amz-Date');
      if (fileDate) {
        const dateTime = DateTime.fromFormat(fileDate, 'yyyyMMdd\'T\'HHmmss\'Z\'');
        return dateTime.toJSDate();
      }
      return '';
    } catch (error) {
      return '';
    }
  }
  private decodeUrlEncodedString(inputString) {
    const decodedString = decodeURIComponent(inputString);
    const unicodeReplacer = /\\u([\dA-Fa-f]{4})/g;
    const replacedString = decodedString.replace(unicodeReplacer, (match, group) => {
      return String.fromCharCode(parseInt(group, 16));
    });
    return replacedString;
  }

  viewOfflinePaymentOptions(content: TemplateRef<any>, locationId: string) {
    if (this.offcanvasService.hasOpenOffcanvas()) {
      return;
    }

    if (locationId) {
      this.selectedTestLocationId = locationId;
    }

    this.offcanvasService.open(content, { panelClass: 'theme-1' });
  }

  changePaymentMethod(item: ICreateApplication) {
    this.offlinePaymentService
      .verifyOfflinePayment(item.app.applicationId)
      .pipe(
        tap(({ application, applicationPayment }) => {
          this.offlinePaymentService.navigatePaymentMethodSelection(
            application,
            applicationPayment
          );
        }),
        first()
      )
      .subscribe();
  }

  isOsrProduct(productName: string): boolean {
    return isOSRProduct(productName);
  }

  ngOnDestroy(): void {
    this.resetBucket();
    this.unpaidApplicationSubscription?.unsubscribe();
    if (this.applicationService.defaultHeaders) {
      this.applicationService.defaultHeaders = this.applicationService.defaultHeaders.delete('loading');
      this.applicationService.defaultHeaders = this.applicationService.defaultHeaders.delete('suppressError');
    }
  }
}
