import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICon } from '../payment-container/payment-container.component';
import {
  Application, TipsPaymentService,
} from '@idp-education/ors-test-taker-bff-client-v1';
import { concatMap, finalize, first } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { TipsLoaderComponent } from 'shared/components/payment/tips/tips-loader/tips-loader.component';
import { PaymentGateways } from 'pages/payment/payment.enum';
import { SharedStateService } from 'pages/account/shared-state.service';
import { LoadingService } from 'shared/services/loading-service.service';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss'],
})
export class TipsComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @Input() isNewJourney = false;
  errMsg: string;
  tipsUrl: string | undefined;
  modalRef: NgbModalRef;
  sub: Subscription;
  isNewPaymentJourney: string;
  static get tab(): ICon {
    return {
      alt: 'tips',
      key: 'TIPS',
      url: '',
      class: 'fa fa-credit-card-alt',
      title: 'Pay online',
      description: 'Pay for your test securely online now via available payment methods',
    };
  }

  constructor(
    private paymentService: TipsPaymentService,
    private modalService: NgbModal,
    private sharedState: SharedStateService,
    private commonService: CommonService,
    public loading: LoadingService
  ) {
   
  }

  ngOnInit(): void {
    if(!this.isNewJourney)
    {
      this.createOrder();
    }
    else{
      this.sub = this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
        if (paymentDetails.componentName === PaymentGateways.TIPS) {
          this.createOrder();
        }
      });
    }
    
  }

  ngOnDestroy() {
    this.paymentService.defaultHeaders.delete('loading');
  }

  createOrder() {
    this.paymentService.defaultHeaders = this.paymentService.defaultHeaders.set('loading', 'off');
    this.paymentService.tipsPaymentInitiation({
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId
    }, this.commonService?.appCorrelationId()).pipe(first(), concatMap((tipsResponse) => {
      if (tipsResponse?.paymentUrl) {
        this.modalRef.componentInstance.msg = 'Please wait while navigate you to the payment portal';
        this.tipsUrl = tipsResponse.paymentUrl;
        return timer(1800);
      } else {
        throw new Error('Payment Url is missing from tips payment initiation response');
      }
    }), finalize(() => {
      this.paymentService.defaultHeaders.delete('loading');
    })).subscribe(data => {
      if (this.tipsUrl) {
        this.redirect(this.tipsUrl);
      } else {
        this.handleTipsError();
        this.modalService.dismissAll();
      }
    }, err => {
      this.handleTipsError(err);
      this.modalService.dismissAll();
    });
    try {
      this.loading.resetLoadingCounter();
      this.modalRef = this.modalService.open(TipsLoaderComponent, {
        keyboard: false,
        animation: false
      });
      this.modalRef.componentInstance.msg = '';
    } catch (e) { /* empty */ }
  }

  redirect(url: string) {
    window.location.href = url;
  }

  handleTipsError(err?: any) {
    const defaultErrMsg = 'Service unavailable, please try after some time.';
    if (err?.error?.type !== 'validation_error') {
      this.errMsg = err?.error?.message || defaultErrMsg;
    }
  }

  getRoute() {
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    return this.isNewPaymentJourney === 'enabled'
      ?  `${location.origin}/account/booking-confirmation`
      :  `${location.origin}/payment/confirmation`;
  }

  // TODO: pass return Url to redirection tipsUrl
  getReturnUrl() {
    switch (this.paymentType) {
      case 'IOC':
        return this.getRoute();
      case 'EOR':
      case 'OSR':
        return `${location.origin}/my-tests`;
      default:
        return `${location.origin}/my-tests`;
    }
  }
}
