import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { IState, SharedStateService } from './shared-state.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
const { site } = require('src/assets/appSettings.json');

@Component({
  selector: 'app-mock-settings',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mock-settings.component.html',
  styleUrls: ['./mock-settings.component.scss']
})
export class MockSettingsComponent implements OnInit, OnDestroy {

  @HostBinding('style.display') display = 'flex';
  @HostBinding('style.flexGrow') flexGrow = '1';
  state!: IState;
  subs: Subscription[] = [];
  public buildInfo: string = site.buildInfo;

  constructor(
    public sharedService: SharedStateService,
  ) { }

  ngOnInit(): void {
    this.subs.push(this.sharedService.getState().subscribe(e => {
      this.state = e;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  setState(name: string, value: any) {
    const newState = {} as any;
    newState[name] = value;
    this.sharedService.updateState(newState);
  }

}
