import {
  OfflinePaymentService,
  OfflineReceiptResponse,
} from '@idp-education/ors-offline-payment-bff-client-v1';
import { Injectable } from '@angular/core';
import { Observable, Subscription, first, of, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  Application,
  ApplicationPayment,
  Contents,
  CreateReceiptRequest,
  CreateReceiptResponse,
  PaymentService,
  ReceiptService,
  TestCentrePaymentMethod,
} from '@idp-education/ors-test-taker-bff-client-v1';

import { ApplicationsService } from './applications.service';
import { AuthService } from './auth.service';
import {
  GlobalState,
  selectEnableNewOfflinePayment,
} from 'store/global.reducer';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { UserState } from 'store/user/user.reducer';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class OfflinePaymentRequestService {
  private isNewOfflinePaymentEnabled = false;
  private testCentrePaymentMethods: TestCentrePaymentMethod[];
  private receipt: CreateReceiptResponse;
  private application: Application;
  private userState: UserState;
  private sub: Subscription;
  
  get offlinePaymentMethodContents() {
    return this.testCentrePaymentMethods?.find(
      (pm) => pm.paymentMethod.code === IPaymentMethods.OFFLINE
    )?.paymentMethod.paymentMethodContents?.[0]?.contents?.[0];
  }

  get newOfflinePaymentEnabled() {
    return this.isNewOfflinePaymentEnabled;
  }
  

  constructor(
    private store: Store<{ globalStore: GlobalState, userStore }>,
    private offlinePaymentService: OfflinePaymentService,
    private applicationService: ApplicationsService,
    private authService: AuthService,
    private receiptService: ReceiptService,
    private paymentService: PaymentService,
    private commonService: CommonService
  ) {
    this.store?.select(selectEnableNewOfflinePayment)?.pipe(first())
      .subscribe((isEnabled) => {
        this.isNewOfflinePaymentEnabled = isEnabled;
      });
      this.sub = this.store.select(appState => appState.userStore)?.subscribe(x => {
        this.userState = x;
      });
  }

  setToken() {
    this.offlinePaymentService.configuration['credentials'] = {'Authorization': this.userState?.userToken};
  }

  setTCPaymentMethods(paymentMethods: TestCentrePaymentMethod[]) {
    this.testCentrePaymentMethods = paymentMethods;
  }

  createReceipt(): Observable<CreateReceiptResponse | ApplicationPayment[]> {
    return this.applicationService.GetCurrentApplication()?.pipe(
      first(),
      tap((application) => {
        this.application = application;
      }),
      switchMap((currentApplication) => {
        if (this.isNewOfflinePaymentEnabled) {
          const receiptRequest: CreateReceiptRequest = {
            applicationId: currentApplication.id,
            applicationPaymentId:
              this.getApplicationPaymentId(currentApplication),
            testCentrePaymentMethodId: this.getTCPaymentMethodId(
              this.testCentrePaymentMethods
            ),
          };

          return this.receiptService.createReceipt(this.commonService?.appCorrelationId(), receiptRequest);
        }

        return of(currentApplication.applicationPayments);
      }),
      first(),
      tap((response) => {
        if ('receiptNumber' in response) {
          this.receipt = response;
        }
      })
    );
  }

  initializeReceipt(
    applicationPaymentId: string,
    isNonEOROSRProduct: boolean
  ): Observable<OfflineReceiptResponse> {
    this.setToken();
    if (this.isNewOfflinePaymentEnabled && isNonEOROSRProduct) {
      const receiptId = this.receipt.id;
      const applicationId = this.application.id;
      return this.offlinePaymentService.initiateOfflineReceiptProcess(
        receiptId,
        { applicationId },
        this.commonService.appCorrelationId()
      );
    }

    return this.paymentService.confirmOfflinePaymentMethod(undefined, {
      applicationPaymentId,
    });
  }

  cancelOfflinePayment(receiptId): Observable<any> {  
    this.setToken();
    return this.offlinePaymentService.cancelOfflineReceiptProcess(receiptId, this.commonService?.appCorrelationId());
  }

  private getApplicationPaymentId(
    application: Application | undefined
  ): string {
    if (
      application &&
      Array.isArray(application.applicationPayments) &&
      application.applicationPayments.length
    ) {
      return application.applicationPayments.reduce((a, b) =>
        new Date(a.createdOn) > new Date(b.createdOn) ? a : b
      ).id;
    }

    return '';
  }

  private getTCPaymentMethodId(tcPaymentMethods: TestCentrePaymentMethod[]): string {
    if (tcPaymentMethods && Array.isArray(tcPaymentMethods)) {
      return tcPaymentMethods.find(
        (x) => x.paymentMethod.code === IPaymentMethods.OFFLINE
      )?.id;
    }

    return '';
  }
}
