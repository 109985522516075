import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Application, CreateReceiptRequest, CreateReceiptResponse, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { ICon } from '../payment-container/payment-container.component';
import { Subscription } from 'rxjs';
import { UserState } from 'store/user/user.reducer';
import { NavigationService } from 'shared/services/navigation.service';
import { Store } from '@ngrx/store';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { SharedStateService } from 'pages/account/shared-state.service';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingService } from 'shared/services/loading-service.service';
import { BracPaymentService } from '@idp-education/ors-brac-payment-bff-client-v1';
import { BracPaymentService as BracPaymentServiceV2 } from '@idp-education/ors-brac-payment-bff-client-v2';
import { PaymentsService } from 'src/app/shared/services/payment.services';
import { CommonService } from 'shared/services/common.service';
const appSettings = require('src/assets/appSettings.json');

@Component({
  selector: 'app-brac',
  templateUrl: './brac.component.html',
  styleUrls: ['./brac.component.scss']
})
export class BracComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() tcPaymentMethod = [];
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  @Input() isNewJourney = false;
  @Input() isBracCyberSource = false;
  postReceipt: Subscription;
  sub: Subscription;
  bracSub: Subscription;
  userState: UserState;
  receiptResponse: CreateReceiptResponse;

  title: string;
  acceptText: string;

  formData: UntypedFormGroup;
  paymentUrl: string;
  bill_to_forename = "NOREAL";
  bill_to_surname = "NAME";
  bill_to_address_line1 = "1295 Charleston Road";
  bill_to_address_state = "CA";
  bill_to_address_city = "Mountain View";
  bill_to_address_country = "US";
  bill_to_email = "null@cybersource.com";
  bill_to_address_postal_code = "94043";
  receiptExpiredValue;

  static get tab(): ICon {
    return {
      alt: 'BRAC',
      key: 'BRAC',
      url: '/assets/images/payment/brac.svg',
      class: '',
      title: 'Pay online with Visa and Mastercard',
      description: 'Secure payments powered by BRAC',
    };
  }
  constructor(
    private router: Router,
    private receiptService: ReceiptService,
    private navigationService: NavigationService,
    private bracPaymentService: BracPaymentService,
    private bracPaymentServiceV2: BracPaymentServiceV2,
    private sharedState: SharedStateService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private store: Store<{ userStore }>,
    private paymentService: PaymentsService,
    private commonService: CommonService
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.userState = x;
    });
  }

  ngOnInit(): void {
    this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.BRAC) {
        if (!this.isBracCyberSource) {
          this.openBRAC();
        } else {
          this.fetchFormData();
        }
      }
    }));
    this.formData = this.formBuilder.group({
      access_key: new FormControl(''),
      profile_id: new FormControl(''),
      transaction_uuid: new FormControl(''),
      signed_field_names: new FormControl(''),
      unsigned_field_names: new FormControl(''),
      signature: new FormControl(''),
      signed_date_time: new FormControl(''),
      locale: new FormControl(''),
      transaction_type: new FormControl(''),
      reference_number: new FormControl(''),
      auth_trans_ref_no: new FormControl(''),
      amount: new FormControl(''),
      currency: new FormControl(''),
      override_custom_cancel_page: new FormControl(''),
      override_custom_receipt_page: new FormControl('')
    });
    this.createReceipt();
  }

  ngOnDestroy(): void {
    this.postReceipt?.unsubscribe();
    this.sub?.unsubscribe();
    this.bracSub?.unsubscribe();
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
  }

  createReceipt() {
    let tcPaymentMethodId;
    if (!this.isBracCyberSource) {
      tcPaymentMethodId = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.BRAC);
    } else {
      tcPaymentMethodId = this.tcPaymentMethod.find(x => x.paymentMethod.description.toLowerCase().includes('cyber'));
    }
    const createReceiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: tcPaymentMethodId?.id
    };

    this.postReceipt = this.receiptService.createReceipt(this.commonService.appCorrelationId(), createReceiptRequest)?.subscribe(result => {
      this.receiptResponse = result;
      this.storeBookingInfo();
    }, (error) => {
      if (error?.includes('APPLICATION_EXPIRED')) {
        this.receiptExpiredValue = this.paymentService.getErrorState();
      }
    });
  }

  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.BRAC);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
    if (!this.isNewJourney) {
      if (!this.isBracCyberSource) {
        this.openBRAC();
      } else {
        this.fetchFormData();
      }
    }
  }

  openBRAC() {
    this.bracPaymentService.configuration.accessToken = this.userState?.userToken;
    this.bracSub = this.bracPaymentService.getPaymentUrl(this.applicationId, this.receiptResponse?.id, this.commonService.appCorrelationId()).subscribe(res => {
      if (res.paymentUrl) {
        window.open(res.paymentUrl, '_self');
      } else {
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.acceptText = 'Return to My Account';
        this.paymentModalPopup.open();
      }
    });
  }

  fetchFormData() {
    this.bracPaymentServiceV2.configuration.accessToken = this.userState?.userToken;
    const appId = this.applicationId;
    const receiptId = this.receiptResponse?.id;
    const postBrac = this.bracPaymentServiceV2.getPaymentFormData(appId, receiptId, this.commonService.appCorrelationId())?.subscribe(result => {
      this.loadingService.increaseLoadingCounter();
      this.formData.setValue({
        access_key: result?.accessKey,
        profile_id: result?.profileId,
        transaction_uuid: result?.transactionUuid,
        signed_field_names: result?.signedFieldNames,
        unsigned_field_names: result?.unsignedFieldNames,
        signature: result?.signature,
        signed_date_time: result?.signedDateTime,
        locale: result?.locale,
        transaction_type: result?.transactionType,
        reference_number: result?.referenceNumber,
        auth_trans_ref_no: result?.referenceNumber,
        amount: result?.amount,
        currency: result?.currency,
        override_custom_cancel_page: result?.cancelUrl,
        override_custom_receipt_page: result?.successUrl
      });
      this.paymentUrl = result.paymentUrl;
      const formElement: HTMLFormElement = document.getElementById('bracForm') as HTMLFormElement;
      this.loadingService.decreaseLoadingCounter();
      if (formElement && this.formData.value) {
        formElement.action = this.paymentUrl;
        formElement.submit();
      }
    }, error => {
      this.loadingService.decreaseLoadingCounter();
    });
    this.sub.add(postBrac);
  }

  backToListingPage(): void {
    window.open(`${appSettings.site.bxsearchURL}/book-ielts`, '_self');
  }
}
