import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Application,
  DashboardItem,
  EORFeeWithApplicationResponse,
  ProductFeeService,
  UserProfileAddressDetails,
  ApplicationService,
  UserProfile, PaymentMethod, PaymentStatus, ReceiptService, ApplicationPaymentStatus, ProductFeeType
} from '@idp-education/ors-test-taker-bff-client-v1';
import { StepsService } from './../../../../shared/services/steps.service';
import { WizardComponent } from '../../../../shared/components/wizard/wizard.component';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import * as uuid from 'uuid';
import { first, delay, concatMap, map, pluck, share } from 'rxjs/operators';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { isString } from 'lodash';
import { CreditCardComponent } from 'src/app/shared/components/payment/credit-card/credit-card.component';
import { CurrencyPipe } from '@angular/common';
import { noop } from 'lodash';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { PaymentsService } from '../../../../shared/services/payment.services';
import { EverFlowHelper } from 'src/app/shared/helper/everflow.helper';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/pages/account/signup/userProfile.model';
import { ApplicationsService } from '../../../../shared/services/applications.service';
import { TestCentreService } from '@idp-education/ors-test-taker-bff-client-v1/api/testCentre.service';
import { getTestCentreCode, initializePaymentMethodV2 } from '../../../../shared/utils/initialize-payment-method';
import { selectEnableOfflineEOR } from '../../../../store/global.reducer';
import { PurchaseItem } from '@paypal/paypal-js';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { selectFromTips, selectOfflinePayment } from '../../../payment/store/payment.reducer';
import { EMAIL_REGEXP } from 'src/app/shared/sharedRegex';
import { getApplicationUserName } from 'src/app/store/applications/application.selectors';
import { setCurrentApplication } from 'src/app/store/applications/application.action';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { setFromTips } from 'pages/payment/store/payment.actions';
import { GALocalStorageItemKeys } from 'pages/payment/payment.enum';
import { CommonService } from 'shared/services/common.service';

type IPaymentStatus = 'default' | 'in-progress' | 'error' | 'complete';
declare let dataLayer;

@Component({
  selector: 'app-eor-management',
  templateUrl: './eor-management.component.html',
  styleUrls: ['./eor-management.component.scss'],
  providers: [StepsService, CurrencyPipe]
})
export class EORManagementComponent implements OnInit, OnChanges, OnDestroy {
  @Input('application') dashboardApplication: DashboardItem;
  @Input() paymentToken: string;
  @Input() isEORPaymentCompleted: boolean;
  @Input() excemptionDetails: any;
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onFinishProcess: EventEmitter<any> = new EventEmitter();
  @ViewChild('wizard') wizardElem: WizardComponent;
  @ViewChild('paymentCard') paymentCard: CreditCardComponent;
  @ViewChild('paymentErrorModalPaypal') paymentErrorModalPaypal: ConfirmModalComponent;
  enableOfflineEOR$ = this.store.select(selectEnableOfflineEOR);
  fromTips$ = this.store.select(selectFromTips);
  enableAcceptClick = false;
  apiResponse: BehaviorSubject<boolean> = new BehaviorSubject(false);
  applicationId;
  application;
  eorFeeDataResponse;
  responseDataStore;
  eorFeeWithApplicationResponse: EORFeeWithApplicationResponse;
  registerResponse: { paymentId: string, appId: string };
  cardErrors;
  readonly _noop = noop;
  hostedFieldsInstance;
  private _paymentStatus: IPaymentStatus = 'default';
  enable3DSPayment: any;
  paymentMethods: IPaymentMethods[] = [];
  receiptNumber = '';
  osrBookingLineId;
  payCode: string;
  purchaseItem: PurchaseItem[];
  purchaseTotal: number;
  isStripeForwardingFailed = false;
  private _clientId: string;
  private _creditCardLoad: boolean;
  getApplicationUserName$ = this.store.select(getApplicationUserName);
  tcPaymentMethod = [];
  bracCyberSource: boolean;
  appCorrelationId = '';

  public get clientId(): string {
    return this._clientId;
  }

  public get creditCardLoad(): boolean {
    return this._creditCardLoad
  }

  private _paymentId: string;
  public get paymentId(): string {
    return this._paymentId;
  }

  private _profileId: string;
  public get profileId(): string {
    return this._profileId;
  }

  public get paymentStatus(): IPaymentStatus {
    return this._paymentStatus;
  }

  public set paymentStatus(v: IPaymentStatus) {
    this._paymentStatus = v;
    if (v === 'in-progress') {
      this.paymentCard.blurBackground = true;
    }
    // this.
  }

  get acceptText() {
    if (this?.stepService?.currentStep$?.value?.stepIndex === 1) {
      return 'Proceed';
    } else {
      if (this.paymentStatus === 'default') {
        const amount = this?.eorFeeWithApplicationResponse?.eorFee?.activeFee?.totalAmount || 0;
        const code = this?.eorFeeWithApplicationResponse?.eorFee?.activeFee?.currencyIsoCode;
        return `Pay ${this.cp.transform(amount, code, 'symbol-narrow')} ${code}`;
      } else if (this.paymentStatus === 'in-progress') {
        return `Processing payment`;
      } else if (this.paymentStatus === 'error') {
        return `Payment failed, please retry with a different card`;
      }
    }
  }

  private stepService: StepsService;
  private requestForm = [];

  get steps() {
    return [
      { stepIndex: 1, isComplete: false },
      { stepIndex: 2, isComplete: false },
    ];
  }

  selectedType: 'credit' | 'active' | 'creditActive' = 'credit';
  private _applicationPaymentId: string;
  public get applicationPaymentId(): string {
    return this._applicationPaymentId;
  }

  addressDetailsForm: UntypedFormGroup;
  CurrentApp: Application;
  threeDSecure: any;
  isOfflinePayment$ = (negation: boolean) => this.store.select(selectOfflinePayment)
    .pipe(map(isOffline => negation ? !isOffline : isOffline))

  constructor(
    private dashboardService: DashboardService,
    private applicationsService: ApplicationsService,
    private applicationService: ApplicationService,
    private productFeeService: ProductFeeService,
    private cp: CurrencyPipe,
    private loading: LoadingService,
    private paymentService: PaymentsService,
    private everFlowHelper: EverFlowHelper,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private toast: ToastrService,
    private store: Store<{ paymentStore, globalStore, applicationsStore, bookingStore }>,
    private testCentreService: TestCentreService,
    private fb: UntypedFormBuilder,
    private userProfile: UserProfileService,
    private receiptService: ReceiptService,
    private commonService: CommonService
  ) {
    this.enable3DSPayment = this.paymentService.check3DSstatus();
    this.userProfile.getUserProfile(false).pipe(pluck('userProfileId'), first()).subscribe(data => this._profileId = data);
    this.applicationsService.GetCurrentApplication().pipe(first()).subscribe(x => {
      if (x) {
        this.CurrentApp = x;
        this.store.dispatch(setCurrentApplication({ application: x }));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dashboardApplication?.currentValue) {
      this.applicationId = this.dashboardApplication?.applicationId;
      this.getEORFeeData().subscribe(data => {
        this.eorFeeWithApplicationResponse = data;
        localStorage.setItem(GALocalStorageItemKeys.eorObject, JSON.stringify(this.eorFeeWithApplicationResponse));
        this._paymentId = data.application.applicationPayments[0].id;
        this.fillPurchaseItem(data);
      });
      this.initApplication();
    }
  }

  initApplication() {
    const getApplication$ = this.applicationService.getApplication(this.applicationId, uuid.v4()).pipe(first(), share());
    getApplication$.subscribe(app => {
      this.appCorrelationId = app['applicationCorrelationId'];
      this.application = app;
      // Find the unpaid payment including 'TIPS' payments that are 'In_Progress'
      const unpaidPayment = app.applicationPayments.find(p =>
        (p.status === ApplicationPaymentStatus.UNPAID) ||
        (p.paymentMethod === PaymentMethod.TIPS && p.status === ApplicationPaymentStatus.INPROGRESS)
      );

      this.receiptNumber = unpaidPayment?.receiptNumber;
      this._applicationPaymentId = unpaidPayment?.id;
      let enableOfflineEOR = false;
      let fromTips = false;
      forkJoin([
        this.fromTips$.pipe(first()),
        this.enableOfflineEOR$.pipe(first())
      ]).pipe(concatMap(([_fromTips, enabled]) => {
        enableOfflineEOR = enabled;
        fromTips = _fromTips;
        return initializePaymentMethodV2({
          store: this.store,
          isNotIOLProduct: true,
          locationId: this.application.bookings[0]?.testLocationId,
          testCentreService: this.testCentreService,
          receiptService: this.receiptService,
          testCentreCode: getTestCentreCode(app),
          isOSRorEORProduct: true
        });
      })).subscribe(({ paymentMethod, location, testcentrePaymentMethods }) => {
        if (!enableOfflineEOR) {
          paymentMethod = paymentMethod.filter(method => method !== IPaymentMethods.OFFLINE);
        }
        this.tcPaymentMethod = testcentrePaymentMethods;
        const isCyberSource = this.tcPaymentMethod?.find(x => x.paymentMethod.description.toLowerCase().includes('cyber'));
        this.bracCyberSource = isCyberSource !== undefined && isCyberSource;
        localStorage.setItem('isBracCyberSource', JSON.stringify(this.bracCyberSource));
        this.paymentMethods = paymentMethod;
        this._clientId = location?.paypalConfiguration?.clientId;
        if (fromTips) {
          this._applicationPaymentId = app.applicationPayments.find(p =>
            p.paymentMethod === PaymentMethod.TIPS &&
            p.status !== ApplicationPaymentStatus.PAID
          )?.id;
          this.isRegisteredEOR(this.applicationId, this._applicationPaymentId);
        }
      });
    });
    return getApplication$;
  }
  ngOnInit() {
    this.addressDetailsForm = this.prepareBillingAddressForm();
    setTimeout(() => {
      if (this.wizardElem) {
        this.stepService = this.wizardElem.stepService;
        this.stepService.setCurrentStep({ isComplete: false, stepIndex: 1 });
      }
    }, 10);
    this.paymentStatus = this.isEORPaymentCompleted ? 'complete' : this.paymentStatus;
  }

  ngOnDestroy(): void {
    this.store.dispatch(setCurrentApplication({ application: null }));
    this.store.dispatch(setFromTips({ fromTips: false }));
  }

  private prepareBillingAddressForm() {
    const user = new User();
    const addressDetail: UserProfileAddressDetails = this.CurrentApp?.userProfile?.addressDetails;
    const userProfile: UserProfile = this.CurrentApp?.userProfile;
    return this.fb.group({
      email: ['',
        { validators: [Validators.pattern(EMAIL_REGEXP)] }
      ],
      mobileNumber: [''],
    });
  }

  getEORFeeData() {
    if (!this.applicationId) {
      return;
    }
    return this.productFeeService.getApplicationEorFee(this.appCorrelationId, this.applicationId).pipe(first());
    // .subscribe(d => this.eorFeeWithApplicationResponse = d);
  }

  showButtonLabel() {
    return !this.stepService.isLastStep() ? 'Continue' : 'Finish';
  }


  get token() {
    return this.paymentToken;
  }

  get isStepOne() {
    return this?.stepService?.currentStep$?.value?.stepIndex === 1;
  }

  get isStepTwo() {
    return this?.stepService?.currentStep$?.value?.stepIndex === 2;
  }

  validationChanged({ value, isValid }: { value?: any, isValid?: boolean } = {}) {
    if (this?.stepService?.currentStep$?.value?.stepIndex === 1) {
      this.enableAcceptClick = isValid;
      this.requestForm = Object.keys(value)
        .filter(i => isString(value[i]))
        .map(i => value[i]);
    }
  }

  getOsrBookingLineId(id) {
    this.osrBookingLineId = id;
  }

  validationChangedPayment({ isValid, errors }) {
    this.enableAcceptClick = isValid;
    this.cdr.detectChanges();
  }

  isRegisteredEOR(_appId, paymentId) {
    this.stepService.setCompleteCurrentStep();
    this.stepService.moveToNextStep();
    this.registerResponse = {
      appId: _appId,
      paymentId,
    };
    this.enableAcceptClick = false;
  }

  onAcceptClick() {
    const hasBookingLinesSelected = this.requestForm && this.requestForm.length > 0;
    if (this.application?.EOR?.applicationPaymentId) {
      if (hasBookingLinesSelected) {
        this.applicationService.updateEor(this.applicationId, this.application?.EOR?.id, {
          bookingLineIds: this.requestForm,
          version: this.application?.EOR?.version
        }, this.appCorrelationId).pipe(first(), concatMap((updateEORResponse) => {
          return this.initApplication().pipe(map(() => updateEORResponse));
        }))
          .subscribe(
            (data) => this.isRegisteredEOR(this.applicationId, data?.applicationPaymentId)
          );
      }
    } else {
      if (hasBookingLinesSelected || this.osrBookingLineId) {
        this.applicationService.registerEor(this.applicationId, {
          bookingLineIds: this.osrBookingLineId ? [this.osrBookingLineId] : this.requestForm,
        }, this.appCorrelationId).pipe(first(), concatMap((registerEORResponse) => {
          return this.initApplication().pipe(map(() => registerEORResponse));
        }))
          .subscribe(
            (data) => this.isRegisteredEOR(this.applicationId, data?.applicationPaymentId),
            (error) => {
              this.enableAcceptClick = false;
              const toast = this.toast.warning('Unable to create more than one EOR. After 5 seconds you will automatically go to the next step', 'EOR Request Registered', {
                closeButton: true,
                progressBar: true,
                timeOut: 5000,
              });
              toast.onHidden.subscribe(() => {
                this.stepService.setCompleteCurrentStep();
                this.stepService.moveToNextStep();
              });
            },
          );
      }
    }
  }

  backToMyTests() {
    this.onBackButtonClick.emit();
  }

  onCreateBraintree() {
    this.hostedFieldsInstance = this.paymentCard.hostedFieldsInstance;
    this.threeDSecure = this.paymentCard.threeDSecureInstance;
  }

  onCreateError() {
    this.loading.resetLoadingCounter();
  }

  async OnPayClick() {
    const findNestedError = (e) => {
      if (e?.details && e.details?.originalError) {
        return findNestedError(e.details?.originalError);
      } else {
        // TODO Need to be discussed with backend
        return `Message: Payment failed. Reason: ${e?.error?.message || ''}`;
      }
    };
    this.cardErrors = [];
    this.paymentStatus = 'in-progress';
    this.loading.increaseLoadingCounter();
    const billingAddressValue = this.addressDetailsForm?.value;
    this.getToken().subscribe(payload => {
      if (this.enable3DSPayment) {
        if (this.addressDetailsForm?.valid) {
          this.verifyCard(payload, billingAddressValue).then((cardVerify) => {
            if (cardVerify.liabilityShifted) {
              this.doPay('', cardVerify.nonce);
            } else {
              this.onErrorPayment({ message: 'the card was not verified' });
              this.loading.decreaseLoadingCounter();
              this.paymentCard.clearHostedFields();
              this.hostedFieldsInstance.teardown();
              this.paymentCard.initPayment();
              this.paymentCard.cardNumberValid = false;
              this.enableAcceptClick = false;
            }
          }).catch(e => {
            this.onErrorPayment(e);
            this.loading.decreaseLoadingCounter();
            this.paymentCard.clearHostedFields();
            this.hostedFieldsInstance.teardown();
            this.paymentCard.initPayment();
            this.paymentCard.cardNumberValid = false;
            this.enableAcceptClick = false;
          });
        } else {
          this.loading.decreaseLoadingCounter();
          return;
        }
      } else {
        this.doPay('', payload.nonce);
        this.loading.decreaseLoadingCounter();
      }

    });
  }

  private getToken(): Observable<any> {
    return new Observable(sub =>
      this.hostedFieldsInstance.tokenize()
        .then(data => sub.next(data))
        .catch(err => {
          this.onErrorPayment(err);
          this.doPay('', null);
          this.loading.decreaseLoadingCounter();
          sub.error(err);
        })
    );
  }

  private verifyCard(payload, billingAddressValue) {
    return this.threeDSecure.verifyCard({
      amount: this.application.applicationPayments[0].amount,
      nonce: payload.nonce,
      bin: payload.details.bin,
      email: billingAddressValue.email,
      billingAddress: {
        phoneNumber: billingAddressValue?.mobileNumber?.e164Number,
      },
      onLookupComplete: (data, next) => {
        next();
      },
    });
  }

  doPay(mode, nonce) {
    this.loading.increaseLoadingCounter();
    this.applicationService.getApplication(this.dashboardApplication.applicationId, this.appCorrelationId).pipe(first()).subscribe(app => {
      this.paymentService.DoPayment(mode ?? '', nonce, app, this.paymentCard.deviceData, this.paymentCard.token)
        .pipe(first(), delay(3000)).subscribe(x => {
          this.onPaymentSuccess(app, x);
        }, e => {
          this.loading.resetLoadingCounter();
          this.renderer.addClass(this.paymentCard?.cardSection?.nativeElement, 'shake');
          this.cdr.detectChanges();
          this.onErrorPayment(e, app.id, this.paymentCard.token);
        },
          () => { /* empty */ });
    });
  }

  onPaymentSuccess(app: any, x?: any) {
    this.everFlowHelper.processEverflowConversion(app);
    this.paymentStatus = 'complete';
    this.loading.resetLoadingCounter();
  }

  private onErrorPayment(error, applicationId?, tokenId?) {
    dataLayer.push({
      event: 'bx-purchase-error',
      applicationId,
      transactionTotal: tokenId,
      error: { ...error }
    });
    this.paymentStatus = 'error';
    this.renderer.addClass(this.paymentCard?.cardSection?.nativeElement, 'shake');
    setTimeout(() => {
      this.paymentStatus = 'default';
      this.renderer.removeClass(this.paymentCard?.cardSection?.nativeElement, 'shake');
    }, 2000);
  }

  onBackButtonClicked() {
    const currentStep = this.stepService.currentStep$.value.stepIndex;
    if (currentStep === 1) {
      this.onBackButtonClick.emit();
    } else if (currentStep === 2 && this.paymentStatus !== 'complete') {
      this.enableAcceptClick = true;
      this.stepService.moveToPrevStep();
      delete this.registerResponse;
    } else if (currentStep === 2 && this.paymentStatus === 'complete') {
      this.onBackButtonClick.emit();
    }
  }

  private fillPurchaseItem(EORFee: EORFeeWithApplicationResponse) {
    const fee = EORFee.eorFee.activeFee;
    const app = EORFee.application;
    this.purchaseTotal = fee.totalAmount;
    this.payCode = fee.currencyIsoCode;
    const tax = fee.totalAmount - fee.baseAmount;
    this.purchaseItem = [{
      name: 'EOR',
      sku: EORFee.eorFee.productId || '',
      quantity: `1`,
      category: 'DIGITAL_GOODS',
      unit_amount: {
        value: `${fee.baseAmount}`,
        currency_code: `${this.payCode}`
      },
      tax: {
        value: `${tax.toFixed ? tax.toFixed(2) : tax}`,
        currency_code: `${this.payCode}`
      }
    }];
  }

  onApprovePaypal(e: { id: string }) {
    this.applicationService.getApplication(this.dashboardApplication.applicationId, this.appCorrelationId).pipe(first()).subscribe(app => {
      this.onPaymentSuccess(app, e);
    });
  }

  onErrorPaypal(err) {
    if (err?.message === 'Detected popup close') {
      this.toast.warning('Payment is canceled');
    } else if (Array.isArray(err?.errors) && err?.errors[0]) {
      try {
        const reason = (err?.errors[0] as string)?.match('(?<=Reason:).*')[0];
        this.paymentErrorModalPaypal.subTitle = reason || 'Something is wrong';
      } catch (error) {
        this.paymentErrorModalPaypal.subTitle = 'Something is wrong';
      }
    } else {
      this.paymentErrorModalPaypal.subTitle = err.message || 'Something is wrong';
    }
    this.paymentErrorModalPaypal.open();
  }

  onAcceptClicked() {
    this.paymentErrorModalPaypal.closeModal(true);
  }
  onCreditCardLoad(event) {
    this._creditCardLoad = event;
  }
  onReturnStripe(status: string) {
    if (status === PaymentStatus.PENDING) {
      this.loading.resetLoadingCounter();
      this.backToMyTests();
    } else if (status === PaymentStatus.FAILED) {
      this.isStripeForwardingFailed = !this.isStripeForwardingFailed;
      this.loading.resetLoadingCounter();
    }
  }
  onFinishClick() {
    this.eorFeeWithApplicationResponse = JSON.parse(localStorage.getItem(GALocalStorageItemKeys.eorObject));
    const total = this.eorFeeWithApplicationResponse?.eorFee?.activeFee?.totalAmount;
    const fee = this.eorFeeWithApplicationResponse;
    const productName = this.eorFeeWithApplicationResponse?.application?.bookings[0].bookableProductName;
    const applicationPayment = this.eorFeeWithApplicationResponse?.application?.applicationPayments
      .find(p => p.applicationFee.type === ProductFeeType.ENQUIRYONRESULT);
    const receiptNumber = applicationPayment?.receiptNumber;
    const paymentMethod = applicationPayment?.paymentMethod === 'OFFLINE' ? 'offline' : 'online';
    dataLayer.push({
      event: 'bxpurchase',
      transactionId: receiptNumber,
      transactionTotal: total,
      transactionAffiliation: '',
      transactionShipping: '',
      transactionProducts: [{
        sku: fee.eorFee.productId,
        name: productName,
        category: 'EOR',
        price: total,
        quantity: 1,
        payment_method: paymentMethod,
      }]
    });
    dataLayer.push({
      event: 'form_submit_enhance',
      enhanced_conversion_data: {
        email: this.eorFeeWithApplicationResponse?.application?.userProfile?.emailAddress
      }
    });
    localStorage.removeItem(GALocalStorageItemKeys.eorObject);
    this.onFinishProcess.emit();
  }
}
