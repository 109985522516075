import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: 'account-v2/email',
    redirectTo: 'my-account',
  },
  {
    path: 'account-v2/sigin',
    redirectTo: 'my-account',
  },
  {
    path: 'account-v2',
    redirectTo: 'my-account',
  },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
